import React, { useEffect, useState, useContext } from "react";
import styled, { useTheme } from "styled-components";
import axios from "axios";
import { getApiUrl, getCSRF, logging } from "../utils";
import { AppContext } from "./AppContext";

const Likes = (props) => {
  const artifact = props.card;

  const theme = useTheme();

  const [likeState, setLikeState] = useState(artifact.self_like);
  const { user, toggleLoginModal } = useContext(AppContext);
  const fillColor = likeState ? theme.colorRed : "none";
  const strokeColor = likeState ? theme.colorRed : theme.colorFont;

  useEffect(() => {
    setLikeState(artifact.self_like);
  }, [artifact.self_like]);

  const setLike = async () => {
    if (!user) {
      toggleLoginModal(true);
      return;
    }
    const res = await axios.post(
      getApiUrl("artifacts/set_like/"),
      {
        artifact_id: artifact.id,
        set: !artifact.self_like,
      },
      {
        headers: getCSRF(),
      }
    );
    if (res.status === 200) {
      artifact.self_like = res.data.self_like;
      res.data.self_like ? (artifact.likes += 1) : (artifact.likes -= 1);
      setLikeState(res.data.self_like);
    }
    logging(res);
  };

  const ViewSVG = (props) => (
    <div className="view-icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 11.5444C2 11.5444 5.77222 4 12.3736 4C18.975 4 22.7472 11.5444 22.7472 11.5444C22.7472 11.5444 18.975 19.0889 12.3736 19.0889C5.77222 19.0889 2 11.5444 2 11.5444Z"
          stroke={props.stroke} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.3721 14.3732C13.9346 14.3732 15.2013 13.1065 15.2013 11.544C15.2013 9.9815 13.9346 8.71484 12.3721 8.71484C10.8096 8.71484 9.54297 9.9815 9.54297 11.544C9.54297 13.1065 10.8096 14.3732 12.3721 14.3732Z"
          stroke={props.stroke} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </div>
  );

  const LikeSVG = (props) => (
    <div className="like-button" onClick={setLike}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7663 6.2377C18.3753 5.84531 17.9111 5.53404 17.4002 5.32168C16.8893 5.10931 16.3417 5 15.7887 5C15.2357 5 14.6881 5.10931 14.1772 5.32168C13.6663 5.53404 13.2021 5.84531 12.8112 6.2377L11.9998 7.05166L11.1884 6.2377C10.3987 5.44548 9.32768 5.00041 8.21089 5.00041C7.09409 5.00041 6.02303 5.44548 5.23334 6.2377C4.44365 7.02993 4 8.10441 4 9.22479C4 10.3452 4.44365 11.4196 5.23334 12.2119L6.0447 13.0258L11.9998 19L17.9549 13.0258L18.7663 12.2119C19.1574 11.8197 19.4677 11.354 19.6794 10.8415C19.891 10.3289 20 9.77958 20 9.22479C20 8.67 19.891 8.12064 19.6794 7.60811C19.4677 7.09558 19.1574 6.6299 18.7663 6.2377V6.2377Z"
          stroke={props.stroke} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>

  );

  return (
    <LikesWrapper>
      <LikeSVG stroke={strokeColor} fill={fillColor} />
      <div className="like-count">
        {artifact.likes}
      </div>
      <ViewSVG stroke={theme.colorFont} />
      <div className="view-count">
        {artifact.views}
      </div>
    </LikesWrapper>
  );
};

const LikesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .like-count,
  .view-count {
    margin-left: 2px;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: default;
    color: ${(props) => props.theme.colorFont};
  }

  .like-button {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }

  .view-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-left: 10px;
  }
`;

export default Likes;
