import React from "react";

const Upload = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 14V2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 6.5L12 1.5L7 6.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21 12.5V16.5C21 17.0304 20.7893 17.5391 20.4142 17.9142C20.0391 18.2893 19.5304 18.5 19 18.5H5C4.46957 18.5 3.96086 18.2893 3.58579 17.9142C3.21071 17.5391 3 17.0304 3 16.5V12.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

);

export default Upload;
