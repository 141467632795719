import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import { device } from "../../constants";
import { logging } from "../../utils";
import { AppContext } from "../AppContext";

const Statistics = () => {
  const { information, locale } = useContext(AppContext);
  const stats = information && information.statistics;
  const upperPrice = stats && stats.upper_price;
  const artifactCount = stats && stats.artifact_count;
  const theme = useTheme();
  const intl = useIntl();
  return (
    <StatisticsDiv>
      <Stat
      text={intl.formatMessage({ id: "statistics__stat.1.text" })}
       count={artifactCount}
       countText={intl.formatMessage({
         id: "statistics__stat.1.countText",
        }, {samples: locale==="ru" ? artifactCount%10 : artifactCount})}
       >
        <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="30" fill={theme.colorMain} />
          <path
            d="M34.0391 32.833V30.708H36.1641C36.5807 30.708 36.9141 30.3747 36.9141 29.958C36.9141 29.5413 36.5807 29.208 36.1641 29.208H34.0391V27.083C34.0391 26.6663 33.7057 26.333 33.2891 26.333C32.8724 26.333 32.5391 26.6663 32.5391 27.083V29.208H30.4141C29.9974 29.208 29.6641 29.5413 29.6641 29.958C29.6641 30.3747 29.9974 30.708 30.4141 30.708H32.5391V32.833C32.5391 33.2497 32.8724 33.583 33.2891 33.583C33.7057 33.583 34.0391 33.2497 34.0391 32.833Z"
            fill={theme.colorWhite}
          />
          <path
            d="M42.7891 37.4167V22.5833C42.7891 21.4167 41.8307 20.5 40.7057 20.5H26.5807C25.9974 20.5 25.4557 20.75 25.0807 21.1667L17.7474 28.9583C17.3724 29.3333 17.1641 29.875 17.1641 30.4167V37.5C17.1641 38.6667 18.1224 39.5833 19.2474 39.5833H40.7057C41.8724 39.5 42.7891 38.5833 42.7891 37.4167ZM25.3307 23V28.625C25.3307 28.9583 25.0807 29.25 24.7474 29.25H19.4557L25.3307 23ZM19.2891 38.0417C18.9557 38.0417 18.6641 37.75 18.6641 37.4167V30.75H24.7474C25.8724 30.75 26.7891 29.7917 26.7891 28.6667V21.9583H40.7057C41.0391 21.9583 41.3307 22.25 41.3307 22.5833V37.4167C41.3307 37.75 41.0391 38.0417 40.7057 38.0417H19.2891Z"
            fill={theme.colorWhite}
          />
        </svg>
      </Stat>
      {/* <Stat text={intl.formatMessage({ id: "statistics__stat.2.text" })} count={upperPrice} countText={intl.formatMessage({ id: "statistics__stat.2.countText" })}>
        <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="30" fill={theme.colorMain} />
          <path
            d="M42.0846 29.292H39.7096C39.3763 24.4587 35.543 20.6253 30.7513 20.292V17.917C30.7513 17.5003 30.418 17.167 30.0013 17.167C29.5846 17.167 29.2513 17.5003 29.2513 17.917V20.292C24.4596 20.6253 20.6263 24.4587 20.293 29.292H17.918C17.5013 29.292 17.168 29.6253 17.168 30.042C17.168 30.4587 17.5013 30.792 17.918 30.792H20.293C20.668 35.5837 24.5013 39.417 29.293 39.792V42.0837C29.293 42.5003 29.6263 42.8337 30.043 42.8337C30.4596 42.8337 30.793 42.5003 30.793 42.0837V39.7087C35.5846 39.3337 39.418 35.5003 39.793 30.7087H42.0846C42.5013 30.7087 42.8346 30.3753 42.8346 29.9587C42.8346 29.5837 42.5013 29.292 42.0846 29.292ZM30.7513 38.2503V37.667C30.7513 37.2503 30.418 36.917 30.0013 36.917C29.5846 36.917 29.2513 37.2503 29.2513 37.667V38.2503C25.293 37.917 22.0846 34.7087 21.7513 30.7503H22.3346C22.7513 30.7503 23.0846 30.417 23.0846 30.0003C23.0846 29.5837 22.7513 29.2503 22.3346 29.2503H21.7513C22.0846 25.292 25.293 22.0837 29.293 21.7503V22.3337C29.293 22.7503 29.6263 23.0837 30.043 23.0837C30.4596 23.0837 30.793 22.7503 30.793 22.3337V21.7503C34.7096 22.0837 37.918 25.292 38.2513 29.292H37.668C37.2513 29.292 36.918 29.6253 36.918 30.042C36.918 30.4587 37.2513 30.792 37.668 30.792H38.2513C37.918 34.7087 34.7096 37.917 30.7513 38.2503Z"
            fill={theme.colorWhite}
          />
          <path
            d="M31.0417 29.2917H28.9583C28.5 29.2917 28.125 28.9583 28.125 28.5417C28.125 28.125 28.5 27.7917 28.9583 27.7917H32.0417C32.4583 27.7917 32.7917 27.4583 32.7917 27.0417C32.7917 26.625 32.4583 26.2917 32.0417 26.2917H31V26.125C31 25.7083 30.6667 25.375 30.25 25.375C29.8333 25.375 29.5 25.7083 29.5 26.125V26.2917H28.9167C27.6667 26.2917 26.625 27.2917 26.625 28.5C26.625 29.7083 27.6667 30.7083 28.9167 30.7083H31C31.4583 30.7083 31.8333 31.0417 31.8333 31.4583C31.8333 31.875 31.4583 32.2083 31 32.2083H27.5C27.0833 32.2083 26.75 32.5417 26.75 32.9583C26.75 33.375 27.0833 33.7083 27.5 33.7083H29.5417V33.875C29.5417 34.2917 29.875 34.625 30.2917 34.625C30.7083 34.625 31.0417 34.2917 31.0417 33.875V33.7083H31.0833C32.3333 33.7083 33.375 32.7083 33.375 31.5C33.3333 30.25 32.2917 29.2917 31.0417 29.2917Z"
            fill={theme.colorWhite}
          />
        </svg>
      </Stat> */}
    </StatisticsDiv>
  );
};

const Stat = (props) => {
  const { children, text, count, countText } = props;
  return (
    <StatDiv>
      <div className="stat__image">{props.children}</div>
      <div className="stat__text">{props.text}</div>
      <div className="stat__row">
        <div className="stat__count">{props.count}</div>
        <div className="stat__count-text">{props.countText}</div>
      </div>
    </StatDiv>
  );
};

const StatDiv = styled.div`
  background-color: ${(props) => props.theme.colorWhite};
  padding-left: 15px;
  padding-right: 15px;
  height: 177px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  max-width: 573px;

  & > * + * {
    margin: 0 0 0 10px;
  }

  .stat__image {
    height: 60px;
    width: 60px;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .stat__text {
    font-size: 16px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    white-space: pre-wrap;
  }

  .stat__count {
    font-weight: 900;
    font-size: 62px;
    line-height: 74px;
    color: ${(props) => props.theme.colorDark};
  }

  .stat__count-text {
    padding-left: 10px;
    font-weight: 900;
    line-height: 24px;
    font-size: 18px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colorDark};
  }

  .stat__row {
    display: flex;
    align-items: center;
  }

  @media ${device.tablet_landscape} {
    .stat__row {
      width: 198px;
    }
    .stat__text {
      width: 168px;
    }
  }
  @media ${device.phone_landscape} {
    flex-direction: column;
    max-width: 300px; // добавлено при одном элементе
    padding-top: 30px;
    padding-bottom: 20px;
    height: auto;
    & > * + * {
      margin: 5px 0 0 0;
    }
    .stat__image {
      height: 90px;
      width: 90px;
      margin-bottom: 12px;
    }
    .stat__text {
      text-align: center;
      margin-bottom: 26px;
      width: auto;
    }

    .stat__row {
      flex-direction: column;
      width: auto;
    }

    .stat__count-text {
      padding: 0;
    }
  }
`;

const StatisticsDiv = styled.div`
  display: flex;
  justify-content: center;
  & > * + * {
    margin: 0 0 0 20px;
  }
  margin-bottom: 150px;

  @media ${device.tablet_landscape} {
    flex-direction: column;
    & > * + * {
      margin: 10px 0 0 0;
    }
    margin-bottom: 100px;
  }

  @media ${device.tablet_portrait} {
    margin-bottom: 80px;
  }

  @media ${device.phone_landscape} {
    align-items: center; // добавлено при одном элементе
    flex-direction: row;
    & > * + * {
      margin: 0 0 0 20px;
    }
  }
  @media ${device.phone_portrait} {
    flex-direction: column;
    & > * + * {
      margin: 10px 0 0 0;
    }
    margin-bottom: 70px;
  }
`;

export default Statistics;
