import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { device } from "../../../constants";
import { localeProfileLabels } from "../../../Internationalization";
import { activeClass, Show } from "../../../utils";
import { AppContext } from "../../AppContext";
import NavArrows from "./NavArrows";

const ProfileNav = ({ item, setItem, profileData, ...props }) => {

  const theme = useTheme();
  const {locale} = useContext(AppContext);
  const { certificates, works, education } = profileData;
  const emptyExperience = !(works[0].date_start || works[1].date_start || works[2].date_start);
  return (
    <StyledDiv {...props}>
      <ul className="profile-nav__list" id="profile-tab-list">
        <Item number={0} current={item} setItem={setItem} text={localeProfileLabels[locale]["tab_portfolio"]} />
        <Show when={!emptyExperience}>
          <Item number={1} current={item} setItem={setItem} text={localeProfileLabels[locale]["tab_experience"]} />
        </Show>
        <Show when={education.institution}>
          <Item number={2} current={item} setItem={setItem} text={localeProfileLabels[locale]["tab_education"]} />
        </Show>
        <Show when={certificates && certificates.length > 0}>
          <Item number={3} current={item} setItem={setItem} text={localeProfileLabels[locale]["tab_certificates"]} />
        </Show>
      </ul>
      <NavArrows background={theme.colorGreyLight} />
    </StyledDiv>
  );
};
const Item = ({ number, current, text, setItem }) => {
  const isActive = number === current;
  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setItem(number);
  };
  return (
    <div className={activeClass("profile-nav__item", isActive)} onClick={onClick}>
      <li>{text}</li>
    </div>
  );
};

const StyledDiv = styled.div`
  position: relative;
  .profile-nav__list {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .profile-nav__item {
    white-space: nowrap;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    position: relative;

    li {
      font-size: 18px;
      font-weight: bold;
      line-height: 30px;
    }

    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }

    :hover {
      color: ${(props) => props.theme.colorMain};
    }

&.active-state {
  color: ${(props) => props.theme.colorMain};
  &:after {
    position: absolute;
    display: block;
    width: 43px;
        content: "";
        height: 4px;
        background: ${(props) => props.theme.colorMain};
        bottom: 0;
        left: calc(50% - 21.5px);
      }
    }
  }

  @media ${device.tablet_portrait} {
    .profile-nav__list {
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }
    .profile-nav__item {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media ${device.phone_landscape} {
    .profile-nav__item li {
      font-size: 16px;
    }
  }
`;

export default ProfileNav;
