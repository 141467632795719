import React from "react";

const Bin = () => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4.94141H2.97059H18.7353" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.92463 4.94118V2.97059C5.92463 2.44796 6.13225 1.94673 6.50181 1.57717C6.87136 1.20761 7.37259 1 7.89522 1H11.8364C12.359 1 12.8603 1.20761 13.2298 1.57717C13.5994 1.94673 13.807 2.44796 13.807 2.97059V4.94118M16.7629 4.94118V18.7353C16.7629 19.2579 16.5553 19.7592 16.1857 20.1287C15.8161 20.4983 15.3149 20.7059 14.7923 20.7059H4.93934C4.41671 20.7059 3.91548 20.4983 3.54592 20.1287C3.17636 19.7592 2.96875 19.2579 2.96875 18.7353V4.94118H16.7629Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.91016 10V15.9118" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.8242 10V15.9118" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Bin;
