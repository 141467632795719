import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Field } from "formik";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

export const CustomTextarea = (props) => {
  const { notMark, maxLength, counter, formik, valueName } = props;
  const placeholder = props.placeholder || "";
  const customClass = props.customClass || "";
  const multipleLines = !!props.multipleLines;
  const isError = formik.touched[valueName] && formik.errors[valueName];
  const theme = useTheme();
  return (
    <CustomTextareaDiv>
      <div className="field-wrapper">
        <div className="input-wrapper">
          <Field
            innerRef={props.innerRef}
            name={valueName}
            as={multipleLines ? "textarea" : "input"}
            className={customClass.concat(isError ? " error" : "")}
            placeholder={placeholder}
            maxLength={maxLength}
          />
          {!notMark && isError ? (
            <div className="error-mark">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke={theme.colorRed}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M12 8V12" stroke={theme.colorRed} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16H12.01" stroke={theme.colorRed} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          ) : null}
        </div>
        <div className="row-after-field">
          {isError ? <div className="error-label">{formik.errors[valueName]}</div> : <div />}
          {counter ? (
            <div className="counter">
              {formik.values[valueName].length}/{maxLength}
            </div>
          ) : null}
        </div>
      </div>
    </CustomTextareaDiv>
  );
};

const CustomTextareaDiv = styled.div`
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    textarea,
    input {
      resize: none;
      overflow: auto;
      height: 63px;
      width: 100%;
      font-size: 14px;
      line-height: 63px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colorDark};
      padding: 0px 40px 0px 20px;
      border: 2px transparent;
      box-shadow: 0 0 0 1px ${(props) => props.theme.colorGrey};
      border-radius: 5px;
      outline: none;
      background: inherit;
      ::placeholder {
        color: ${(props) => props.theme.colorFont};
      }

      :focus-visible {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorMain};
      }
    }

    textarea {
      height: 172px;
      line-height: 30px;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .error {
      border-color: transparent;
      box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};

      :focus-visible {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};
      }
    }
  }
`;

export const TagSelect = (props) => {
  const { valueName, formik, options, commonTags } = props;
  const isError = props.isError || formik.touched[valueName] && formik.errors[valueName];
  const minHeight = props.minHeight || "63px";
  const height = props.height || "fit-content";
  const width = props.width || "auto";
  const [isMenuOpen, setMenuOpen] = useState(false);
  const getValue = () => formik.values[valueName].map((value) => ({ value, label: value }));
  const theme = useTheme();

  const onChange = (option) => {
    formik.setFieldValue(
      valueName,
      option.map((item) => item.value)
    );
  };

  const Input = (props) => <components.Input {...props} maxLength={12} />;

  const commonTagOnClick = (tag) => {
    formik.setFieldTouched(valueName);
    const array = formik.values[valueName].map((value) => value);
    if (formik.values[valueName].includes(tag.value)) {
      const index = array.indexOf(tag.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(tag.value);
    }
    formik.setFieldValue(valueName, array);
  };

  const chosenTagStyle = {
    border: "none",
    color: theme.colorGreyLight,
    backgroundColor: theme.colorDark,
  };

  return (
    <div className="select-wrapper">
      <SelectDiv
        value={formik.values[valueName]}
        height={height}
        width={width}
        isMenuOpen={isMenuOpen}
        hideFirst={!!props.hideFirst}
        minHeight={minHeight}
        hideAfterFive
      >
        <CreatableSelect
          components={{ Input }}
          classNamePrefix="select"
          className={"select".concat(isError ? " error" : "")}
          isClearable
          isMulti
          name={valueName}
          value={getValue()}
          onChange={(e) => {
            formik.setFieldTouched(valueName);
            onChange(e);
          }}
          placeholder="CJM можно будет найти по тегам"
          options={options}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          onBlur={(e) => {
            formik.setFieldTouched(valueName);
            formik.handleBlur(e);
          }}
        />
      </SelectDiv>
      {formik.touched[valueName] && formik.errors[valueName] ? (
        <div className="error-label">{formik.errors[valueName]}</div>
      ) : null}
      <div className="common-tags">
        {commonTags.map((value, index) => (
          <div
            className="tag"
            style={formik.values[valueName].includes(value.value) ? chosenTagStyle : undefined}
            key={index}
            onClick={() => commonTagOnClick(value)}
          >
            {value.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export const CustomSelect = (props) => {
  const { valueName, formik, options, disableSearch, placeholder } = props;
  const isError = props.isError || formik.touched[valueName] && formik.errors[valueName];
  const height = props.height || "63px";
  const width = props.width || "auto";
  const [isMenuOpen, setMenuOpen] = useState(false);
  const getValue = () => (options ? options.find((option) => option.value === formik.values[valueName]) || null : "");
  const onChange = props.onChange || ((e) => formik.setFieldValue(valueName, e.value));

  const onBlur = (e) => {
    setTimeout(() => {
      formik.setFieldTouched(valueName);
      formik.handleBlur(e);
    }, 20);
  };

  return (
    <SelectDiv
      value={formik.values[valueName]}
      height={height}
      width={width}
      isMenuOpen={isMenuOpen}
      hideFirst={!!props.hideFirst}
    >
      <Select
        classNamePrefix="select"
        className={"select".concat(isError ? " error" : "")}
        isSearchable={!disableSearch}
        name={valueName}
        placeholder={placeholder}
        options={options}
        value={getValue()}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        onChange={(e) => {
          formik.setFieldTouched(valueName);
          onChange(e);
        }}
        onBlur={onBlur}
      />
    </SelectDiv>
  );
};

const SelectDiv = styled.div`
  .select {
    width: ${(props) => props.width};

    .select__placeholder {
      color: ${(props) => props.theme.colorFont};
    }

    .select__single-value {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
    }

    .select__control {
      cursor: pointer;
      border: 1px solid ${(props) => props.theme.colorGrey};
      border-radius: 5px;
      min-height: ${(props) => props.minHeight};
      height: ${(props) => props.height};
      margin-right: 0;
      .select__indicators {
        .select__indicator-separator {
          display: none;
        }
        .select__dropdown-indicator {
          color: ${(props) => props.theme.colorFont};
          transform: scale(1, ${(props) => (props.isMenuOpen ? -1 : 1)});
        }
      }

      .select__multi-value {
        background-color: ${(props) => props.theme.colorWhite};
        border: 1px solid ${(props) => props.theme.colorGrey2};
        border-radius: 50px;
        height: 31px;
        line-height: 31px;
        align-items: center;
        padding: 0px 5px 0px 5px;

        .select__multi-value__label {
          padding-right: 0px;
          font-size: 12px;
          color: ${(props) => props.theme.colorDark};
        }
      }
    }

    .select__control--is-focused {
      outline: none;
      border: 2px solid ${(props) => props.theme.colorMain};
      border-radius: 5px;
      box-shadow: none;
    }
  }

  .error {
    .select__control {
      border: 2px solid ${(props) => props.theme.colorRed};
    }

    .select__control--is-focused {
      border: 2px solid ${(props) => props.theme.colorRed};
    }
  }

  .select__menu {
    z-index: 5;

    .select__menu-list {
      .select__option {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 40px;
        line-height: 40px;

        &:first-child {
          display: ${(props) => (props.hideFirst ? "none" : "flex")};
        }
        &:nth-child(n + 6) {
          display: ${(props) => (props.hideAfterFive ? "none" : "flex")};
        }
        &:last-child {
          display: flex;
        }
      }
    }
  }
`;
