import React from "react";

const LinkedIn = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8786 6.12109C13.0243 6.12109 14.1231 6.57624 14.9333 7.38639C15.7435 8.19655 16.1986 9.29536 16.1986 10.4411V15.4811H13.3186V10.4411C13.3186 10.0592 13.1669 9.69291 12.8968 9.42286C12.6268 9.15281 12.2605 9.00109 11.8786 9.00109C11.4967 9.00109 11.1304 9.15281 10.8604 9.42286C10.5903 9.69291 10.4386 10.0592 10.4386 10.4411V15.4811H7.55859V10.4411C7.55859 9.29536 8.01374 8.19655 8.82389 7.38639C9.63405 6.57624 10.7329 6.12109 11.8786 6.12109Z"
      fill="white"
    />
    <path d="M4.68078 6.83984H1.80078V15.4798H4.68078V6.83984Z" fill="white" />
    <path
      d="M3.24078 4.68078C4.03607 4.68078 4.68078 4.03607 4.68078 3.24078C4.68078 2.44549 4.03607 1.80078 3.24078 1.80078C2.44549 1.80078 1.80078 2.44549 1.80078 3.24078C1.80078 4.03607 2.44549 4.68078 3.24078 4.68078Z"
      fill="white"
    />
  </svg>

);

export default LinkedIn;
