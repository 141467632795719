import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import { device } from "../../constants";
import { Step } from "../SmallComponents";

const HowSelected = () => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <HowSelectedDiv>
      <div className="for-company__block-title"><FormattedMessage id="how-selected__title" /></div>
      <div className="how-selected__steps">
        <Step
        number="1"
        title={intl.formatMessage({ id: "how-selected__steps.1.title" })}
        text={intl.formatMessage({ id: "how-selected__steps.1.text" })}
        >
          <svg viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="72" cy="72" r="72" fill={theme.colorMain} />
            <path
              d="M97.0002 51.8H47.0002C43.8002 51.8 41.2002 54.4 41.2002 57.6V86.6C41.2002 89.8 43.8002 92.4001 47.0002 92.4001H97.0002C100.2 92.4001 102.8 89.8 102.8 86.6V57.6C102.8 54.3 100.2 51.8 97.0002 51.8ZM99.3002 86.5C99.3002 87.7001 98.3002 88.8 97.0002 88.8H47.0002C45.8002 88.8 44.7002 87.8 44.7002 86.5V57.5C44.7002 56.3 45.7002 55.2 47.0002 55.2H97.0002C98.2002 55.2 99.3002 56.2 99.3002 57.5V86.5Z"
              fill={theme.colorMain}
            />
            <path
              d="M72.0002 61C71.0002 61 70.2002 61.8 70.2002 62.8V78.6C70.2002 79.6 71.0002 80.4 72.0002 80.4C73.0002 80.4 73.8002 79.6 73.8002 78.6V62.8C73.8002 61.8 73.0002 61 72.0002 61Z"
              fill={theme.colorMain}
            />
            <path
              d="M50.9001 72.4001H60.1001C61.1001 72.4001 61.9001 71.6 61.9001 70.6C61.9001 69.6 61.1001 68.8 60.1001 68.8H50.9001C49.9001 68.8 49.1001 69.6 49.1001 70.6C49.1001 71.6 49.9001 72.4001 50.9001 72.4001Z"
              fill={theme.colorMain}
            />
            <path
              d="M92.7003 61H82.9003C81.7003 61 80.8003 62 80.8003 63.1V72.9C80.8003 74.1 81.8003 75 82.9003 75H92.7003C93.9003 75 94.8003 74 94.8003 72.9V63.1C94.8003 62 93.9003 61 92.7003 61ZM91.3003 71.6H84.3003V64.6H91.3003V71.6Z"
              fill={theme.colorMain}
            />
            <path
              d="M62.7001 76.8H50.9001C49.9001 76.8 49.1001 77.6 49.1001 78.6C49.1001 79.6 49.9001 80.4001 50.9001 80.4001H62.7001C63.7001 80.4001 64.5001 79.6 64.5001 78.6C64.5001 77.6 63.7001 76.8 62.7001 76.8Z"
              fill={theme.colorMain}
            />
          </svg>
        </Step>

        <Step
          number="2"
          title={intl.formatMessage({ id: "how-selected__steps.2.title" })}
          text={intl.formatMessage({ id: "how-selected__steps.2.text" })}
        >
          <svg viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="72" cy="72" r="72" fill={theme.colorMain} />
            <path
              d="M102.699 89.7992V54.1992C102.699 51.3992 100.399 49.1992 97.6992 49.1992H63.7992C62.3992 49.1992 61.0992 49.7992 60.1992 50.7992L42.5992 69.4992C41.6992 70.3992 41.1992 71.6992 41.1992 72.9992V89.9992C41.1992 92.7992 43.4992 94.9992 46.1992 94.9992H97.6992C100.499 94.7992 102.699 92.5992 102.699 89.7992ZM60.7992 55.1992V68.6992C60.7992 69.4992 60.1992 70.1992 59.3992 70.1992H46.6992L60.7992 55.1992ZM46.2992 91.2992C45.4992 91.2992 44.7992 90.5992 44.7992 89.7992V73.7992H59.3992C62.0992 73.7992 64.2992 71.4992 64.2992 68.7992V52.6992H97.6992C98.4992 52.6992 99.1992 53.3992 99.1992 54.1992V89.7992C99.1992 90.5992 98.4992 91.2992 97.6992 91.2992H46.2992Z"
              fill={theme.colorMain}
            />
            <path d="M85 66L74 77L69 72" stroke={theme.colorMain} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </Step>

        <Step
          number="3"
          title={intl.formatMessage({ id: "how-selected__steps.3.title" })}
          text={intl.formatMessage({ id: "how-selected__steps.3.text" })}
        >
          <svg viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="72" cy="72" r="72" fill={theme.colorMain} />
            <path
              d="M101 70.2999H95.3002C94.5002 58.6999 85.3002 49.5 73.8002 48.7V43C73.8002 42 73.0002 41.2 72.0002 41.2C71.0002 41.2 70.2002 42 70.2002 43V48.7C58.7002 49.5 49.5002 58.6999 48.7002 70.2999H43.0002C42.0002 70.2999 41.2002 71.0999 41.2002 72.0999C41.2002 73.0999 42.0002 73.9 43.0002 73.9H48.7002C49.6002 85.4 58.8002 94.5999 70.3002 95.5V101C70.3002 102 71.1002 102.8 72.1002 102.8C73.1002 102.8 73.9002 102 73.9002 101V95.2999C85.4002 94.3999 94.6002 85.2 95.5002 73.7H101C102 73.7 102.8 72.9 102.8 71.9C102.8 71 102 70.2999 101 70.2999ZM73.8002 91.7999V90.4C73.8002 89.4 73.0002 88.5999 72.0002 88.5999C71.0002 88.5999 70.2002 89.4 70.2002 90.4V91.7999C60.7002 91 53.0002 83.2999 52.2002 73.7999H53.6002C54.6002 73.7999 55.4002 73 55.4002 72C55.4002 71 54.6002 70.2 53.6002 70.2H52.2002C53.0002 60.7 60.7002 53 70.3002 52.2V53.6C70.3002 54.6 71.1002 55.4 72.1002 55.4C73.1002 55.4 73.9002 54.6 73.9002 53.6V52.2C83.3002 53 91.0002 60.6999 91.8002 70.2999H90.4002C89.4002 70.2999 88.6002 71.0999 88.6002 72.0999C88.6002 73.0999 89.4002 73.9 90.4002 73.9H91.8002C91.0002 83.2999 83.3002 91 73.8002 91.7999Z"
              fill={theme.colorMain}
            />
            <path
              d="M74.5004 70.2999H69.5004C68.4004 70.2999 67.5004 69.4999 67.5004 68.4999C67.5004 67.4999 68.4004 66.6999 69.5004 66.6999H76.9004C77.9004 66.6999 78.7004 65.8999 78.7004 64.8999C78.7004 63.8999 77.9004 63.0999 76.9004 63.0999H74.4004V62.6999C74.4004 61.6999 73.6004 60.8999 72.6004 60.8999C71.6004 60.8999 70.8004 61.6999 70.8004 62.6999V63.0999H69.4004C66.4004 63.0999 63.9004 65.4999 63.9004 68.3999C63.9004 71.2999 66.4004 73.6999 69.4004 73.6999H74.4004C75.5004 73.6999 76.4004 74.4999 76.4004 75.4999C76.4004 76.4999 75.5004 77.2999 74.4004 77.2999H66.0004C65.0004 77.2999 64.2004 78.0999 64.2004 79.0999C64.2004 80.0999 65.0004 80.8999 66.0004 80.8999H70.9004V81.2999C70.9004 82.2999 71.7004 83.0999 72.7004 83.0999C73.7004 83.0999 74.5004 82.2999 74.5004 81.2999V80.8999H74.6004C77.6004 80.8999 80.1004 78.4999 80.1004 75.5999C80.0004 72.5999 77.5004 70.2999 74.5004 70.2999Z"
              fill={theme.colorMain}
            />
          </svg>
        </Step>
      </div>
    </HowSelectedDiv>
  );
};

const HowSelectedDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  .how-selected__steps {
    display: flex;
    justify-content: space-between;
    & > * + * {
      margin-left: 30px;
    }
  }

  @media ${device.tablet_landscape} {
    .how-selected__steps {
      padding-top: 20px;
      flex-direction: column;
      & > * + * {
        margin-left: 0;
        margin-top: 50px;
      }
    }
  }
  @media ${device.phone_landscape} {
    margin-bottom: 70px;
  }
`;

export default HowSelected;
