import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import App from "./components/App";
import "./polyfills";
import "./prototypes";

Sentry.init({
  dsn: "https://3f5a87abd6d644028d02872a6893ab3f@o1010563.ingest.sentry.io/6021034",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
