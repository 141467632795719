import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AppContext } from "../AppContext";
import { Show } from "../../utils";
import { device, localeDefaultTexts } from "../../constants";

const SuccessModal = () => {
  const { successModal, setSuccessModal, restartSuccessModal, setRestartSuccessModal, successInfo, locale } = useContext(AppContext);

  useEffect(() => {
    if (restartSuccessModal !== -1) {
      setSuccessModal(true);
      setRestartSuccessModal(false);
    }
  }, [restartSuccessModal, setRestartSuccessModal, setSuccessModal]);

  const onAnimationEnd = () => {
    setSuccessModal(false);
  };
  const text = successInfo.text ||
  (successInfo.success ? localeDefaultTexts[locale]["success-modal__text"] : localeDefaultTexts[locale]["success-modal__failed-text"]);
  return (
    <Show when={successModal > 0}>
      <Background>
        <SuccessModalDiv onAnimationEnd={onAnimationEnd} success={successInfo.success}>
          <div className="success-modal__colored-line" />
          <div className="success-modal__text">{text}</div>
          <div className="success-modal__image">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <Show when={successInfo.success}>
                <path d="M28 14L17 25L12 20" strokeLinecap="round" strokeLinejoin="round" />
              </Show>
              <Show when={!successInfo.success}>
                <path d="M26 14L14 26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 14L26 26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </Show>
            </svg>
          </div>
        </SuccessModalDiv>
      </Background>
    </Show>
  );
};

const breatheAnimation =  (bottom) => keyframes`
  20% { bottom: ${bottom}; opacity:1 }
  80% { bottom: ${bottom}; opacity:1 }
`;

const SuccessModalDiv = styled.div`
  bottom: 0;
  right: 138px;
  height: 77px;
  width: 283px;
  background-color: ${(props) => props.theme.colorWhite};
  box-shadow: 0px 2px 4px rgba(117, 133, 171, 0.25);
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation-name: ${breatheAnimation('82px')};
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  opacity: 0;

  path {
    stroke: ${(props) => props.theme.colorGreyLight};
  }


  .success-modal__colored-line {
    flex-shrink: 0;
    height: 100%;
    width: 4px;
    border-radius: 5px 0px 0px 5px;
    background-color: ${(props) => props.success ? props.theme.colorGreen : props.theme.colorRed};
    margin-right: 5px;
  }

  .success-modal__text {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
  }

  .success-modal__image {
    flex-shrink: 0;
    margin-left: 5px;
    margin-right: 10px;
    right: 23px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.success ? props.theme.colorGreen : props.theme.colorRed};
    border-radius: 50%;
  }

  @media ${device.tablet_landscape} {
    right: 24px;
    animation-name: ${breatheAnimation('86px')};
  }

  @media ${device.tablet_portrait} {
    animation-name: ${breatheAnimation('175px')};
  }

  @media ${device.phone_landscape} {
    right: 0;
    width: 432px;
    animation-name: ${breatheAnimation('42px')};
  }

  @media ${device.phone_portrait} {
    width: 288px;
    animation-name: ${breatheAnimation('12px')};
  }
`;

const Background = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: fixed;
  top: 0;
  display: grid;
  justify-content: end;
  align-items: end;
  z-index: 20;

  @media ${device.phone_landscape} {
    justify-content: center;
  }
`;
export default SuccessModal;
