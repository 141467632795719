import React from "react";
import styled from "styled-components";
import RoundedArrowLeft from "../../SVGs/rounded/RoundedArrowLeft";
import RoundedButton from "./RoundedButton";

const RoundedBackButton = ({className, ...props}) => (
  <StyledButton className={className}>
    <RoundedButton {...props}>
      <RoundedArrowLeft />
    </RoundedButton>
  </StyledButton>
);

const StyledButton = styled.div`
  .rounded-button {
    background-color: ${(props) => props.theme.colorMain};
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    stroke: ${(props) => props.theme.colorWhite};
  }
`;

export default RoundedBackButton;
