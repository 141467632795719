import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { addClass } from "../../../utils";
import useWidth from "../../hooks/useWidth";
import ArrowLeft from "../../UI/SVGs/ArrowLeft";
import ArrowRight from "../../UI/SVGs/ArrowRight";

const NavArrows = ({ left, right, onClick, ...props }) => {
  const width = useWidth();
  const [visible, setVisible] = useState({ right: false, left: false });
  const [navList, setNavList] = useState(null);

  const getRef = useCallback(node => {
    if (node !== null) {
      const prevEl = node.previousElementSibling;
      setNavList(prevEl);
    }
  }, []);

  const checkVisibility = (nList) => {
    if (nList) {
      const cWidth = nList.clientWidth;
      const sWidth = nList.scrollWidth;
      const sLeft = nList.scrollLeft;
      if (cWidth < sWidth) {
        const currentVisible = { right: true, left: true };
        if (sLeft === 0) {
          currentVisible.left = false;
        }
        if (cWidth + sLeft >= sWidth) {
          currentVisible.right = false;
        }
        setVisible(currentVisible);
      } else {
        setVisible({ right: false, left: false });
      }
    }
  };

  useEffect(() => {
    checkVisibility(navList);
  }, [width, navList]);

  const handleClick = (e, width) => {
    navList.scrollTo({ left: navList.scrollLeft += width });
    const cWidth = navList.clientWidth;
    const sWidth = navList.scrollWidth;
    const sLeft = navList.scrollLeft;
    const currentVisible = { right: true, left: true };
    if (sLeft + width <= 0) {
      currentVisible.left = false;
    }
    if (cWidth + sLeft + width >= sWidth) {
      currentVisible.right = false;
    }
    setVisible(currentVisible);
  };

  return (
    <>
      <StyledDiv className={addClass("right-arrow", "d-none", !visible.right)} {...props} ref={getRef}>
        <div className="arrow-wrapper">
          <ArrowLeft onClick={(e) => handleClick(e, 300)} />
        </div>
      </StyledDiv>
      <StyledDiv className={addClass("left-arrow", "d-none", !visible.left)} {...props}>
        <div className="arrow-wrapper">
          <ArrowLeft onClick={(e) => handleClick(e, -300)} />
        </div>
      </StyledDiv>
    </>
  );
};

const StyledDiv = styled.div`
  pointer-events: none;
  position: absolute;
  bottom: 0;
  width: 50px;
  svg {
    pointer-events: auto;
    cursor: pointer;
    stroke: ${props => props.theme.colorDark};
    stroke-width: 1.2px;
  }

  &.left-arrow {
    left: 0;
    background-image: linear-gradient(to left, rgba(255,255,255,0) 0%, ${props => props.background || "white"} 50%);
  }

  &.right-arrow {
    right: 0;
    background-image: linear-gradient(to left, rgba(255,255,255,0) 0%, ${props => props.background || "white"} 50%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`;

export default NavArrows;
