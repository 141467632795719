import React from "react";
import { urls } from "../../../constants";
import GearSVG from "../SVGs/Gear";
import LinkButton from "./LinkButton";

const SettingsButton = ({ className, ...props }) => (
  <LinkButton {...props} className={"icon ".concat(className)} url={urls.settings}>
    <GearSVG />
  </LinkButton>
);

export default SettingsButton;
