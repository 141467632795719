import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import Intl from "intl";
import { IntlProvider } from "react-intl";
import { AppContext } from "./components/AppContext";
import { getApiUrl, Show } from "./utils";

// '\xa0' and &nbsp; to nobreakable space in
// <br/> and \n and {br} to new line

const InterProvider = ({ id, children }) => {
  const { locale } = useContext(AppContext);
  const [messages, setMessages] = useState({});
  useEffect(() => {
    getLocalizedData(id, setMessages);
  }, [id, setMessages]);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}
      defaultRichTextElements={{
        // b: (chunks) => <b>{chunks}</b>,
        br: <br />,
        // p: (chunks) => <p>{chunks}</p>
        li: (chunks) => <li>{chunks}</li>,
        ul: (chunks) => <ul>{chunks}</ul>,
        nbsp: '\xa0',
      }}>
      <Show when={messages && JSON.stringify(messages) !== '{}'}>
        {children}
      </Show>
    </IntlProvider>
  );
};

export default InterProvider;

export const getLocalizedData = async (id, setData) => {
  try {
    const request = await axios.get(getApiUrl(`localize/${id}`));
    const data = request.data;
    setData(data);
  } catch (e) { }
};

export const localeLinksTexts = {
  "en": {
    "go-to": "Go to",
    "go-to-source": "Go to source",
    "open-by-link": "Open by link",
  },
  "ru": {
    "go-to": "Перейти в",
    "go-to-source": "Перейти в источник",
    "open-by-link": "Открыть по ссылке",
  },
};

export const localeButtonTexts = {
  "en": {
    "sign-in": "Get started",
    "send-request": "Send",
    "delete-account": "Delete account",
    "delete": "Delete",
    "save": "Save",
    "exit": "Sign out",
    "upload-cjm": "Upload CJM",
    "upload": "Upload",
    "upload-image": "Upload preview",
  },
  "ru": {
    "sign-in": "Войти",
    "send-request": "Отправить",
    "delete-account": "Удалить аккаунт",
    "delete": "Удалить",
    "save": "Сохранить",
    "exit": "Выйти",
    "upload-cjm": "Загрузить CJM",
    "upload": "Загрузить",
    "upload-image": "Загрузить превью"
  },
};
export const localeErrorTexts = {
  "en": {
    "required-name": "Please enter your first name",
    "required-email": "Please enter your email",
    "for-company__name-validation": "Only letters, spaces and hyphens are allowed",
    "for-company__email-validation": "This email is not valid :(",
    "required-field": "Required field",
    "check-first-name": "Please check your first name",
    "check-last-name": "Please check your last name",
    "date-error": "Please check the dates",
    "check-facebook": "Facebook link is invalid",
    "check-linkedin": "LinkedIn link is invalid",
    "too-many-tags": "Up to 5 tags are allowed",
    "check-notion": "Notion link is invalid",
    "check-username": "Only letters, digits and symbols “_ , - , . “ are allowed",
    "end-without-symbols": "Input cannot end with symbols “_ , - , . “",
    "start-with-letter": "Input must start with letter",
    "username-exists": "Username isn’t available",
    "username-min-letters": "Include at least 2 characters in your username",
  },
  "ru": {
    "required-name": "Укажите имя",
    "required-email": "Укажите email",
    "for-company__name-validation": "Допустимы только буквы, пробелы и дефисы",
    "for-company__email-validation": "Такой email не подходит :(",
    "required-field": "Обязательное поле",
    "check-first-name": "Проверьте своё имя",
    "check-last-name": "Проверьте свою фамилию",
    "date-error": "Проверьте правильность ввода дат",
    "check-facebook": "Проверьте ссылку на профиль в Facebook",
    "check-linkedin": "Проверьте ссылку на профиль в LinkedIn",
    "too-many-tags": "Добавьте не более 5-и тегов",
    "check-notion": "Проверьте правильность ссылки",
    "check-username": "Допустимы только буквы, цифры и знаки “_ , - , . “",
    "end-without-symbols": "Ввод не может оканчиваться на знаки “_ , - , . “",
    "start-with-letter": "Ввод должен начинаться с буквы",
    "username-exists": "Такой username уже занят",
    "username-min-letters": "Введите как минимум 2 символа",
  },
};

export const localePlaceholderTexts = {
  "en": {
    "for-company__input__name": "Name",
    "for-company__input__email": "E-mail",
    "for-company__input__telegram": "Telegram, WhatsApp or other (optional)",
    "main-page__industry": "Industry",
    "main-page__rate": "Rate",
    "search-input": "Name, tags, company name",
    "profile-settings__input__first-name": "First Name",
    "profile-settings__input__last-name": "Last Name",
    "experience__achievements-input": "E.g. MAU increase by 32% ",
    "date-month": "Month",
    "date-year": "Year",
    "education__level": "Degree",
    "education__institution": "Full name or abbreviation",
    "description__portrait": "Tell about your self \n" +
      "E.g. I am actually a very hardworking person. Almost everything becomes harder when I'm the one working on it",
    "add-cjm__title": "e.g. CJM Sign-up in Duolingo app",
    "add-cjm__company": "Duolingo",
    "add-cjm__industry": "Select Industry",
    "add-cjm__tags": "Your CJM can be found by tags",
    "add-cjm__description": "What do you propose to change in the service you analysed? ",
    "add-artifact__title": " ",
    "add-artifact__company": "Duolingo",
    "add-artifact__industry": "Select Industry",
    "add-artifact__tags": "Your CJM can be found by tags",
    "add-artifact__description": "What do you propose to change in the service you analysed? ",
    "add-pr__title": "e.g. Joint accounts research",
    "add-pr__company": "Whose service is described in the research",
    "add-pr__industry": "Select Industry",
    "add-pr__tags": "Your research can be found by tags",
    "add-pr__description": "Small overview of your work sample",
    "add-artifact__choose-artifact-type": "Choose type",
    "add-artifact__choose-platform": "Choose platform",
  },
  "ru": {
    "for-company__input__name": "Имя",
    "for-company__input__email": "E-mail",
    "for-company__input__telegram": "Telegram (необязательно)",
    "main-page__industry": "Сфера деятельности",
    "main-page__rate": "Оценка",
    "search-input": "Название, теги, компания",
    "profile-settings__input__first-name": "Имя",
    "profile-settings__input__last-name": "Фамилия",
    "experience__achievements-input": "Опишите, чем вы гордитесь.",
    "date-month": "месяц",
    "date-year": "год",
    "education__level": "Уровень",
    "education__institution": "Название или аббревиатура",
    "education__date-end": "год",
    "description__portrait": 'Расскажите о себе, например "я аутичный и добрый продакт".',
    "add-cjm__title": "Например, CJM регистрации в Skyeng",
    "add-cjm__company": "Чей сервис описан в CJM",
    "add-cjm__industry": "Выбрать сферу деятельности",
    "add-cjm__tags": "CJM можно будет найти по тегам",
    "add-cjm__description": "Небольшое описание проекта. Что вы предлагаете изменить в CJM сервиса этой компании?",
    "add-artifact__title": " ",
    "add-artifact__company": "Если сделано для какой-то компании",
    "add-artifact__industry": "Выбрать сферу деятельности",
    "add-artifact__tags": "CJM можно будет найти по тегам",
    "add-artifact__description": "Небольшое описание твоей работы",
    "add-pr__title": "Например, Research рынка семейных карт",
    "add-pr__company": "Чей сервис описан в ресерче",
    "add-pr__industry": "Выбрать сферу деятельности",
    "add-pr__tags": "Product Research можно будет найти по тегам",
    "add-pr__description": "Небольшое описание твоей работы. Что ты исследовал и какая цель была у ресерча?",
    "add-artifact__choose-artifact-type": "Выбрать тип работы",
    "add-artifact__choose-platform": "Выбрать платформу",
  }
};

export const localeSettingsLabels = {
  "en": {
    "profile-settings__link": "Portfolio link",
    "experience__date-start": "Start Date",
    "experience__date-end": "End Date",
    "experience__company": "Employer",
    "experience__post": "Job Title",
    "experience__achievements": "Achievements",
    "experience__currently-work": "I currently work here",
    "experience__work": "Job",
    "education__level": "Degree",
    "education__institution": "School",
    "education__faculty": "Faculty",
    "education__specialty": "Field of Study",
    "education__date-end": "Graduation Date",
    "add-cjm__title": "CJM Name",
    "add-cjm__company": "Company",
    "add-cjm__industry": "Industry",
    "add-cjm__tags": "Add Tags",
    "add-cjm__description": "Description",
    "add-cjm__refresh-button-title": "Refresh preview",
    "add-cjm__delete-button-title": "Delete link",
    "add-artifact__title": "Name",
    "add-artifact__company": "Company",
    "add-artifact__industry": "Industry",
    "add-artifact__tags": "Add Tags",
    "add-artifact__description": "Description",
    "add-artifact__refresh-button-title": "Refresh preview",
    "add-artifact__delete-button-title": "Delete",
    "add-artifact__back": "Back",
    "add-artifact__artifact-type": "Type",
    "add-artifact__platform": "Platform",
    "add-artifact__link": "Source link",
    "add-pr__modal-pr": "Link to project in Notion",
    "add-pr__title": "Research Name",
    "add-pr__company": "Company",
    "add-pr__industry": "Industry",
    "add-pr__tags": "Add Tags",
    "add-pr__description": "Description",
  },
  "ru": {
    "profile-settings__link": "Ссылка на профиль",
    "experience__date-start": "Начало работы",
    "experience__date-end": "Окончание",
    "experience__company": "Компания",
    "experience__post": "Должность",
    "experience__achievements": "Достижения",
    "experience__currently-work": "По настоящее время",
    "experience__work": "Работа",
    "education__level": "Уровень",
    "education__institution": "Учебное заведение",
    "education__faculty": "Факультет",
    "education__specialty": "Специальность",
    "education__date-end": "Год окончания",
    "add-cjm__title": "Название проекта",
    "add-cjm__company": "Компания",
    "add-cjm__industry": "Сфера деятельности",
    "add-cjm__tags": "Добавить теги",
    "add-cjm__description": "Описание проекта",
    "add-cjm__refresh-button-title": "Обновить превью работы",
    "add-cjm__delete-button-title": "Удалить ссылку",
    "add-artifact__title": "Название проекта",
    "add-artifact__company": "Компания",
    "add-artifact__industry": "Сфера деятельности",
    "add-artifact__tags": "Добавить теги",
    "add-artifact__description": "Описание проекта",
    "add-artifact__refresh-button-title": "Обновить превью работы",
    "add-artifact__delete-button-title": "Удалить",
    "add-artifact__back": "Назад к выбору работы",
    "add-artifact__artifact-type": "Тип работы",
    "add-artifact__platform": "Платформа",
    "add-artifact__link": "Ссылка",
    "add-pr__modal-pr": "Ссылка на проект в Notion",
    "add-pr__title": "Название проекта",
    "add-pr__company": "Компания",
    "add-pr__industry": "Сфера деятельности",
    "add-pr__tags": "Добавить теги",
    "add-pr__description": "Описание проекта",
  },
};


export const localeProfileLabels = {
  "en": {
    "tab_portfolio": "Portfolio",
    "tab_experience": "Work Experience",
    "tab_education": "Education",
    "tab_certificates": "Certificates",
    "job-looking": "Open to work",
    "work-now": "Present",
  },
  "ru": {
    "tab_portfolio": "Портфолио",
    "tab_experience": "Опыт работы",
    "tab_education": "Образование",
    "tab_certificates": "Сертификаты",
    "job-looking": "Ищу работу",
    "work-now": "Настоящее время",
  },
};
