import React from "react";

import Main from "./components/main/Main";
import Profile from "./components/profile/Profile";
import Settings from "./components/settings/Settings";
import ProfileSettings from "./components/settings/profile-settings/ProfileSettings";
import WorkExperience from "./components/settings/work-experience/WorkExperience";
import Notifications from "./components/settings/Notifications";
import Favourites from "./components/settings/Favourites";
import MyProfile from "./components/settings/MyProfile";
import Education from "./components/settings/education/Education";
import Description from "./components/settings/description/Description";
import AddCJM from "./components/settings/add-artifact/add-cjm/AddCJM";
import AddedArtifacts from "./components/settings/addedArtifacts/AddedArtifacts";
import ExpertRate from "./components/settings/ExpertRate";
import ArtifactPreview from "./components/preview/ArtifactPreview";
import ForProduct from "./components/for-product/ForProduct";
import ForCompany from "./components/for-company/ForCompany";
import ChooseArtifactTypeToAdd from "./components/settings/add-artifact/ChooseArtifactTypeToAdd";
import AddPR from "./components/settings/add-artifact/add-pr/AddPR";
import OtherPage from "./components/other-page/OtherPage";
import AddArtifact from "./components/settings/add-artifact/add-artifact-new/AddArtifact";
// import TestPage from "./components/testpages/TestPage";

const Routes = {
  "/": () => <Main />,
  // "/profile/:id": ({ id }) => <Profile userId={id} />,
  // "/profile*": () => <Profile />,
  "/card/:id": ({ id }) => <ArtifactPreview artifactID={id} />,
  "/settings/:action": ({ action }) => <Settings action={action} />,
  "/settings/:action/:index": ({ action, index }) => <Settings action={action} index={index} />,
  "/settings": () => <Settings action={SettingsItems.profileSettings} />,
  "/settings/": () => <Settings action={SettingsItems.profileSettings} />,
  "/forproduct": () => <ForProduct />,
  "/forcompany": () => <ForCompany />,
  // "/test": () => <TestPage />,
  "/:data": ({ data }) => <OtherPage data={data} />,
};

export const SettingsItems = {
  profileSettings: "profile-settings",
  notifications: "notifications",
  favourites: "favourites",
  myProfile: "my-profile",
  workExperience: "experience",
  education: "education",
  description: "description",
  chooseArtifactType: "choose-artifact-type",
  addArtifact: "add-artifact",
  changeArtifact: "change-artifact",
  addCJM: "add-cjm",
  changeCJM: "change-cjm",
  addPR: "add-pr",
  changePR: "change-pr",
  addedArtifacts: "added-artifacts",
  expertRate: "expert-rate",
  exit: "exit",
};


export const SettingsContent = (props) => {
  switch (props.action) {
    case SettingsItems.profileSettings: return <ProfileSettings {...props} />;
    case SettingsItems.notifications: return <Notifications {...props} />;
    case SettingsItems.favourites: return <Favourites {...props} />;
    case SettingsItems.myProfile: return <MyProfile {...props} />;
    case SettingsItems.workExperience: return <WorkExperience {...props} />;
    case SettingsItems.education: return <Education {...props} />;
    case SettingsItems.description: return <Description {...props} />;
    case SettingsItems.addArtifact: return <AddArtifact {...props} />;
    case SettingsItems.changeArtifact: return <AddArtifact {...props} />;
    case SettingsItems.chooseArtifactType: return <ChooseArtifactTypeToAdd {...props} />;
    case SettingsItems.addCJM: return <AddCJM {...props} />;
    case SettingsItems.changeCJM: return <AddCJM {...props} />;
    case SettingsItems.addPR: return <AddPR {...props} />;
    case SettingsItems.changePR: return <AddPR {...props} />;
    case SettingsItems.addedArtifacts: return <AddedArtifacts {...props} />;
    case SettingsItems.expertRate: return <ExpertRate {...props} />;
    default: return "Page not found 404";
  }
};


export const SettingsTitles = {
  "en": {
    [SettingsItems.profileSettings]: "Account Settings",
    [SettingsItems.notifications]: "notifications",
    [SettingsItems.favourites]: "favourites",
    [SettingsItems.myProfile]: "my_profile",
    [SettingsItems.workExperience]: "Work Experience",
    [SettingsItems.education]: "Education",
    [SettingsItems.description]: "About You",
    [SettingsItems.addArtifact]: "Add Work Sample",
    [SettingsItems.changeArtifact]: "Edit Work Sample",
    [SettingsItems.chooseArtifactType]: "Add Work Sample",
    [SettingsItems.addCJM]: "Add CJM",
    [SettingsItems.changeCJM]: "Edit CJM",
    [SettingsItems.addPR]: "Add Product Research",
    [SettingsItems.changePR]: "Edit Product Research",
    [SettingsItems.addedArtifacts]: "Your Work Samples",
    [SettingsItems.expertRate]: "expert_rate",
    [SettingsItems.exit]: "exit",
  },
  "ru": {
    [SettingsItems.profileSettings]: "Настройки профиля",
    [SettingsItems.notifications]: "notifications",
    [SettingsItems.favourites]: "favourites",
    [SettingsItems.myProfile]: "my_profile",
    [SettingsItems.workExperience]: "Опыт работы",
    [SettingsItems.education]: "Образование",
    [SettingsItems.description]: "Описание",
    [SettingsItems.addArtifact]: "Добавить работу",
    [SettingsItems.changeArtifact]: "Редактировать работу",
    [SettingsItems.chooseArtifactType]: "Добавить работу",
    [SettingsItems.addCJM]: "Добавление CJM",
    [SettingsItems.changeCJM]: "Редактирование CJM",
    [SettingsItems.addPR]: "Добавление Product Research",
    [SettingsItems.changePR]: "Редактирование Product Research",
    [SettingsItems.addedArtifacts]: "Добавленные работы",
    [SettingsItems.expertRate]: "expert_rate",
    [SettingsItems.exit]: "exit",
  },
};


export default Routes;
