import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import axios from "axios";
import { navigate } from "hookrouter";
import backgroundImage from "../../images/illustration.png";
import Menu from "./Menu";
import { getApiUrl, getCSRF, logging, Show } from "../../utils";
import { AppContext } from "../AppContext";
import { SettingsItems } from "../../Routes";
import { device } from "../../constants";
import Cards from "../cards/NewCards";
import InterProvider, { localePlaceholderTexts } from "../../Internationalization";
import AddButton from "../UI/buttons/AddButton";

const Main = () => {
  const { user, toggleLoginModal, locale } = useContext(AppContext);
  const [industries, setIndustries] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [rateOptions, setRateOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [cardIDs, setCardIDs] = useState([]);
  const [cards, setCards] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const countOfLoad = 12;
  useEffect(() => {
    fetch(getApiUrl("artifacts/filters/"))
      .then((res) => res.json())
      .then((result) => {
        const industryList = result.industries.map((value) => ({ value, key: value, label: value }));
        industryList.unshift({ key: "", value: "", label: localePlaceholderTexts[locale]["main-page__industry"] });
        setIndustries(industryList);
        const sortList = result.sorts[locale].map((value) => ({ value, key: value, label: value }));
        setSortOptions(sortList);
        const rateList = result.rates[locale].map((value) => ({ value, key: value, label: value }));
        rateList.unshift({ key: "", value: "", label: localePlaceholderTexts[locale]["main-page__rate"] });
        setRateOptions(rateList);
        const typeList = result.types[locale].map((value) => ({ value, key: value, label: value }));
        setTypeOptions(typeList);
      })
      .then(() => {
        const requestUrl = "artifacts/";
        fetch(getApiUrl(requestUrl))
          .then((res) => res.json())
          .then((result) => setCardIDs(result.artifacts_ids));
      })
      .then(() => setIsLoaded(true));
  }, [locale]);

  const getPartOfCards = async (clear = false) => {
    const start = clear ? 0 : cards.length;
    const ids = cardIDs ? cardIDs.slice(start, start + countOfLoad) : 0;
    if (ids.length > 0) {
      const res = await axios.get(getApiUrl("artifacts/get-artifacts/"), {
        headers: getCSRF(),
        params: { 'artifacts_ids': ids.join(',') },
      });
      if (res.status === 200) {
        const newCards = res.data.artifacts;
        clear ? setCards([...newCards]) : setCards(cards => [...cards, ...newCards]);
      }
    } else if (clear) {
      cards && setCards([]);
    }
  };

  useEffect(() => {
    getPartOfCards(true);
  }, [cardIDs]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectSort: isLoaded ? sortOptions[0].value : "",
      selectIndustry: isLoaded ? industries[0].value : "",
      selectRate: isLoaded ? rateOptions[0].value : "",
      selectType: isLoaded ? typeOptions[0].value : "",
      searchField: "",
      toggleFilter: false,
    },
    onSubmit: async (values) => {
      const res = await axios.get(getApiUrl("artifacts/"), {
        headers: getCSRF(),
        params: values,
      });
      if (res.status === 200) {
        const artifacts_ids = res.data.artifacts_ids;

        artifacts_ids.join(',') === cardIDs.join(',') || setCardIDs(res.data.artifacts_ids);
      }
      logging(res);
    },
  });

  const addButtonOnClick = () => {
    window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', 'main-add-cjm');
    if (user) {
      navigate(`/settings/${SettingsItems.addArtifact}`);
    }
    else {
      toggleLoginModal(true);
    }
  };
  return (
    <InterProvider id="main-page">
      <MainDiv>
        <BannerDiv>
          <div className="banner__header">
            <FormattedMessage id="header__title" />
          </div>
          <AddButton className="banner__btn-add" text={<FormattedMessage id="header__button-add" />} onClick={addButtonOnClick} />
        </BannerDiv>
        <Show when={isLoaded}>
          <Menu
            formik={formik}
            sortOptions={sortOptions}
            typeOptions={typeOptions}
            filterOptions={{
              industries,
              rateOptions,
            }}
          />
          <Cards cards={cards} noneText={<FormattedMessage id="nothing-found" />} />
          <div className="main__button-more-wrapper">
            <Show when={cards && (cards.length > 0) && (cards.length < cardIDs.length)}>
              <div className="main__button-more" onClick={() => getPartOfCards()}><FormattedMessage id="button__load-more" /></div>
            </Show>
          </div>
        </Show>
      </MainDiv>
    </InterProvider>
  );
};

const MainDiv = styled.div`
  background: linear-gradient(to bottom, #ffffff, #ffffff 375px, #f9f9f9 calc(5.55% + 375px));
  padding-left: 137px;
  padding-right: 137px;
  display: flex;
  flex-direction: column;

  .main__button-more-wrapper {
    width:100%;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    margin-top: 30px;
  }

  .main__button-more {
    text-align: center;
    width: 252px;
    height: 63px;
    border-radius: 5px;
    color: ${(props) => props.theme.colorDark};
    font-size: 16px;
    font-weight: bold;
    line-height: 63px;
    text-transform: uppercase;
    background-color: ${(props) => props.theme.colorGrey};
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: pointer;
  }

  @media ${device.tablet_landscape} {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media ${device.phone_landscape} {
    padding-left: 16px;
    padding-right: 16px;

    .main__button-more {
      width: 432px;
    }
  }
  @media ${device.phone_portrait} {
    .main__button-more {
      width: calc(100% - 16px);
      height: 48px;
      line-height: 48px;
      min-width: fit-content;
    }
  }
`;

const BannerDiv = styled.div`
  position: relative;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;

  @media ${device.tablet_portrait} {
    background-image: none;
  }

  .banner__header {
    min-width: 277px;
    margin-top: 67px;
    width: 553px;
    font-weight: bold;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    order: 0;
    flex-grow: 0;
    color: ${(props) => props.theme.colorDark};
  }

  .banner__btn-add {
    cursor: pointer;
    margin-top: 57px;
  }

  @media ${device.phone_landscape} {
    display: flex;
    flex-direction: column;

    .banner__header {
      padding-left: 10px;
      padding-right: 10px;
      align-self: center;
      font-size: 30px;
      line-height: 60px;
      width: auto;
    }

    .banner__btn-add {
      align-self: center;
    }
  }

  @media ${device.phone_portrait} {
    .banner__header {
      font-size: max(18px, 6vw);
      line-height: 40px;
    }
  }
`;

export default Main;
