import React, { useContext, useEffect, useReducer, useState } from "react";
import { DiscussionEmbed } from "disqus-react";
import { AppContext } from "../AppContext";

const CommentBlock = ({artifact, artifactID}) => {
  const {locale} = useContext(AppContext);
  const hostname = window.location.hostname.split(".");
  hostname[hostname.length - 1] = "ru";
  const currentUrl = `${window.location.protocol}//${hostname.join(".")}/card/${artifact.id}`;
  const initConfig = {
      url: currentUrl,
      id: artifact.id || 0,
      title: artifact ? artifact.title : "none",
      language: locale,
  };
  const reducerConfig = (state, data) => ({...state, ...data});
  const [config, setConfig] = useReducer(reducerConfig, initConfig);
  useEffect(() => {
    const current_config = {
      url: currentUrl,
      id: artifact.id || 0,
      title: artifact ? artifact.title : "none",
      language: locale,
    };
    setConfig(current_config);
  }, [artifactID, artifact, currentUrl, locale]);

  return <DiscussionEmbed shortname="productov-scythargon-ru" config={config} />;
};
export default CommentBlock;
