import React from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import backgroundImage from "../../../images/placeholder_cjm.png";
import { getMediaUrl } from "../../../utils";
import { Tags } from "../Sidebar";
import EstimationBlock from "./EstimationBlock";
import MainBlock from "./MainBlock";

const ArtifactBlock = ({ artifact, setArtifactID, artifactID }) => (
  <CJMDiv imgpath={artifact.preview_image ? getMediaUrl(`${artifact.preview_image}`) : backgroundImage}>
    <MainBlock artifact={artifact} setArtifactID={setArtifactID} artifactID={artifactID}/>
    <EstimationBlock artifact={artifact} />
    <Tags artifact={artifact} className="cjm-preview__cjm__tags" />
  </CJMDiv>
);

const CJMDiv = styled.div`
  display: flex;
  min-width: 687px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 60px;

  .cjm-preview__cjm__tags {
    display: none;
  }

  @media ${device.tablet_portrait} {
    min-width: 0;
    .cjm-preview__cjm__tags {
      display: flex;
      margin-top: 30px;
    }
  }
`;
export default ArtifactBlock;
