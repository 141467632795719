import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Field, FormikProvider } from "formik";
import { Show } from "../../utils";
import { CustomSelect } from "../settings/CustomFields";
import { device } from "../../constants";
import SearchInput from "../UI/inputs/SearchInput";
import ButtonFilter from "../UI/buttons/ButtonFilter";
import Select from "../UI/inputs/Select";

const Menu = (props) => {
  const [toggleFilter, setToggleFilter] = useState(props.formik.values.toggleFilter);

  return (
    <MenuDiv>
      <MainMenu
        formik={props.formik}
        sortOptions={props.sortOptions}
        typeOptions={props.typeOptions}
        toggleFilter={toggleFilter}
        setToggleFilter={setToggleFilter}
      />
      <Show when={toggleFilter}>
        <AdditionalMenu formik={props.formik} filterOptions={props.filterOptions} />
      </Show>
    </MenuDiv>
  );
};

const MainMenu = (props) => {
  const formik = props.formik;
  const theme = useTheme();

  const setFilter = () => {
    formik.setFieldValue("toggleFilter", !props.toggleFilter);
    props.setToggleFilter(!props.toggleFilter);
    formik.submitForm();
  };

  return (
    <FormikProvider value={formik}>
      <MainMenuForm onSubmit={formik.handleSubmit}>
        <div className="main-menu__first-part">
          <SearchInput className="main-menu__search-input" formik={formik} options={props.typeOptions} />
          <ButtonFilter className="main-menu__btn-filter" isActive={formik.values.toggleFilter} onClick={setFilter} />
        </div>
        <div className="main-menu__second-part">
          <Select
            className="menu__select-sort"
            valueName="selectSort"
            formik={formik}
            options={props.sortOptions}
            placeholder="Выбрать сферу деятельности"
            disableSearch={true}
            onChange={(e) => {
              formik.setFieldValue("selectSort", e.value);
              formik.submitForm();
            }}
          />
          <ButtonFilter className="main-menu__btn-filter" isActive={formik.values.toggleFilter} onClick={setFilter} />
        </div>
      </MainMenuForm>
    </FormikProvider>
  );
};

const AdditionalMenu = (props) => {
  const formik = props.formik;
  const industryOptions = props.filterOptions.industries;
  const rateOptions = props.filterOptions.rateOptions;

  return (
    <AdditionalMenuDiv>
      <Select
        className="menu__select-rate"
        valueName="selectRate"
        formik={formik}
        options={rateOptions}
        disableSearch={true}
        onChange={(e) => {
          formik.setFieldValue("selectRate", e.value);
          formik.submitForm();
        }}
      />
      <Select
        className="menu__select-industry"
        valueName="selectIndustry"
        formik={formik}
        options={industryOptions}
        disableSearch={true}
        onChange={(e) => {
          formik.setFieldValue("selectIndustry", e.value);
          formik.submitForm();
        }}
      />
    </AdditionalMenuDiv>
  );
};

const MenuDiv = styled.div`
  padding-top: 60px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;

  @media ${device.tablet_landscape} {
    margin-bottom: 60px;
  }
`;

const MainMenuForm = styled.div`
  display: flex;
  justify-content: space-between;

  .main-menu__first-part{
    display: flex;
    flex: 1;
  }
  .main-menu__second-part {
    display: flex;
  }
  .main-menu__second-part > .main-menu__btn-filter {
      display: none;
  }

  .main-menu__search-input {
    max-width: 665px;
    margin-right: 20px;
  }

  .menu__select-sort {
    width: 255px;
  }

  @media ${device.tablet_landscape} {
    .main-menu__search-input {
      max-width: 526px;
      margin-right: 32px;
    }
  }

  @media ${device.tablet_portrait} {
    flex-direction: column;

    .main-menu__search-input {
      max-width: 592px;
      margin-right: 0;
    }

    .main-menu__first-part {
      margin-bottom: 13px;
      .main-menu__btn-filter {
        display: none;
      }
    }
    .main-menu__second-part > .main-menu__btn-filter {
        display: flex;
    }
    .menu__select-sort {
      margin-right: 20px;
    }
  }
`;

const AdditionalMenuDiv = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;

  .menu__select-industry,
  .menu__select-rate {
    width: 331px;
  }

  .menu__select-rate {
    margin-right: 6px;
  }

  @media ${device.tablet_portrait} {
    .menu__select-industry,
    .menu__select-rate {
      width: 255px;
    }
  }

  @media ${device.phone_landscape} {
    flex-direction: column;

    .menu__select-rate {
      margin-right: 0px;
      margin-bottom: 6px;
    }
  }
`;

export default Menu;
