import React, { useContext } from "react";
import { A, navigate } from "hookrouter";
import styled from "styled-components";
import { SettingsItems } from "../../../Routes";
import { AppContext } from "../../AppContext";
import RoundedBackButton from "../../UI/buttons/rounded/RoundedBackButton";
import { localeSettingsLabels } from "../../../Internationalization";

const BackToArtifactSelect = (props) => {
  const {locale} = useContext(AppContext);
  return (
    <StyledDiv {...props}>
      <RoundedBackButton
        className="add-artifact__back-button"
        onClick={() => navigate(`/settings/${SettingsItems.chooseArtifactType}`)}
      />
      <A className="add-artifact__label" href={`/settings/${SettingsItems.chooseArtifactType}`}>{localeSettingsLabels[locale]["add-artifact__back"]}</A>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  .add-artifact__back-button {
    margin-right: 11px;
  }
  .add-artifact__label {
    font-size: 14px;
    color: ${props => props.theme.colorMain};
  }
`;

export default BackToArtifactSelect;
