import React from "react";
import styled from "styled-components";
import { device } from "../../constants";
import CrossButton from "../UI/buttons/CrossButton";

const ModalBackground = ({ onClick, children }) => (
  <BackgroundDiv onClick={onClick}>
    {children}
    <CrossButton className="modal-background__btn-cross" />
  </BackgroundDiv>
);

const BackgroundDiv = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(18, 28, 61, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 7;

  .modal-background__btn-cross {
    position: absolute;
    top: 35px;
    right: 138px;
  }

  @media ${device.tablet_landscape} {
    .modal-background__btn-cross {
      top: 33px;
      right: 24px;
    }
  }

  @media ${device.phone_landscape} {
    grid-template-columns: 1fr;
    .modal-background__btn-cross {
      display: none;
    }
  }
`;

export default ModalBackground;
