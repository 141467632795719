import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { device } from "../../constants";
import { getApiUrl, logging } from "../../utils";
import { AppContext } from "../AppContext";
import LeftSide from "./left-side/LeftSide";
import RightSide from "./right-side/RightSide";

const Profile = ({ userId }) => {
  const [profileData, setProfileData] = useState(null);
  const { ownProfile } = useContext(AppContext);
  useEffect(() => {
    const fetchProfileData = async () => {
      const request = await axios.get(getApiUrl(`profile/${userId}`));
      const data = request.data;
      if (data) {
        logging(data);
        setProfileData(data);
      }
    };
    if (userId) {
      fetchProfileData();
    }

  }, [userId]);
  if (!profileData) {
    return null;
  }
  const isOwner = ownProfile && (ownProfile.id === profileData.id);
  return (
    <StyledDiv>
      <LeftSide className="profile__left-side" profileData={profileData} isOwner={isOwner} />
      <RightSide className="profile__right-side" profileData={profileData} isOwner={isOwner} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  padding: 60px 138px 60px;
  --leftSideWidth: 335px;
  --leftSideMarRight: 140px;
  --colorGrey: ${props => props.theme.colorGreyLight};
  background: linear-gradient(180deg, #FFFFFF 0%, var(--colorGrey) 130px, var(--colorGrey) 100%);
  .profile__left-side {
    margin-right: var(--leftSideMarRight);
    width: var(--leftSideWidth);
  }
  .profile__right-side {
    width:calc(100% - var(--leftSideWidth) - var(--leftSideMarRight));
  }

  @media ${device.desktop} {
    padding: 40px 138px;
    --leftSideMarRight: 74px;
  }
  @media ${device.tablet_landscape} {
    padding: 40px 24px;
    --leftSideMarRight: 103px;
  }
  @media ${device.tablet_portrait} {
    flex-direction: column;
    --leftSideMarRight: 0px;
    .profile__left-side {
      width: 100%;
      margin-bottom: 30px;
    }
    .profile__right-side {
      width: 100%;
    }
  }
  @media ${device.phone_landscape} {
    padding: 30px 16px;
  }
`;

export default Profile;
