import React from "react";
import styled from "styled-components";
import { Show } from "../../../utils";
import Cards from "../../cards/NewCards";

const Portfolio = ({ profileData, isOwner }) => {
  const artifacts = profileData.artifacts;
  return (
    <Show when={artifacts}>
      <StyledDiv>
        <Cards cards={artifacts} noneText="" addCard isOwner={isOwner} className="profile__portfolio__cards"/>
      </StyledDiv>
    </Show>
  );
};

const StyledDiv = styled.div`
  .profile__portfolio__cards {
    grid-gap: 20px;
  }
`;

export default Portfolio;
