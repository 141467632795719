import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import Certificates from "./Certificates";
import Education from "./Education";
import Portfolio from "./Portfolio";
import ProfileNav from "./ProfileNav";
import WorkExperience from "./WorkExperience";

const RightSide = ({ profileData, isOwner, className }) => {
  const [page, setPage] = useState(0);
  const pages = useMemo(() => {
    const pageProps = { profileData, isOwner };
    return [
      <Portfolio {...pageProps} />,
      <WorkExperience {...pageProps} />,
      <Education {...pageProps} />,
      <Certificates {...pageProps} />,
    ];
  }, [profileData, isOwner]);

  return (
    <StyledDiv className={className}>
      <ProfileNav item={page} setItem={setPage} className="right-side__profile-nav" profileData={profileData} />
      {pages[page]}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  .right-side__profile-nav {
    margin-bottom: 40px;
  }
  .profile__right-side__edit-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media ${device.phone_landscape} {
    .right-side__profile-nav {
      margin-bottom: 30px;
    }
  }
`;

export default RightSide;
