import React from "react";
import styled from "styled-components";
import { addClass } from "../../../../utils";

const RoundedButton = ({ children, className, ...props }) => (
  <StyledButton className={addClass("rounded-button", className)} {...props}>
    {children}
  </StyledButton>
);

const StyledButton = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  stroke-width: 2px;
  width: 35px;
  height: 35px;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default RoundedButton;
