import React, { useContext } from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import { localeSettingsLabels } from "../../../Internationalization";
import { activeClass } from "../../../utils";
import { AppContext } from "../../AppContext";

const WorkList = ({ works, workIndex, setWorkIndex }) => {
  const {locale} = useContext(AppContext);
  const render = [];
  if (works.length > 0) {
    works.map((val, index) => {
      render.push(
        <div key={index} className={activeClass("work-list__tab", workIndex === index)} onClick={() => setWorkIndex(index)}>
          {`${localeSettingsLabels[locale]["experience__work"]} ${index + 1}`}
        </div>
      );
      return null;
    });
  }
  return <StyledList>{render}</StyledList>;
};

const StyledList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  height: 30px;

  & > * + * {
    margin-left: 40px;
  }

  .work-list__tab {
    position: relative;
    cursor: pointer;
    height: 25px;
    line-height: 30px;
    border-radius: 5px 5px 0px 0px;
    font-weight: bold;
    font-size: min(5vw, 18px);
    text-align: center;
    vertical-align: center;
    white-space: nowrap;
    color: ${(props) => props.theme.colorDark};
  }

  .active-state {
    color: ${(props) => props.theme.colorMain};
    &:after {
      position: absolute;
      display: block;
      height: 4px;
      width: 43px;
      content: "";
      background: ${(props) => props.theme.colorMain};
      bottom: -5px;
      left: calc(50% - 21.5px);
    }
  }

  @media ${device.phone_landscape} {
    justify-content: space-between;
    & > * + * {
    margin-left: 0px;
  }
  }

  @media ${device.phone_portrait} {
    .work-list__tab {
    }
  }
`;

export default WorkList;
