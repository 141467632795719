import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { A } from "hookrouter";
import { AppContext } from "./AppContext";
import { device } from "../constants";

export const ClearButton = (props) => {
  const { setConfirmProps } = useContext(AppContext);
  const theme = useTheme();

  const onClick = async (e) => {
    setConfirmProps({
      confirmText: props.text,
      confirmButtonText: props.buttonText || "Удалить",
      confirmAction: () => props.onClick(),
      visible: true,
    });
  };

  return (
    <ClearButtonDiv style={props.style} onClick={onClick}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="#E1E1E3" />
        <path
          d="M10.9561 13.9117H12.9266H28.6913"
          stroke={theme.colorGreyLight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8822 13.9118V11.9412C15.8822 11.4185 16.0898 10.9173 16.4593 10.5478C16.8289 10.1782 17.3301 9.97058 17.8527 9.97058H21.7939C22.3166 9.97058 22.8178 10.1782 23.1873 10.5478C23.5569 10.9173 23.7645 11.4185 23.7645 11.9412V13.9118M26.7204 13.9118V27.7059C26.7204 28.2285 26.5128 28.7297 26.1432 29.0993C25.7737 29.4688 25.2724 29.6765 24.7498 29.6765H14.8969C14.3742 29.6765 13.873 29.4688 13.5034 29.0993C13.1339 28.7297 12.9263 28.2285 12.9263 27.7059V13.9118H26.7204Z"
          stroke={theme.colorGreyLight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.853 18.8383V24.75"
          stroke={theme.colorGreyLight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7939 18.8383V24.75"
          stroke={theme.colorGreyLight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ClearButtonDiv>
  );
};

const ClearButtonDiv = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

export const PlusButton = (props) => {
  const theme = useTheme();
  return (
    <PlusButtonDiv onClick={props.onClick}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={theme.colorMain} />
        <path d="M20 13V27" stroke={theme.colorGreyLight} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 20H27" stroke={theme.colorGreyLight} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </PlusButtonDiv>
  );
};

const PlusButtonDiv = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

export const CrossButton = (props) => (
  <CrossButtonDiv
    onClick={props.onClick}
    strokeColor={props.color}
    strokeColorOnTabletPortrait={props.strokeColorOnTabletPortrait || props.strokeColor}
  >
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.5 13.5L13.5 40.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 13.5L40.5 40.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </CrossButtonDiv>
);

const CrossButtonDiv = styled.div`
  path {
    stroke: ${(props) => props.strokeColor || props.theme.colorGreyLight};
  }

  @media ${device.tablet_portrait} {
    path {
      stroke: ${(props) => props.strokeColorOnTabletPortrait || props.theme.colorGreyLight};
    }
  }
`;

export const SmallCrossButton = (props) => {
  const theme = useTheme();
  return (
    <div className="btn-cross" onClick={props.onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 6L6 18"
          stroke={props.color || theme.colorDark || "black"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke={props.color || theme.colorDark || "black"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const DownloadButton = (props) => {
  const theme = useTheme();
  return (
    <DownloadButtonDiv style={props.style} onClick={props.onClick}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29 22V26C29 26.5304 28.7893 27.0391 28.4142 27.4142C28.0391 27.7893 27.5304 28 27 28H13C12.4696 28 11.9609 27.7893 11.5858 27.4142C11.2107 27.0391 11 26.5304 11 26V22"
          stroke={theme.colorDark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M15 17L20 22L25 17" stroke={theme.colorDark} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20 22V10" stroke={theme.colorDark} strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="20" cy="20" r="19.5" stroke={theme.colorDark} />
      </svg>
    </DownloadButtonDiv>
  );
};

const DownloadButtonDiv = styled.div`
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

export const RemoveButton = (props) => {
  const theme = useTheme();
  return (
    <RemoveButtonDiv style={props.style} onClick={props.onClick}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9561 13.9116H12.9266H28.6913"
          stroke={theme.colorDark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8822 13.9119V11.9413C15.8822 11.4187 16.0898 10.9174 16.4593 10.5479C16.8289 10.1783 17.3301 9.9707 17.8527 9.9707H21.7939C22.3166 9.9707 22.8178 10.1783 23.1873 10.5479C23.5569 10.9174 23.7645 11.4187 23.7645 11.9413V13.9119M26.7204 13.9119V27.706C26.7204 28.2286 26.5128 28.7299 26.1432 29.0994C25.7737 29.469 25.2724 29.6766 24.7498 29.6766H14.8969C14.3742 29.6766 13.873 29.469 13.5034 29.0994C13.1339 28.7299 12.9263 28.2286 12.9263 27.706V13.9119H26.7204Z"
          stroke={theme.colorDark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M17.853 18.8384V24.7501" stroke={theme.colorDark} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.7939 18.8384V24.7501" stroke={theme.colorDark} strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="20" cy="20" r="19.5" stroke={theme.colorDark} />
      </svg>
    </RemoveButtonDiv>
  );
};

const RemoveButtonDiv = styled.div`
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

export const SearchButton = (props) => {
  const val = null;
  return (
    <SearchButtonDiv>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" />
    </SearchButtonDiv>
  );
};

const SearchButtonDiv = styled.div`
  width: 24px;
  height: 24px;
`;

export const AddButton = (props) => {
  const { text, onClick } = props;
  return (
    <AddButtonDiv onClick={onClick}>
      <div className="add-button__btn-text">{text || "Добавить CJM"}</div>
      <div className="add-button__btn-img">
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 18.5H27" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M20 12V26" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </AddButtonDiv>
  );
};

const AddButtonDiv = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  background: ${(props) => props.theme.colorMain};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  justify-items: center;

  .add-button__btn-text {
    font-weight: bold;
    padding-left: 10px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: ${(props) => props.theme.colorWhite};
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .add-button__btn-img {
    vertical-align: middle;
    align-items: center;
  }
`;

export const Step = (props) => (
  <StepDiv height={props.height}>
    <div className="step__image">
      <div className="step__number">{props.number}</div>
      {props.children}
    </div>
    <div className="step__title step__mobile">{props.title}</div>
    <div className="step__text">
      <div className="step__title">{props.title}</div>
      <div className="step__content">{props.text}</div>
    </div>
  </StepDiv>
);

const StepDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 354px;
  min-width: 300px;

  .step__image {
    position: relative;
    height: 144px;
    width: 144px;
    border-radius: 50%;
    margin-bottom: 69px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .step__title {
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
    height: ${(props) => props.height || "60px"};
  }

  .step__mobile {
    display: none;
  }

  .step__content {
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    font-size: 16px;
  }

  .step__number {
    height: 59px;
    width: 59px;
    top: -19px;
    left: 0;
    position: absolute;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colorMain};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: ${(props) => props.theme.colorWhite};
  }

  @media ${device.tablet_landscape} {
    flex-direction: row;
    align-items: center;
    & > * + * {
      margin-left: 83px;
    }
    max-width: none;

    .step__title {
      height: auto;
    }

    .step__image {
      margin-bottom: 0;
    }

    .step__text {
      max-width: 350px;
    }
  }

  @media ${device.tablet_portrait} {
    & > * + * {
      margin-left: 46px;
    }
    .step__number {
      height: 51px;
      width: 51px;
      top: -16px;
    }
    .step__image {
      height: 125px;
      width: 125px;
    }
  }

  @media ${device.phone_landscape} {
    & > * + * {
      margin-left: 35px;
    }
    .step__number {
      height: 38px;
      width: 38px;
      top: -12px;
      font-size: 20px;
    }
    .step__image {
      height: 97px;
      width: 97px;
    }
    .step__title {
      white-space: normal;
      margin-bottom: 10px;
    }
  }

  @media ${device.phone_portrait} {
    min-width: 288px;
    display: grid;
    grid-template-columns: 68px 1fr;
    grid-template-areas:
      "image title"
      "text text";
    grid-gap: 10px;
    & > * + * {
      margin-left: 0;
    }
    .step__title {
      display: none;
      font-size: 18px;
    }

    .step__number {
      height: 32px;
      width: 32px;
      top: -10px;
      font-size: 16px;
    }

    .step__image {
      grid-area: image;
      height: 68px;
      width: 68px;
    }

    .step__mobile {
      grid-area: title;
      display: block;
      align-self: center;
    }

    .step__text {
      grid-area: text;
    }

    .step__content {
      font-size: 14px;
      max-width: calc(100% - 32px);
    }
  }
`;

export const ModalBackground = ({ onClick, children }) => (
  <BackgroundDiv onClick={onClick}>
    {children}
    <div className="modal-background__btn-cross">
      <CrossButton />
    </div>
  </BackgroundDiv>
);

const BackgroundDiv = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(18, 28, 61, 0.7);
  position: fixed;
  top: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 7;

  .modal-background__btn-cross {
    display: ${(props) => props.hideCross ? "none" : "block"};
    position: absolute;
    top: 54px;
    right: 138px;
    cursor: pointer;
  }

  @media ${device.tablet_landscape} {
    .modal-background__btn-cross {
      top: 31px;
      right: 24px;
    }
  }

  @media ${device.phone_landscape} {
    grid-template-columns: 1fr;
    .modal-background__btn-cross {
      display: none;
    }
  }
`;
