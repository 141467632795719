import React from "react";

const Facebook = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5997 1.80078H10.4397C9.48491 1.80078 8.56923 2.18007 7.8941 2.8552C7.21897 3.53033 6.83969 4.446 6.83969 5.40078V7.56078H4.67969V10.4408H6.83969V16.2008H9.71969V10.4408H11.8797L12.5997 7.56078H9.71969V5.40078C9.71969 5.20983 9.79554 5.02669 9.93057 4.89166C10.0656 4.75664 10.2487 4.68078 10.4397 4.68078H12.5997V1.80078Z"
      fill="white"
    />
  </svg>

);

export default Facebook;
