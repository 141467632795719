import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import HowCreate from "./HowCreateBlock";
import HowEvaluated from "./HowEvaluatedBlock";
import HowItWorks from "./HowItWorksBlock";
import IfHavent from "./IfHaventBlock";
import Statistics from "./StatisticsBlock";
import Quotation from "./QuotationBlock";
import ForProductHeader from "./HeaderBlock";
import { AppContext } from "../AppContext";
import { device, urls } from "../../constants";
import InterProvider from "../../Internationalization";

const ForProduct = () => {
  const { setUrlState } = useContext(AppContext);

  useEffect(() => {
    setUrlState(urls.product);
    return () => {
      setUrlState(null);
    };
  }, [setUrlState]);

  return (
    <InterProvider id="forproduct">
      <ForProductDiv>
        <ForProductHeader />
        <Quotation />
        <Statistics />
        <HowItWorks />
        {/* <IfHavent /> */}
        {/* <HowEvaluated /> */}
        <HowCreate />
      </ForProductDiv>
    </InterProvider>
  );
};

const ForProductDiv = styled.div`
  background: ${props => props.theme.colorLinearBG};
  padding: 37px 137px 0px 137px;
  display: flex;
  flex-direction: column;
  min-width: calc(320px - 32px);
  .for-product__block-title {
    color: ${(props) => props.theme.colorDark};
    font-size: 34px;
    font-weight: 900;
    line-height: 70px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 50px;
  }

  .for-product__block-text {
    max-width: 454px;
    padding-top: 10px;
    font-size: 16px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
  }

  @media ${device.tablet_landscape} {
    padding-left: 24px;
    padding-right: 24px;

    .for-product__block-text {
      max-width: 887px;
      margin-bottom: 40px;
      padding-top: 0px;
    }
  }
  @media ${device.tablet_portrait} {
    .for-product__block-title {
      font-size: 24px;
      margin-bottom: 40px;
      line-height: 50px;
    }
  }
  @media ${device.phone_portrait} {
    padding-left: 16px;
    padding-right: 16px;
    .for-product__block-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
`;

export default ForProduct;
