import React, { useState, useContext } from "react";
import styled from "styled-components";
import ReactSelect from "react-select";
import { addClass, Show } from "../../../utils";
import { device } from "../../../constants";
import { AppContext } from "../../AppContext";
import { localeErrorTexts, localePlaceholderTexts } from "../../../Internationalization";

const Select = ({ valueName, formik, options, disableSearch, placeholder = "", hideFirst, onChange, hideAfterRow, className, errorCondition }) => {
  const isError = !!(errorCondition || (formik.touched[valueName] && formik.errors[valueName]));
  const { locale } = useContext(AppContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const getValue = () => (options ? options.find((option) => option.value === formik.values[valueName]) || null : "");
  const onChangeEvent = (e) => {
    formik.setFieldTouched(valueName);
    onChange ? onChange(e) : formik.setFieldValue(valueName, e.value);
  };
  const cName = addClass(className, "error", isError);

  const onBlur = (e) => {
    setTimeout(() => {
      formik.setFieldTouched(valueName);
      formik.handleBlur(e);
    }, 20);
  };

  return (
    <StyledSelect
      className={cName}
      value={formik.values[valueName]} // ?
      isMenuOpen={isMenuOpen}
      hideFirst={!!hideFirst}
    >
      <ReactSelect
        classNamePrefix="select"
        className="select"
        isSearchable={!disableSearch}
        name={valueName}
        placeholder={localePlaceholderTexts[locale][placeholder] || placeholder}
        options={options}
        value={getValue()}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        onChange={onChangeEvent}
        onBlur={onBlur}
        defaultMenuIsOpen={false}
      />
      <Show when={!hideAfterRow}>
        <div className="select__after-row">
          <Show when={isError} otherwise={<div />}>
            <div className="select__error-label">{localeErrorTexts[locale][formik.errors[valueName]] || formik.errors[valueName]}</div>
          </Show>
        </div>
      </Show>
    </StyledSelect>
  );
};

const StyledSelect = styled.div`
  width: 100%;
  input {
    -webkit-appearance: none;
  }

  .select {
    .select__placeholder {
      color: ${(props) => props.theme.colorFont};
    }

    .select__single-value {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      color: ${props => props.theme.colorDark};
    }

    .select__control {
      height: 48px;
      cursor: pointer;
      border: 2px transparent;
      box-shadow: 0 0 0 1px ${(props) => props.theme.colorGrey2};
      border-radius: 5px;
      margin-right: 0;

      .select__value-container {
        padding: 2px 0px 0px 12px;
      }

      .select__indicators {
        .select__indicator-separator {
          display: none;
        }
        .select__dropdown-indicator {
          color: ${(props) => props.theme.colorDark};
          transform: scale(1, ${(props) => (props.isMenuOpen ? -1 : 1)});
        }
      }
    }

    .select__control--is-focused {
      outline: none;
      box-shadow: 0 0 0 2px ${(props) => props.theme.colorMain};
      border-radius: 5px;
    }
  }

  &.error {
    .select {
      .select__control {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};
      }

      .select__control--is-focused {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};
      }
    }
  }

  .select__menu {
    z-index: 5;
    width: fit-content;
    min-width: 100%;
    max-width: 500px;
    .select__menu-list {
      min-width: 100%;
      max-width: 100%;
      width: max-content;
      display: flex;
      flex-direction: column;
      .select__option {
        flex-shrink: 0;
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 12px;
        line-height: 40px;
        &:first-child {
          display: ${(props) => (props.hideFirst ? "none" : "flex")};
        }
        &:last-child {
          display: flex;
        }
      }
    }
  }

  .select__error-label {
    margin-top: 4px;
    color: ${(props) => props.theme.colorRed};
    font-size: 12px;
    line-height: 13px;
  }


  @media ${device.phone_landscape} {
    .select__menu {
      max-width: 448px;
    }
  }

  @media ${device.phone_landscape} {
    .select__menu {
      max-width: 288px;
    }
  }
`;

export default Select;
