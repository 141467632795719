import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { localeButtonTexts } from "../../../../Internationalization";
import { AppContext } from "../../../AppContext";
import Bin from "../../SVGs/Bin";
import RoundedButton from "./RoundedButton";

const DeleteButton = ({className, onClick, text, buttonText, ...props}) => {
  const { setConfirmProps, locale } = useContext(AppContext);

  const handleClick = async (e) => {
    setConfirmProps({
      confirmText: text,
      confirmButtonText: buttonText || localeButtonTexts[locale].delete,
      confirmAction: () => onClick(),
      visible: true,
    });
  };
  return (
    <StyledButton className={className}>
      <RoundedButton {...props} onClick={handleClick}>
        <Bin />
      </RoundedButton>
    </StyledButton>
  );
};

const StyledButton = styled.div`
  .rounded-button {
    background-color: ${props => props.theme.colorWhite};
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    stroke: ${props => props.theme.colorRed};
  }
`;

export default DeleteButton;
