import React from "react";

const Telegram = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 3.79204C22.886 4.32791 22.7663 4.86264 22.6591 5.40022C21.9464 8.97859 21.2354 12.557 20.525 16.1359C20.2036 17.7538 19.8799 19.3711 19.5636 20.9896C19.3901 21.8795 18.3187 22.3044 17.598 21.7542C14.9815 19.7583 12.3672 17.759 9.75245 15.7602C9.71865 15.7344 9.68828 15.7053 9.63901 15.6624C9.80745 15.5097 9.96844 15.3633 10.1306 15.2174C13.2174 12.4432 16.3049 9.67002 19.3889 6.89288C19.5195 6.77507 19.6421 6.63724 19.7309 6.48626C19.8432 6.29639 19.7401 6.14769 19.5195 6.19287C19.3224 6.23348 19.1231 6.3164 18.9518 6.42392C17.4616 7.35955 15.9778 8.3049 14.4922 9.24681C11.937 10.867 9.3812 12.4855 6.82943 14.1102C6.6799 14.2052 6.55787 14.2155 6.39458 14.16C4.91302 13.6562 3.42859 13.1603 1.94474 12.6622C1.52135 12.5204 1.17703 12.2865 1 11.857C1 11.7569 1 11.6568 1 11.5567C1.18391 11.2084 1.49385 11.0163 1.84677 10.8779C8.15458 8.40784 14.4612 5.93609 20.7702 3.46949C21.1816 3.30879 21.5986 3.15152 22.0266 3.04457C22.5388 2.91704 22.8052 3.0623 23 3.4918C23 3.59188 23 3.69196 23 3.79204Z"
      fill="white"
    />
  </svg>
);

export default Telegram;
