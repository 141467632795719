import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { device } from "../../constants";

const HowEvaluated = () => {
  const miroID = "o9J_ln-GUhM=";
  return (
    <HowEvaluatedDiv>
      <div className="for-product__block-title"><FormattedMessage id="howevaluated__title" defaultMessage=""/></div>
      <div className="how-evaluated__row">
        <div className="how-evaluated__miro">
          <iframe
          key={miroID}
          width="100%" height="100%" title="miro"
          src={`https://miro.com/app/embed/${miroID}/?autoplay=yep`}
          frameBorder="0" scrolling="no" allowFullScreen />
        </div>
        <div className="for-product__block-text how-evaluated__text">
          Эксперты будут оценивать твою CJM по вот этому шаблону. Им важно увидеть:
          <br />
          <br />
          <ul>
            <li>исследование рынка и других похожих решений</li>
            <li>как ты приходишь к своим решениям</li>
            <li>на какие метрики твое решение повлияет</li>
          </ul>
          <br />
          Просто генерация идей без исследования других решений на рынке не интересна, потому что так сложно оценить идею объективно.
        </div>
      </div>
    </HowEvaluatedDiv>
  );
};

const HowEvaluatedDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;

  .how-evaluated__row {
    display: flex;
    justify-content: space-between;
  }

  .how-evaluated__text {
    margin-left: 20px;

    ul {
      margin-left: 7px;
      list-style: none;
      li:before{ content:"• "; font-size:16px; }
    }
  }

  .how-evaluated__miro {
    width: 572px;
    min-width: 450px;
    padding-bottom: min(62.5%, 357.5px);
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  @media ${device.tablet_landscape} {
    .how-evaluated__row {
      flex-direction: column;
    }
    .how-evaluated__miro {
      order: 1;
      width: 100%;
      min-width: 0;
      padding-bottom: 62.5%;
    }
    .how-evaluated__text {
      margin-left: 0px;
    }
  }

  @media ${device.phone_landscape} {
    margin-bottom: 70px;
  }
`;

export default HowEvaluated;
