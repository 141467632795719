import { A } from 'hookrouter';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { device, urls } from '../../constants';
import logo from '../../images/logo.svg';
import LinkButton from "../UI/buttons/LinkButton";
import Button from '../UI/buttons/Button';
import Telegram from '../UI/SVGs/Telegram';
import InterProvider from '../../Internationalization';

const Footer = () => (
  <InterProvider id="footer">
    <FooterDiv>
      <Logo />
      <FooterButtons />
      <Copyright />
      <Community
        joinButtonText={
          <FormattedMessage
            id="button__join"
          />}
        communityText={
          <FormattedMessage
            id="community_text"
          />}
      />
    </FooterDiv>
  </InterProvider>
);

const FooterDiv = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-areas:
  "logo buttons"
  "copyright community";
  grid-row-gap: 55px;
  margin: 75px 138px 50px;

  @media ${device.tablet_landscape} {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media ${device.tablet_portrait} {
    grid-template-areas:
    "logo"
    "community"
    "copyright";
    justify-content: stretch;
    grid-row-gap: 50px;
    margin-bottom: 24px;
  }

  @media ${device.phone_landscape} {
    margin-left: 16px;
    margin-right: 16px;
  }

  @media ${device.phone_portrait} {
    margin-top: 45px;
  }
`;

const Logo = () => (
  <LogoDiv>
    <A className="logo__svg" onClick={() => window.scrollTo(0, 0)} href="/">
      <img src={logo} alt="Underdoc" />
    </A>
    <div className="logo__buttons">
      <a target="_blank" rel="noreferrer" href="https://t.me/underdoc_community">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 8.33349C16.9482 8.55912 16.8937 8.78427 16.8451 9.01062C16.5211 10.5173 16.1979 12.024 15.875 13.5309C15.7289 14.2121 15.5818 14.8931 15.438 15.5746C15.3591 15.9493 14.8721 16.1282 14.5445 15.8965C13.3552 15.0561 12.1669 14.2143 10.9784 13.3727C10.963 13.3619 10.9492 13.3496 10.9268 13.3315C11.0034 13.2672 11.0766 13.2056 11.1503 13.1442C12.5534 11.9761 13.9568 10.8084 15.3586 9.63911C15.418 9.5895 15.4737 9.53147 15.5141 9.4679C15.5651 9.38795 15.5182 9.32535 15.418 9.34437C15.3284 9.36146 15.2378 9.39638 15.1599 9.44165C14.4826 9.8356 13.8081 10.2336 13.1328 10.6302C11.9714 11.3124 10.8096 11.9939 9.64974 12.678C9.58177 12.718 9.5263 12.7223 9.45208 12.6989C8.77865 12.4868 8.10391 12.278 7.42943 12.0683C7.23698 12.0086 7.08047 11.9101 7 11.7292C7 11.6871 7 11.645 7 11.6028C7.08359 11.4562 7.22448 11.3753 7.3849 11.317C10.2521 10.277 13.1187 9.23625 15.9865 8.19768C16.1734 8.13002 16.363 8.0638 16.5576 8.01877C16.7904 7.96507 16.9115 8.02623 17 8.20707C17 8.24921 17 8.29135 17 8.33349Z" />
        </svg>
      </a>
      <a target="_blank" rel="noreferrer" href="https://www.facebook.com/groups/700601981111878">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.3972 7.2002H12.9572C12.3207 7.2002 11.7102 7.45305 11.2601 7.90314C10.81 8.35323 10.5572 8.96368 10.5572 9.6002V11.0402H9.11719V12.9602H10.5572V16.8002H12.4772V12.9602H13.9172L14.3972 11.0402H12.4772V9.6002C12.4772 9.47289 12.5278 9.3508 12.6178 9.26078C12.7078 9.17077 12.8299 9.1202 12.9572 9.1202H14.3972V7.2002Z" />
        </svg>
      </a>
    </div>
  </LogoDiv>
);

const LogoDiv = styled.div`
  grid-area: logo;
  .logo__svg img {
    height: 16px;
    width: 133px;
    margin-bottom: 20px;
  }

  .logo__buttons {
    display: flex;
    & > * + * {
      margin-left: 5px;
    }
    a {
      svg path {
        fill: ${(props) => props.theme.colorFont};
      }
      &:hover {
        svg path {
          fill: ${(props) => props.theme.colorMain};
        }
      }
    }
  }

  @media ${device.tablet_portrait} {
    .logo__buttons {
      & > * + * {
        margin-left: 20px;
      }
    }
  }
`;

const Copyright = () => (
  <CopyrightDiv>
    <div className="copyright__symbol">©</div>
    <div className="copyright__text">2021 Underdoc</div>
  </CopyrightDiv>
);

const CopyrightDiv = styled.div`
  grid-area: copyright;
  display: flex;
  color: ${(props) => props.theme.colorFont};
  font-size: 14px;
  align-items: flex-end;
  .copyright__symbol {
    line-height: 30px;
    font-weight: bold;
  }

  .copyright__text {
    line-height: 30px;
  }
`;

const FooterButtons = () => (
  <FooterButtonsDiv>
    <LinkButton onClick={() => window.scrollTo(0, 0)} className="footer-buttons__button" url={urls.product}>
      <FormattedMessage id="link__product" />
    </LinkButton>
    <LinkButton onClick={() => window.scrollTo(0, 0)} className="footer-buttons__button" url={urls.company}>
      <FormattedMessage id="link__company" />
    </LinkButton>
    <LinkButton className="footer-buttons__button" simple url={`${process.env.PUBLIC_URL}/confidence.pdf`}>
      <FormattedMessage id="link__politics" />
    </LinkButton>
  </FooterButtonsDiv>
);
const FooterButtonsDiv = styled.div`
  grid-area: buttons;
  display: flex;

  & > * + * {
    margin-left: 90px;
  }

  .footer-buttons__button {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    position: relative;
    color: ${(props) => props.theme.colorDark};
    &:hover {
      color: ${(props) => props.theme.colorMain};
    }
    &.active-state {
      color: ${(props) => props.theme.colorMain};
      &:after {
        position: absolute;
        display: block;
        width: 43px;
        content: "";
        height: 4px;
        background: ${(props) => props.theme.colorMain};
        bottom: 0;
        left: calc(50% - 21.5px);
      }
    }
  }

  @media ${device.tablet_portrait} {
    display: none;
  }
`;

const Community = ({ joinButtonText, communityText }) => (
  <CommunityDiv>
    <div className="community__text">{communityText}</div>
    <Button
      as="a"
      className="community__button"
      text={joinButtonText}
      icon={<Telegram />}
      a target="_blank"
      rel="noreferrer"
      href="https://t.me/underdoc_community"
    />
  </CommunityDiv>
);

const CommunityDiv = styled.div`
  grid-area: community;
  display: flex;
  & > * + * {
    margin-left: 30px;
  }
  .community__text {
    font-size: 14px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    width: 326px;
  }

  .community__button {
    stroke-width: 1px;
  }

  @media ${device.tablet_portrait} {
    flex-direction: column;
    & > * + * {
      margin-left: 0px;
      margin-top: 30px;
    }
  }

  @media ${device.phone_landscape} {
    .community__button {
      align-self: center;
    }

    .community__text{
      align-self: center;
      text-align: center;
      width: 100%;
      max-width: 432px;
    }
  }

  @media ${device.phone_portrait} {
    .community__text{
      width: calc(100% - 16px);
      font-size: 12px;
    }
  }
`;

export default Footer;
