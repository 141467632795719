import React, { useContext } from 'react';
import styled from 'styled-components';
import axios from "axios";
import { navigate } from "hookrouter";
import { AppContext } from '../../AppContext';
import { getApiUrl, getCSRF, logging } from "../../../utils";
import { OnConfirmMessages } from "../../../constants";
import { localeButtonTexts } from '../../../Internationalization';

const DeleteAccountButton = ({ formik, action, ...props }) => {

  const { setOwnProfile, setConfirmProps, setUser, locale } = useContext(AppContext);

  const deleteAccount = async () => {
    const res = await axios.post(
      getApiUrl(`settings/${action}__delete`),
      { id: formik.values.workID },
      {
        headers: getCSRF(),
      }
    );
    if (res.status === 200) {
      setOwnProfile(null);
      setUser(null);
      navigate("/");
      window.location.reload();
    }
    logging(res);
  };

  const handleDelete = async () => {
    setConfirmProps({
      confirmText: OnConfirmMessages[locale].deleteAccount,
      confirmButtonText: "Удалить",
      confirmAction: () => deleteAccount(),
      visible: true,
    });
  };

  return <StyledButton {...props} onClick={handleDelete}>{localeButtonTexts[locale]["delete-account"]}</StyledButton>;
};

const StyledButton = styled.div`
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  color: ${(props) => props.theme.colorGrey2};
  position: relative;
  width: fit-content;
  &:after {
    position: absolute;
    display: block;
    width: 100%;
    content: "";
    height: 0.86px;
    background: ${(props) => props.theme.colorGrey2};
    bottom: 6.5px;
  }
`;

export default DeleteAccountButton;
