import React from 'react';
import image_335w from '../../../images/artifacts/cjm/flat-335w.png';
import image_670w from '../../../images/artifacts/cjm/flat-670w.png';
import image_1005w from '../../../images/artifacts/cjm/flat-1005w.png';
import image_1340w from '../../../images/artifacts/cjm/flat-1340w.png';
import image_pr from '../../../images/artifacts/pr/preview_pr_1340w.png';

const ArtifactImageCJMTest = () => (
  <img
    className="artifact__image"
    src={`${image_1340w}`}
    srcSet={
      `${image_335w} 200w,
      ${image_pr} 300w,
      ${image_pr} 400w,
      ${image_1340w} 500w
    `}
    alt="CJM"
  />
);

export const ArtifactImageCJM = () => (
  <img
    className="artifact__image"
    src={`${image_1340w}`}
    alt="CJM"
  />
);

export const ArtifactImagePR = () => (
  <img
    className="artifact__image"
    src={`${image_pr}`}
    alt="PR"
  />
);
