import React from "react";

const RoundedPlus = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 4.375V16.625" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.375 10.5H16.625" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

export default RoundedPlus;
