import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import image from "../../images/for-company/illustration-1440.png";
import { AddButton } from "../SmallComponents";
import { device } from "../../constants";
import { AppContext } from "../AppContext";

const ForCompanyHeader = ({ inputRef }) => {
  const { locale } = useContext(AppContext);
  const intl = useIntl();
  return (
    <HeaderDiv>
      <div className="for-company-header__title">
        <FormattedMessage
          id="header__title"
          defaultMessage="select candidates{br}by work samples"
        />
      </div>
      <div className="for-company-header__button">
        <AddButton text={intl.formatMessage({ id: "header__request-button" })} onClick={() => inputRef.current.focus()} />
      </div>
      <div className="for-company-header__image-wrapper">
        <img src={image} alt="simple" />
      </div>
    </HeaderDiv>
  );
};
const HeaderDiv = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "title image"
    "button image";
  margin-bottom: 150px;
  margin-top: 100px;

  .for-company-header__title {
    grid-area: title;
    position: absolute;
    font-weight: 900;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colorDark};
    margin-bottom: 50px;
    width: 747px;

    div {
      white-space: nowrap;
    }
  }

  .for-company-header__button {
    grid-area: button;
    width: 252px;
    height: 61px;
  }

  .for-company-header__image-wrapper {
    grid-area: image;
    width: 455px;
    justify-self: end;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media ${device.desktop} {
    .for-company-header__title {
      max-width: 640px;
    }
    .for-company-header__image-wrapper {
      width: 382px;
    }
  }

  @media ${device.tablet_landscape} {
    margin-top: 80px;
    .for-company-header__image-wrapper {
      width: 281px;
    }
  }

  @media ${device.tablet_portrait} {
    margin-top: 70px;
    margin-bottom: 130px;
    grid-template-columns: auto;
    grid-template-areas:
    "title"
    "button"
    "image";

    .for-company-header__title {
      position: static;
      width: 100%;
      max-width: 459px;
      line-height: 60px;

      div {
        white-space: normal;
      }
    }

    .for-company-header__image-wrapper {
      justify-self: center;
      width: 516px;
    }

    .for-company-header__button {
      margin-bottom: 70px;
    }
  }

  @media ${device.phone_landscape} {
    margin-top: 50px;
    grid-template-areas:
    "title"
    "image"
    "button";

    .for-company-header__image-wrapper {
      width: 385px;
    }

    .for-company-header__button {
      justify-self: center;
      margin-bottom: 0px;
      margin-top: 60px;
      width: 100%;
      max-width: 448px;
    }
    .for-company-header__title {
      margin-bottom: 50px;
      margin-right: 0;
      white-space: normal;
      font-size: 30px;
    }

  }

  @media ${device.phone_portrait} {

    .for-company-header__image-wrapper {
      width: 288px;
    }

    .for-company-header__title {
      font-size: max(18px, 6vw);
      line-height: 50px;
    }
    .for-company-header__button {
      height: 48px;
    }
  }
`;

export default ForCompanyHeader;
