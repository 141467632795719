import React, { useContext } from "react";
import styled from "styled-components";
import DeleteButton from "../../UI/buttons/rounded/DeleteButton";
import RoundedUploadButton from "../../UI/buttons/rounded/RoundedUploadButton";
import RoundedRefreshButton from "../../UI/buttons/rounded/RoundedRefreshButton";
import { device, OnConfirmMessages } from "../../../constants";
import { getMediaUrl, Show } from "../../../utils";
import { AppContext } from "../../AppContext";
import { localeButtonTexts, localeSettingsLabels } from "../../../Internationalization";

const PreviewImage = ({
  previewImage,
   handleRefresh,
   handleDelete,
   handleUpload,
   imageText,
  noneText = "Loading...",
   hideUpload = false,
  hideRefresh = false,
  deleteConfirmText,
}) => {
  const { locale } = useContext(AppContext);
  return (
    <StyledDiv>
      <Show when={previewImage}>
        <img className="preview-image__image" src={previewImage && getMediaUrl(`${previewImage}`)} alt={imageText} />
      </Show>
      <Show when={!previewImage}>
        <div className="preview-image__image">{noneText}</div>
      </Show>
      <div className="preview-image__btns">
        <Show when={!hideUpload}>
        <label htmlFor="load-other-upload">
        <input
          accept="image/*"
          id="load-other-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload}
        />
        <div className="btn-edit">
          <RoundedUploadButton title={localeButtonTexts[locale]["upload"]} />
        </div>
      </label>
        </Show>
        <Show when={!hideRefresh}>
          <RoundedRefreshButton onClick={handleRefresh} title={localeSettingsLabels[locale]["add-artifact__refresh-button-title"]} />
        </Show>
        <DeleteButton onClick={handleDelete}
          text={deleteConfirmText || OnConfirmMessages[locale].deleteLink}
          title={localeSettingsLabels[locale]["add-artifact__delete-button-title"]}
        />
      </div>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  margin-bottom: 30px;
  display: flex;

  .preview-image__image {
    width: 400px;
    height: 239px;
  }

  & > * + * {
    margin-left: 33px;
  }

  .preview-image__btns {
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 18px;
    }
  }

  @media ${device.phone_landscape} {
    .preview-image__image {
      width: 374px;
      height: 239px;
    }
  }

  @media ${device.phone_portrait} {
    .preview-image__image {
      width: 214px;
      height: 133px;
    }
  }
`;
export default PreviewImage;
