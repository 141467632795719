import React, { useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { A, navigate } from "hookrouter";
import { SettingsItems } from "../../Routes";
import backgroundImage from "../../images/placeholder_cjm.png";
import { getApiUrl, getCSRF, getMediaUrl, getRelativeDate, logging, Show } from "../../utils";
import { device, OnConfirmMessages, SalaryTypes } from "../../constants";
import EditButton from "../UI/buttons/rounded/RoundedEditButton";
import Likes from "../Likes";
import DeleteButton from "../UI/buttons/rounded/DeleteButton";
import RoundedAddButton from "../UI/buttons/rounded/RoundedAddButton";
import { AppContext } from "../AppContext";
import InterProvider from "../../Internationalization";
import AddButton from "../UI/buttons/AddButton";

const Cards = (props) => {
  const { cards, addCard, isOwner, noneText, className } = props;
  return (
    <StyledCards className={className}>
      <Show when={addCard && isOwner}>
        <AddCard />
      </Show>
      <InterProvider id="artifact">
        {cards && cards.map((card, index) => (
          <Card card={card} key={index} cardIndex={index} isOwner={isOwner} />
        ))}
      </InterProvider>
      <Show when={cards && cards.length === 0}>
        <div className="cards__none">{noneText}</div>
      </Show>
    </StyledCards>
  );
};

const AddCard = () => {
  const AddCJM = () => (navigate(`/settings/${SettingsItems.addArtifact}`));
  return (
    <>
      <AddButton className="cards__add-button" onClick={AddCJM} text="Добавить работу" />
      <EmptyCard className="cards__empty-card" AddArtifact={AddCJM} />
    </>
  );
};

const EmptyCard = ({ AddArtifact, ...props }) => (
  <StyledEmptyCard {...props}>
    <div className="empty-card__giglet" />
    <div className="empty-card__button">
      <RoundedAddButton onClick={AddArtifact} />
    </div>
  </StyledEmptyCard>
);

const Card = (props) => {
  const { card, cardIndex, isOwner } = props;
  const { locale } = useContext(AppContext);
  const deleteArtifact = async () => {
    const res = await axios.post(
      getApiUrl("settings/artifact__delete"),
      { id: card.id },
      {
        headers: getCSRF(),
      }
    );
    if (res.status === 200) {
      window.location.reload();
    }
    logging(res);
  };

  // const editArtifact = () => (navigate(`/settings/${SettingsItems[`change${card.type}`]}/${cardIndex}`));
  const editArtifact = () => (navigate(`/settings/${SettingsItems.changeArtifact}/${cardIndex}`));

  //  может, убрать счётчик кликов яндекс метрики, если владелец кликает.
  return (
    <StyledCard>
      <StyledCardMain rate={card.rate}>
        <A
          onClick={() => window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', 'main-to-cjm-page')}
          href={`/card/${card.id}`}
        >
          <div className="card-main__top">
            <div className="card-main__rate">{card.salary_type === SalaryTypes.Dollar ? `$${card.rate}/hour` : `${card.rate}k RUB`}</div>
            <div className="card-main__title">{card.title}</div>
            <StyledCardMiro imgpath={card.preview_image && getMediaUrl(`${card.preview_image}`)} />
          </div>
        </A>
        <div className="card-main__bottom">
          <div className="card-main__row">
            <div className="card-main__date">{getRelativeDate(card.pub_date, locale)}</div>
            <Likes card={card} />
          </div>
          <div className="card-main__industry">{card.industry}</div>
          <Show when={isOwner}>
            <div className="card-main__delete-button">
              <DeleteButton onClick={deleteArtifact} text={OnConfirmMessages[locale].deleteArtifact(card.title)} />
            </div>
            <div className="card-main__edit-button">
              <EditButton onClick={(e) => editArtifact(e)} />
            </div>
          </Show>
        </div>
      </StyledCardMain>
      <StyledCardLogos>
        <Show when={card.logos.length > 0}>
          <div className="card-bottom__logos">
            {card.logos.map((logo) => <img key={logo.name} src={getMediaUrl(`${logo.image}`)} alt={logo.name} />)}
          </div>
        </Show>
        <Show when={card.logos.length === 0}>
          <div className="card-bottom__no-logos"><FormattedMessage id="artifact__not-estimated" /></div>
        </Show>
      </StyledCardLogos>
    </StyledCard>
  );
};

const StyledCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
  justify-content: space-between;
  grid-gap: 80px;

  .cards__none {
    grid-column: 1 / -1;
    justify-self: center;
    font-size: 16px;
    line-height: 30px;
    color: ${(props) => props.theme.colorFont};
    text-align: center;
    width: calc(100% - 56px);
  }

  .cards__add-button {
    grid-column: 1 / -1;
    display: none;
  }

  @media ${device.desktop} {
    grid-gap: 40px;
  }

  @media ${device.tablet_landscape} {
    grid-gap: 27px;
  }
  @media ${device.tablet_portrait} {
    grid-gap: 20px;
    .cards__empty-card {
      display: none;
    }
    .cards__add-button {
      display: block;
    }
  }
  @media ${device.phone_landscape} {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    .cards__add-button {
      justify-self: center;
    }
  }
  @media ${device.phone_portrait} {
    .cards__none {
      width: 240px;
    }
  }
`;

const StyledEmptyCard = styled.div`
  border: 1px dashed #BFC0C1;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  padding: 88px 10px 84px;
  .empty-card__button {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .empty-card__giglet {
    height: 0;
    padding-bottom: 60%;
  }
`;

const StyledCard = styled.div`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
  & > * + * {
    margin-bottom: 1px;
  }
`;

const StyledCardMain = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.colorGrey};
  border-radius: 5px;
  background-color: ${props => props.theme.colorWhite};

  .card-main__top {
    padding: 38px 10px 0px;
    cursor: pointer;
  }

  .card-main__bottom {
    padding: 5px 10px 16px;
  }

  .card-main__rate {
    --height: 32px;
    display: ${props => props.rate > 0 ? "block" : "none"};
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: var(--height);
    border-radius: 0px 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.colorGreen};
    color: ${props => props.theme.colorWhite};
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    line-height: var(--height);
  }

  .card-main__title {
    --height: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: var(--height);
    height: calc(2*var(--height));
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .card-main__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .card-main__date {
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.theme.colorFont};
  }

  .card-main__industry {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${props => props.theme.colorFont};
  }

  .card-main__delete-button,
  .card-main__edit-button {
    position: absolute;
    bottom: 10px;
    right: 16px;
  }

  .card-main__edit-button {
    right: 69px;
  }
`;

const StyledCardMiro = styled.div`
  border-radius: 5px;
  height: 0;
  padding-bottom: 60%;
  background-size: cover;
  background-image: url(${(props) => props.imgpath}),  url(${backgroundImage});
`;

const StyledCardLogos = styled.div`
  /* display: flex; */
  display: none;
  box-sizing: border-box;
  height: 46px;
  border: 1px solid ${props => props.theme.colorGrey};
  background-color: ${props => props.theme.colorWhite};
  border-radius: 5px;
  padding: 0 10px;
  align-items: center;

  .card-bottom__logos {
    height: 16px;
    display: flex;
    overflow: hidden;

    img {
      height: 16px;
    }

    & > * + * {
      margin-left: 6px;
    }
  }

  .card-bottom__no-logos {
    font-size: 12px;
    color: ${props => props.theme.colorFont};
  }
`;

export default Cards;
