import { A } from "hookrouter";
import React, { useContext } from "react";
import { activeClass } from "../../../utils";
import { AppContext } from "../../AppContext";

const LinkButton = ({ url, simple, children, className, ...props }) => {
  const { urlState } = useContext(AppContext);
  if (simple) return <a className={className} href={url} target="_blank" rel="noreferrer" {...props}>{children}</a>;
  return <A className={activeClass(className, (urlState === url))} href={url} {...props}>{children}</A>;
};

export default LinkButton;
