import React, { useContext } from "react";
import styled from "styled-components";
import { device, localeDefaultTexts } from "../../constants";
import "../../fonts.css";
import { Show } from "../../utils";
import { AppContext } from "../AppContext";
import { ModalBackground, SmallCrossButton } from "../SmallComponents";

const ConfirmModal = () => {
  const { confirmProps, setConfirmProps, locale } = useContext(AppContext);

  const onConfirm = async () => {
    await confirmProps.confirmAction();
    onClose();
  };

  const onClose = () => {
    setConfirmProps({});
  };
  const modalText = confirmProps.confirmText || "";
  const buttonCancelText = confirmProps.cancelButtonText || localeDefaultTexts[locale]["confirm-modal__cancel-button-text"];
  const buttonConfirmText = confirmProps.cancelButtonText || localeDefaultTexts[locale]["confirm-modal__confirm-button-text"];
  return (
    <Show when={confirmProps.visible}>
      <ModalBackground onClick={onClose}>
        <ConfirmDiv onClick={(e) => e.stopPropagation()}>
          <div className="confirm-modal__text">{modalText}</div>
          <div className="confirm-modal__btns">
            <div className="confirm-modal__btn-cancel" onClick={onClose}>{buttonCancelText}</div>
            <div className="confirm-modal__btn-confirm" onClick={onConfirm}>{buttonConfirmText}</div>
          </div>
          <div className="confirm-modal__small-cross-button">
            <SmallCrossButton onClick={onClose} color="black" />
          </div>
        </ConfirmDiv>
      </ModalBackground>
    </Show>
  );
};

const ConfirmDiv = styled.div`
  padding: 66px 81px 48px 41px;
  box-shadow: 0 2px 4px rgba(117, 133, 171, 0.25);
  background: ${(props) => props.theme.colorWhite};
  color: ${(props) => props.theme.colorDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  border-radius: 10px;

  .confirm-modal__small-cross-button {
    display: none;
  }
  .confirm-modal__text {
    padding-left: 10px;
    min-height: 82px;
    width: 400px;
    font-size: 20px;
    line-height: 30px;
    align-items: center;
  }

  .confirm-modal__btn-confirm,
  .confirm-modal__btn-cancel {
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    width: 217px;
    height: 64px;
    font-size: 16px;
    line-height: 64px;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .confirm-modal__btns {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
  }

  .confirm-modal__btn-confirm {
    background: ${(props) => props.theme.colorMain};
    border: none;
    color: ${(props) => props.theme.colorWhite};
  }

  .confirm-modal__btn-cancel {
    border: 1px solid ${(props) => props.theme.colorFont};
    color: ${(props) => props.theme.colorDark};
  }

  @media ${device.phone_landscape} {
    width: auto;
    height: 100%;
    right: 0;
    border-radius: 0;
    padding: 90px 24px 24px 24px;

    .confirm-modal__small-cross-button {
      display: block;
      position: absolute;
      z-index: 15;
      cursor: pointer;
      top: 16px;
      right: 24px;
      width: 24px;
      height: 24px;
    }

    .confirm-modal__text {
      padding: 0;
      min-height: 70px;
      text-align: center;
      width: 100%;
    }

    .confirm-modal__btns {
      grid-auto-flow: row;
      justify-items: center;
    }

    .confirm-modal__btn-cancel,
    .confirm-modal__btn-confirm {
      width: 100%;
      max-width: 448px;
    }

    .confirm-modal__btn-confirm {
      order: -1;
    }
  }

  @media ${device.phone_portrait} {
    .confirm-modal__text {
      min-height: 164px;
    }
    .confirm-modal__small-cross-button {
      top: 15px;
      right: 14px;
    }
    .confirm-modal__btn-cancel,
    .confirm-modal__btn-confirm {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
    }
  }
`;

export default ConfirmModal;
