import { A } from "hookrouter";
import React, { useContext } from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import { localeProfileLabels } from "../../../Internationalization";
import { SettingsItems } from "../../../Routes";
import { getApiUrl, getMediaUrl, Show } from "../../../utils";
import { AppContext } from "../../AppContext";
import EditButton from "../../UI/buttons/rounded/RoundedEditButton";
import ProfileImage from "../../UI/other/ProfileImage";

const AccountData = ({ profileData, isOwner }) => {
  const {locale} = useContext(AppContext);
  return (
    <Show when={profileData}>
      <StyledDiv>
        <div className="account-data__image">
          <ProfileImage picture={profileData.picture} />
        </div>
        <div className="flex column center">
          <div className="account-data__fullname">
            {`${profileData.first_name} ${profileData.last_name}`}
          </div>
          {/* <div className="account-data__profile-link"
          onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/profile/${profileData.id}`)}
          title="Скопировать"
        >
          Ссылка на профиль
        </div> */}
          <Show when={profileData.job_looking}>
            <div className="account-data__job-looking">{localeProfileLabels[locale]["job-looking"]}</div>
          </Show>
        </div>
        <Show when={isOwner}>
          <A href={`/settings/${SettingsItems.profileSettings}`}>
            <EditButton className="profile__left-side__edit-button" />
          </A>
        </Show>
      </StyledDiv>
    </Show>
  );
};
const StyledDiv = styled.div`
  padding: 30px 10px 15px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colorWhite};
  border: 1px solid ${props => props.theme.colorGrey};
  border-radius: 5px;
  position: relative;
  align-items: center;

  .account-data__image {
    height: 120px;
    width: 120px;
    margin-bottom: 15px;
  }
  .account-data__fullname {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    word-break: break-word;
  }
  .account-data__profile-link {
    cursor: pointer;
    font-size: 12px;
    line-height: 10px;
    color: ${props => props.theme.colorMain};
    margin-bottom: 10px;
  }
  .account-data__job-looking {
    background: #DFE9FF;
    border-radius: 50px;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    padding: 2.5px 10px;
    color: ${props => props.theme.colorMain};
  }

  @media ${device.tablet_portrait} {
    padding: 18px 58px 18px 20px;
    flex-direction: row;
    .account-data__image {
      flex-shrink: 0;
      height: 68px;
      width: 68px;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .account-data__fullname
    {
      text-align: start;
      align-self: flex-start;
      margin-bottom: 9px;
    }

    .account-data__job-looking
    {
      align-self: flex-start;
    }

    .account-data__profile-link {
      display: none;
    }
  }
  @media ${device.phone_landscape} {
    padding: 10px 50px 10px 12px;
    .account-data__image {
      height: 46px;
      width: 46px;
    }
    .account-data__fullname
    {
      font-size: 14px;
      margin-bottom: 4px;
    }
    .account-data__job-looking
    {
      font-size: 10px;
      line-height: 22px;
      padding: 0px 10px;
    }
  }
`;

export default AccountData;
