import React, { useContext } from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import { localeButtonTexts } from "../../../Internationalization";
import { AppContext } from "../../AppContext";

const EnterButton = () => {
  const { toggleLoginModal, locale } = useContext(AppContext);

  const enter = () => {
    window.ym(parseInt(process.env.REACT_APP_YM_ID), "reachGoal", "sign-in-up");
    toggleLoginModal(true);
  };

  return <StyledButton className={locale} onClick={enter}>{localeButtonTexts[locale]["sign-in"]}</StyledButton>;
};

const StyledButton = styled.div`
  justify-self: end;
  width: 119px;
  height: 45px;
  background: ${(props) => props.theme.colorDark};
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 45px;
  color: ${(props) => props.theme.colorWhite};
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  &.en {
    width: 167px;
  }

  @media ${device.phone_portrait} {
    height: 40px;
    line-height: 40px;
    width: 91px;

    &.en {
      width: 119px;
    }
  }
`;

export default EnterButton;
