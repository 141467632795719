import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import image from "../../images/for-company/offer-image-1440.png";
import { device } from "../../constants";

const OurOffer = () => (
  <OurOfferDiv>
    <div className="for-company__block-title"><FormattedMessage id="our-offer__title" /></div>
    <div className="our-offer__row">
      <div className="our-offer__image-wrapper">
        <img src={image} alt="simple" />
      </div>
      <div className="our-offer__text-wrapper">
        <div className="our-offer__text">
          <FormattedMessage id="our-offer__text" />
        </div>
      </div>
    </div>
  </OurOfferDiv>
);


const OurOfferDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;

  .our-offer__row {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }

  .our-offer__text-wrapper {
    display: flex;
    justify-content: center;
    flex-grow: 2;
  }

  .our-offer__text {
    max-width: 625px;
    font-size: 16px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
  }

  .our-offer__image-wrapper {
    flex-shrink: 0;
    width: 413px;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media ${device.desktop} {
    .our-offer__image-wrapper {
      width: 389px;
    }
    .our-offer__text {
      max-width: 543px;
    }
  }

  @media ${device.tablet_landscape} {
    margin-bottom: 100px;

    .our-offer__row {
      align-items: flex-start;
    }

    .our-offer__image-wrapper {
      width: 413px;
    }
    .our-offer__text {
      margin-left: 55px;
      max-width: 444px;
    }
  }
  @media ${device.tablet_portrait} {
    margin-bottom: 80px;
    .our-offer__row {
      flex-direction: column;
      align-items: center;
      & > * + * {
        margin-top: 40px;
      }
    }
    .our-offer__text {
      margin-left: 0px;
      max-width: none;
    }
  }

  @media ${device.phone_portrait} {
    margin-bottom: 70px;
    .our-offer__image-wrapper {
      width: 288px;
    }
  }
`;

export default OurOffer;
