import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { AppContext } from "../AppContext";
import { CustomSelect, CustomTextarea } from "../settings/CustomFields";
import { device, mailRegExp } from "../../constants";
import { getApiUrl, getCSRF, logging } from "../../utils";
import Select from "../UI/inputs/Select";
import Input from "../UI/inputs/Input";
import SubmitButton from "../UI/buttons/SubmitButton";

const IfHavent = () => (
  <IfHaventDiv>
    <div className="for-product__block-title">А что если у меня нет CJM?</div>
    <div className="ifhavent__row">
      <div className="for-product__block-text ifhavent__text">
        Ничего страшного, ты можешь взять любое приложение или сервис и сделать CJM по нему.
        <br />
        <br />
        Но если ничего не приходит на ум, можешь здесь выбрать компанию и отправить нам запрос, а мы тебе напишем, по какому именно сервису эта компания нуждается сейчас в CJM.
      </div>
      <RequestFormComponent />
    </div>
  </IfHaventDiv>
);

const ValidationSchema = Yup.object().shape({
  company: Yup.string().required("Выберите компанию"),
  email: Yup.string().trim().required("Укажите email")
    .matches(
      mailRegExp,
      "Такой email не подходит :("
    ),
});

const RequestFormComponent = () => {
  const [companies, setCompanies] = useState([]);
  const { showSuccessModal } = useContext(AppContext);
  useEffect(() => {
    fetch(getApiUrl("artifacts/get_companies_to_cjm_request/"))
      .then((res) => res.json())
      .then((result) => {
        const companiesList = result.companies.map((value) => ({ value, key: value, label: value }));
        setCompanies(companiesList);
        logging(result);
      });
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: "",
      email: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      values = ValidationSchema.cast(values);
      const res = await axios.post(getApiUrl("add_cjm_request/"), values, {
        headers: getCSRF(),
      });
      if (res.status === 200) {
        window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', 'product-send-request');
        showSuccessModal(res.data.text, res.data.success);
      }
      logging(res);
    },
  });

  return (
    <FormikProvider value={formik}>
      <RequestForm onSubmit={formik.handleSubmit}>
        <div className="request-form__label">Выберите компанию</div>
        <Select className="request-form__input" valueName="company" formik={formik} options={companies} placeholder="Компания" disableSearch />
        <div className="request-form__label">Оставьте нам свой e-mail</div>
        <Input className="request-form__input" valueName="email" formik={formik} placeholder="E-mail" maxLength="50" />
        <SubmitButton className="request-form__btn" text="Отправить" />
      </RequestForm>
    </FormikProvider>
  );
};

const IfHaventDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;

  .ifhavent__row {
    display: flex;
    justify-content: space-between;
  }

  .ifhavent__text {
    margin-right: 20px;
  }

  @media ${device.tablet_landscape} {
    margin-bottom: 100px;
    .ifhavent__row {
      flex-direction: column;
    }
  }
  @media ${device.tablet_portrait} {
    margin-bottom: 80px;
    .ifhavent__text {
      margin-right: 0px;
    }
  }
  @media ${device.phone_portrait} {
    margin-bottom: 70px;
  }
`;

const RequestForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.theme.colorGrey};
  --indent-left: 46px;
  --indent-right: 44px;
  padding: 54px var(--indent-right) 58px var(--indent-left);
  width: calc(572px - var(--indent-right) - var(--indent-left));
  min-width: calc(572px - var(--indent-right) - var(--indent-left));
  border-radius: 5px;

  .request-form__label {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    margin-bottom: 12px;
  }

  .request-form__input {
    margin-bottom: 30px;
    .input__field-wrapper {
      background-color: ${props => props.theme.colorWhite};
    }
    input {
      box-shadow: 0 0 0 1px ${props => props.theme.colorWhite};
    }
  }

  @media ${device.tablet_portrait} {
    --indent: 20px;
    width: calc(100% - 2*var(--indent));
    max-width: calc(592px - 2*var(--indent));
    padding-left: var(--indent);
    padding-right: var(--indent);
    min-width: 0;
  }

  @media ${device.phone_landscape} {
    --indent: 24px;
    padding-bottom: 34px;
    margin-left: calc(-1 * var(--indent));
    margin-right: calc(-1 * var(--indent));
    width: 100%;
    align-self: center;
    border-radius: 0;

    .request-form__btn {
      align-self: center;
    }
    .request-form__label {
      font-size: 18px;
    }
  }

  @media ${device.phone_portrait} {
    --indent: 16px;
    padding-top: 20px;
    padding-bottom: 44px;
    .request-form__label {
      font-size: 16px;
    }
  }
`;

export default IfHavent;
