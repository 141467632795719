import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { FormattedMessage } from "react-intl";
import { A } from "hookrouter";
import { device, SalaryTypes } from "../../constants";
import { getMediaUrl, Show } from "../../utils";
import { AppContext } from "../AppContext";
import { localeProfileLabels } from "../../Internationalization";

const Sidebar = (props) => {
  const { artifact } = props;
  const { locale } = useContext(AppContext);
  const theme = useTheme();
  const style = {};
  let rate = artifact.salary_type === SalaryTypes.Dollar ? `$${artifact.rate}/hr` : `${artifact.rate}k`;
  let rateText = "";

  if (artifact.rate === 0) {
    style.backgroundColor = theme.colorGrey2;
    rateText = <FormattedMessage id="not-estimated" />;
    rate = "";
  } else {
    style.backgroundColor = theme.colorGreen;
    rateText = <FormattedMessage id="salary-estimation" />;
  }
  return (
    <SidebarDiv>
      <ProfileDiv
        onClick={() => window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', 'preview-cjm-to-profile-page')}
        href={`/${artifact.profile_username}`}
      >
        <img
          src={
            artifact.profile_picture.substring(0, 9) === "pictures/"
              ? getMediaUrl(`${artifact.profile_picture}`)
              : artifact.profile_picture
          }
          alt=""
        />
        <div className="sidebar__profile__info">
          <div className="sidebar__profile__name">{`${artifact.profile_first_name} ${artifact.profile_last_name}`}</div>
          <Show when={artifact.profile_joblooking}>
            <div className="sidebar__profile__job-looking">{localeProfileLabels[locale]["job-looking"]}</div>
          </Show>
        </div>
      </ProfileDiv>
      <Show when={artifact.rate !== 0}>
        <RateDiv>
          <div className="sidebar__rate__rate-number" style={style}>{rate}</div>
          <div className="sidebar__rate__label-text">{rateText}</div>
        </RateDiv>
      </Show>
      <div className="sidebar__tags">
        <Tags artifact={artifact} />
      </div>
    </SidebarDiv>
  );
};

export const Tags = ({ artifact, ...props }) => (
  <TagsDiv {...props}>
    {artifact.tags.map((tag, index) => (
      <div className="tag" key={index}>
        {tag}
      </div>
    ))}
  </TagsDiv>
);

const SidebarDiv = styled.div`
  width: 180px;
  display: grid;
  grid-auto-flow: row;
  padding-left: 57px;
  grid-gap: 80px;
  height: fit-content;
  .sidebar__tags {
    margin-top: 220px;
    display: flex;
    justify-content: center;
  }

  @media ${device.desktop} {
    padding-left: 25px;
  }

  @media ${device.tablet_landscape} {
    .sidebar__tags {
      margin-top: 120px;
    }
  }

  @media ${device.tablet_portrait} {
    order: -1;
    width: 100%;
    padding-left: 0;
    margin-bottom: 40px;
    .sidebar__tags {
      display: none;
    }
  }
  @media ${device.phone_landscape} {
    margin-bottom: 30px;
  }
`;

const ProfileDiv = styled(A)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: fit-content;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .sidebar__profile__name {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    margin-top: 10px;
    margin-bottom: 5px;
    word-break: break-word;
  }

  .sidebar__profile__job-looking {
    background: #DFE9FF;
    align-self: center;
    border-radius: 50px;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    padding: 2.5px 10px;
    color: ${props => props.theme.colorMain};
  }

  .sidebar__profile__info {
    display: flex;
    flex-direction: column;
  }

  @media ${device.tablet_portrait} {
    align-items: start;
    flex-direction: row;
    text-align: start;
    .sidebar__profile__name {
      margin-top: 0;
      margin-bottom: 9px;
    }
    .sidebar__profile__job-looking {
      align-self: flex-start;
    }
    img {
      width: 68px;
      height: 68px;
      margin-right: 10px;
    }
  }
  @media ${device.phone_landscape} {
    .sidebar__profile__name {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .sidebar__profile__job-looking
    {
      font-size: 10px;
      line-height: 22px;
      padding: 0px 10px;
      margin-bottom: 4px;
    }
    img {
      width: 46px;
      height: 46px;
    }
  }
  @media ${device.phone_portrait} {
    img {
      margin-right: 8px;
    }
  }
`;

const RateDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .sidebar__rate__rate-number {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: ${(props) => props.theme.colorWhite};
    margin-bottom: 10px;
  }

  .sidebar__rate__label-text {
    font-size: 18px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    margin-bottom: 10px;
    :first-letter {
      text-transform: uppercase;
    }
  }

  @media ${device.tablet_portrait} {
    display: none;
  }
`;

const TagsDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  --gap: 18px;
  & > * + * {
    margin: var(--gap) 0 0 0;
  }
  .tag {
    color: ${(props) => props.theme.colorDark};
  }
  @media ${device.tablet_portrait} {
    flex-direction: row;
    flex-wrap: wrap;
    --gap: 8px;
    width: calc(100% + var(--gap));
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    & > * {
      margin: var(--gap) 0 0 var(--gap);
    }
  }
`;

export default Sidebar;
