import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { navigate } from "hookrouter";
import { getApiUrl, getCSRF, logging, Timeout } from "../../../../utils";
import useLocalStorageState from "../../../hooks/useLocalStorageState";
import { AppContext } from "../../../AppContext";
import { SettingsItems } from "../../../../Routes";
import { PlatformTypes } from "../../../../constants";

const INITIAL_VALUES = {
  type: "",
  platform: "",
  id: null,
  miro_id: "",
  notion_link: "",
  embednotion_link: "",
  source_link: "",
  preview_image: null,
  title: "",
  company: "",
  industry: "",
  tags: [],
  description: "",
};

const validationSchema = Yup.object().shape({
  type: Yup.string().required("required-field"),
  platform: Yup.string().required("required-field"),
  company: Yup.string().trim(),
  title: Yup.string().trim().required("required-field"),
  industry: Yup.string().required("required-field"),
  description: Yup.string().trim().required("required-field"),
  tags: Yup.mixed().test("maxCount", "too-many-tags", (tags) => tags.length <= 5),
  miro_id: Yup.string().trim().when('platform', (platform, schema) =>
    platform === PlatformTypes.Miro ? schema.required("required-field") : schema),
  notion_link: Yup.string().trim().when('platform', (platform, schema) =>
    platform === PlatformTypes.Notion ? schema.required("required-field") : schema),
  embednotion_link: Yup.string().trim().when('platform', (platform, schema) =>
    platform === PlatformTypes.Notion ? schema.required("required-field") : schema),
  source_link: Yup.string().trim().when('platform', (platform, schema) =>
    platform === PlatformTypes.Other ? schema.required("required-field") : schema),
  preview_image: Yup.mixed().required("required-field"),
});

const localStorageKey = "addArtifact";

const useArtifactFormik = (artifactIndex, isChanging, setSubmitActive, showSuccessModal, ownArtifacts, toggleLoadingModal ) => {
  const [localArtifact, handleUpdateForm] = useLocalStorageState({ key: localStorageKey, value: INITIAL_VALUES });
  const artifact = useMemo(() => {
    let artifact = {};
    if (isChanging) {
      if (artifactIndex >= 0 && ownArtifacts.length > artifactIndex) {
        artifact = ownArtifacts[artifactIndex];
      } else {
        Object.assign(artifact, INITIAL_VALUES);
      }
    } else {
      artifact = localArtifact;
    }
    return artifact;
  }, [artifactIndex, isChanging, ownArtifacts]);

  const setFormikInitialValues = useCallback(() => {
    const initialValues = {};
    Object.keys(INITIAL_VALUES).forEach((key) => initialValues[key] = artifact[key]);
    return initialValues;
  }, [artifact]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: setFormikInitialValues(),
    validationSchema,
    onSubmit: async (values) => {
      setSubmitActive(false);
      toggleLoadingModal(true);
      values = validationSchema.cast(values);
      const data = { ...values };
      try {
        const res = await axios.post(getApiUrl(`artifacts/add-artifact/${values.platform}`), data, {
          headers: getCSRF(),
        });

        if (res.status === 200) {
          toggleLoadingModal(false);
          showSuccessModal(res.data.result_text, res.data.success);
          if (res.data.success) {
            !isChanging && window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', `${values.type.toLowerCase()}-added-successfully`);
            await Timeout(2000);
            navigate(`/settings/${SettingsItems.addedArtifacts}`);
            window.location.reload();
            !isChanging && handleUpdateForm(INITIAL_VALUES);
          }
        }
        logging(res);
      } catch (e) {
        logging(e);
      } finally {
        setSubmitActive(true);
        toggleLoadingModal(false);
      }
    },
  });

  useEffect(() => {
    if (!isChanging)
    {
      handleUpdateForm(formik.values);
    }
  }, [formik.values, handleUpdateForm, isChanging]);

  return formik;
};

export default useArtifactFormik;
