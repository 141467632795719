import React, { useContext } from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import { localeButtonTexts } from "../../../Internationalization";
import { AppContext } from "../../AppContext";

const Button = ({ text, icon, ...props }) => {
  const { locale } = useContext(AppContext);
  if (!text) {
    // text = localeButtonTexts[locale].save;
  }
  text = text || localeButtonTexts[locale].save;
  if (icon) {
    return (
      <StyledIconButton {...props}>
        <div className="button__label">{text || localeButtonTexts[locale].save}</div>
        <div className="button__icon">{icon}</div>
      </StyledIconButton>
    );
  }
  return (
    <StyledButton {...props}>
      <div className="button__label">{text}</div>
    </StyledButton>
  );
};

const StyledButton = styled.div`
  justify-content: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  background: ${props => props.theme.colorMain};
  border: none;
  border-radius: 5px;
  width: 217px;
  height: 61px;

  .button__label {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colorWhite};
    line-height: 61px;
  }

  :disabled {
    background-color: ${props => props.theme.colorGrey2};
  }

  @media ${device.phone_landscape} {
    width: 100%;
    max-width: 448px;
  }

  @media ${device.phone_portrait} {
    height: 48px;

    .button__label {
      font-size: 14px;
      line-height: 48px;
    }
  }
`;


const StyledIconButton = styled(StyledButton)`
  stroke: ${props => props.theme.colorWhite};
  stroke-width: 2px;

  .button__icon {
    height: 24px;
    width: 24px;
    margin-top: 20px;
    margin-bottom: 17px;
  }

  @media ${device.upper_phone_landscape} {
    padding: 0px 20px;
    width: 252px;
    box-sizing: border-box;
    justify-content: space-between;
  }

  @media ${device.phone_landscape} {
    position: relative;
    .button__icon {
      position: absolute;
      margin: 0px;
      top: 20px;
      right: 30px;
    }
  }

  @media ${device.phone_portrait} {
    padding: 0px 20px;
    box-sizing: border-box;
    justify-content: space-between;
    .button__icon {
      position: static;
      margin: 12px 0px;
    }
  }
`;

export default Button;
