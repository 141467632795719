import React, { useContext } from "react";
import styled from "styled-components";
import { A } from "hookrouter";
import icon_miro from "../../../images/artifacts/icon_miro.png";
import icon_notion from "../../../images/artifacts/icon_notion.png";
import { SettingsItems, SettingsTitles } from "../../../Routes";
import {ArtifactImageCJM, ArtifactImagePR} from "./ArtifactImage";
import { AppContext } from "../../AppContext";

const ChooseArtifactTypeToAdd = ({ action }) => {
  const {locale} = useContext(AppContext);
  return (
    <StyledDiv>
      <div className="settings__header">{SettingsTitles[locale][action]}</div>
      <div className="add-artifact__artifacts">
        <CJMArtifact />
        <PRArtifact />
      </div>
    </StyledDiv>
  );
};
const CJMArtifact = () => <Artifact image={<ArtifactImageCJM/>} icon={icon_miro} service="Miro" title="CJM" href={SettingsItems.addCJM} />;
const PRArtifact = () => <Artifact image={<ArtifactImagePR/>} icon={icon_notion} service="Notion" title="Product Research" href={SettingsItems.addPR} />;

const Artifact = ({ image, icon, service, title, href }) => {
  const val = null;
  return (
    <StyledArtifact>
      <A href={`/settings/${href}`}>
        {image}
      </A>
      <div className="artifact__row">
        <img className="artifact__icon" src={icon} alt={service} />
        <div className="artifact__service">{service}</div>
      </div>
      <div className="artifact__title">{title}</div>
    </StyledArtifact>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .settings__header {
    margin-bottom: 30px;
  }

  .add-artifact__artifacts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(286px,1fr));
    justify-content: stretch;
    grid-gap: 20px;
  }
`;

const StyledArtifact = styled.div`
  user-select: none;
  .artifact__image {
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
  }

  .artifact__row {
    display: flex;
    margin-left: 6px;
  }

  .artifact__icon {
    width: 23px;
    height: 23px;
    margin-right: 17px;
  }

  .artifact__service {
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    color: ${props => props.theme.colorFont};
  }

  .artifact__title {
    margin-left: 6px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.theme.colorDark};
  }
`;

export default ChooseArtifactTypeToAdd;
