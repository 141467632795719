import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Show } from "../../../../utils";
import { ArtifactTypes} from "../../../../constants";
import { SettingsItems, SettingsTitles  } from "../../../../Routes";
import BackToArtifactSelect from "../BackToArtifactSelect";
import AddCJMForm from "./AddCJMForm";
import LoadMiro from "./LoadMiro";
import AddArtifactFormik from "../AddArtifactFormik";
import { ValidationSchema } from "./AddCJMValidationSchema";
import { AppContext } from "../../../AppContext";

const CJM_LOCAL_STORAGE_KEY = "addCjm";
const CJM_INITIAL_VALUES = {
  id: null,
  miro_id: null,
  preview_image: null,
  title: "",
  company: "",
  industry: "",
  tags: [],
  description: "",
};

const AddCJM = ({ action, artifactIndex }) => {
  const isChanging = action === SettingsItems.changeCJM;
  const [submitActive, setSubmitActive] = useState(true);
  const formik = AddArtifactFormik(artifactIndex, isChanging, setSubmitActive, CJM_LOCAL_STORAGE_KEY, CJM_INITIAL_VALUES, ArtifactTypes.CJM, ValidationSchema);
  const enableSubmit = submitActive && formik.values.miro_id && formik.values.preview_image;
  const { locale } = useContext(AppContext);
  return (
    <StyledDiv>
      <Show when={!isChanging}>
        <BackToArtifactSelect className="add-cjm__back-to-select" />
      </Show>
      <div className="settings__header add-cjm__header">{SettingsTitles[locale][action]}</div>
      <LoadMiro formik={formik} />
      <AddCJMForm formik={formik} disableSubmit={!enableSubmit} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .add-cjm__upload-button {
    margin-bottom: 30px;
  }

  .add-cjm__back-to-select {
    margin-bottom: 40px;
  }

  .add-cjm__header {
    margin-bottom: 30px;
  }
`;

export default AddCJM;
