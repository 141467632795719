import React, { useContext, useState } from "react";
import ReactSelect from "react-select";
import styled from "styled-components";
import { AppContext } from "../../AppContext";

const LanguageSelect = ({className, ...props}) => {
  const { locale, handleChangeLocale } = useContext(AppContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const options = [
    { value: "en", label: "English", key: "en" },
    { value: "ru", label: "Русский", key: "ru" },
  ];
  const getValue = () => (options.find((option) => option.value === locale));
  return (
    <StyledDiv
    className={className}
    isMenuOpen={isMenuOpen}
    >
      <ReactSelect
        classNamePrefix="select"
        className="lang-select"
        isSearchable={false}
        options={options}
        value={getValue()}
        onChange={handleChangeLocale}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 102px;
  font-size: 12px;
  .lang-select {



    .select__control {
      background-color: inherit;
      height: 30px;
      cursor: pointer;
      border: 0 transparent;
      border-radius: 5px;
      box-shadow: none;
      margin-right: 0;

      .select__value-container {
        padding: 0;
      }
      .select__single-value {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        color: ${props => props.theme.colorDark};
        margin-left: 0px;
      }
      .select__indicators {
        .select__indicator-separator {
          display: none;
        }
        .select__dropdown-indicator {
          color: ${(props) => props.theme.colorDark};
          transform: scale(1, ${(props) => (props.isMenuOpen ? -1 : 1)});
        }
      }
    }

    .select__control--is-focused {
      outline: none;
    }
  }
`;

export default LanguageSelect;
