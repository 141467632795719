const theme = {
  fontFamily: "Lato",
  fontStyle: "normal",
  colorDark: "#121C43",
  colorYellow: "#FFBE00",
  colorGrey: "#F0F1F3",
  colorRed: "#FF6969",
  colorMain: "#5773FF",
  colorFont: "#73737E",
  colorGreyLight: "#F9F9F9",
  colorGrey2: "#BFC0C1",
  colorWhite: "#FFFFFF",
  colorGreen: "#32CF97",
  colorLinearBG: "linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 12.5%, #F9F9F9 100%);",
};

export default theme;
