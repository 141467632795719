import React from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import InformationPoint from "../SVGs/InformationPoint";

const Hint = ({children, ...props}) => (
  <StyledHint {...props}>
    <div className="hint__icon"><InformationPoint /></div>
    <div className="hint__label">{children}</div>
  </StyledHint>
);

const StyledHint = styled.div`
    display: flex;
    & > * + * {
      margin-left: 6px;
    }
  .hint__icon {
    position: relative;
    height: 30px;
    width: 22px;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .hint__label {
    font-size: 14px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
  }

  @media ${device.phone_landscape} {
    .hint__label {
      font-size: 12px;
    }
  }
`;

export default Hint;
