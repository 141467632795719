import React from "react";
import { A } from "hookrouter";
import styled from "styled-components";
import { getMediaUrl } from "../../../utils";

const ProfileImage = ({ picture, href, ...props }) => (
  <ProfileImageWrapper $disableLink={!href} className="profile-image-wrapper" href={href || ""} {...props}>
    <img
      src={picture.substring(0, 9) === "pictures/" ? getMediaUrl(`${picture}`) : picture}
      alt=""
    />
  </ProfileImageWrapper>
);

const ProfileImageWrapper = styled(A)`
  cursor: ${(props) => (props.$disableLink ? "auto" : "pointer")};
  pointer-events: ${props => props.$disableLink ? "none" : "auto"};
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colorGrey};
    box-sizing: border-box;
  }
`;

export default ProfileImage;
