import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Show} from "../../../../utils";
import { AppContext } from "../../../AppContext";
import UploadButton from "../../../UI/buttons/UploadButton";
import ArrowHint from "../../../UI/other/ArrowHint";
import Hint from "../../../UI/other/Hint";
import PreviewImage from "../PreviewImage";
import { localeButtonTexts } from "../../../../Internationalization";

const LoadNotion = ({ formik, toggleModal, getPreviewImage, noneText, ...props }) => {
  const arrowHintElements = [
    <FormattedMessage id="load-notion__arrow-hint.1" />,
    <FormattedMessage id="load-notion__arrow-hint.2" />,
    <FormattedMessage id="load-notion__arrow-hint.3" />,
  ];
  const { locale } = useContext(AppContext);


  const deleteNotionInfo = () => {
    formik.setFieldValue("notion_link", "");
    formik.setFieldValue("preview_image", null);
    formik.setFieldValue("embednotion_link", "");
  };
  return (
    <StyledDiv {...props}>
      <Show when={!formik.values.notion_link}>
        <Hint className="load__hint"><FormattedMessage id="load-notion__hint" /></Hint>
        <ArrowHint className="load__arrow-hint" elements={arrowHintElements} />
        <UploadButton className="load__upload" onClick={() => toggleModal(true)} text={localeButtonTexts[locale]["upload"]} />
      </Show>
      <Show when={formik.values.notion_link}>
        <PreviewImage
          previewImage={formik.values.preview_image}
          handleRefresh={() => getPreviewImage()}
          handleDelete={deleteNotionInfo}
          imageText="notion screenshot"
          noneText={noneText}
          hideUpload
        />
      </Show>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .load__hint,
  .load__upload {
    margin-bottom: 30px;
  }

  .load__arrow-hint {
    margin-bottom: 40px;
  }
`;

export default LoadNotion;
