import React from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import AccountData from "./AccountData";
import DescriptionData from "./DescriptionData";

const LeftSide = ({className, profileData, isOwner, ...props}) => (
  <StyledDiv className={className}>
    <AccountData profileData={profileData} isOwner={isOwner}/>
    <DescriptionData profileData={profileData} className="profile__left-side__description-data" isOwner={isOwner}/>
  </StyledDiv>
  );

const StyledDiv = styled.div`
  flex-shrink: 0;
  .profile__left-side__description-data {
    margin-top: 1px;
  }
  .profile__left-side__edit-button {
    position: absolute;
    top: 14px;
    right: 7px;
  }

  @media ${device.tablet_portrait} {
    .profile__left-side__edit-button {
      top: 18px;
      right: 20px;
    }
  }
  @media ${device.phone_landscape} {
    .profile__left-side__edit-button {
      top: 10px;
      right: 12px;
    }
  }
  @media ${device.phone_portrait} {
    .profile__left-side__edit-button {
      right: 6px;
    }
  }
`;

export default LeftSide;
