import axios from "axios";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FormikProvider } from "formik";
import { SettingsItems, SettingsTitles } from "../../../../Routes";
import { getApiUrl, getCSRF, logging, Show } from "../../../../utils";
import { AppContext } from "../../../AppContext";
import useArtifactFormik from "./AddArtifactFormik";
import { ArtifactTypes, PlatformTypes, successfulMessages } from "../../../../constants";
import InterProvider, { localeSettingsLabels } from "../../../../Internationalization";
import Select from "../../../UI/inputs/Select";
import SubmitButton from "../../../UI/buttons/SubmitButton";
import Input from "../../../UI/inputs/Input";
import TagSelect from "../../../UI/inputs/TagSelect";
import LoadMiro from "../add-cjm/LoadMiro";
import LoadNotion from "../add-pr/LoadNotion";
import ModalPR from "../add-pr/ModalPR";
import LoadOther from "./LoadOther";


const AddArtifact = ({ action, artifactIndex }) => {
  const [isChanging, setChanging] = useState(false);
  useEffect(() => {
    setChanging(action === SettingsItems.changeArtifact);
  }, [action]);

  const { locale, toggleLoadingModal, setOwnArtifacts, ownArtifacts, showSuccessModal } = useContext(AppContext);

  const [tags, setTags] = useState([]);
  const [commonTags, setCommonTags] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [submitActive, setSubmitActive] = useState(true);
  const [notionModalVisible, toggleNotionModal] = useState(false);
  const industryOptions = industries.map((value) => ({ value, key: value, label: value }));
  const formik = useArtifactFormik(artifactIndex, isChanging, setSubmitActive, showSuccessModal, ownArtifacts, toggleLoadingModal );
  const [noneText, setNoneText] = useState();

  const onError = (showingModal = true) => {
    showingModal && showSuccessModal(successfulMessages[locale].unknownError, false);
    formik.setFieldValue("preview_image", "");
    formik.setFieldValue("embednotion_link", "");
    setNoneText("Error. Please try again");
  };

  const getPreviewImage = async (notion_link) => {
    setNoneText(undefined);
    toggleLoadingModal(true);
    try {
      formik.setFieldValue("preview_image", null);
      const result = await axios.post(
        getApiUrl(`artifacts/create_screenshot/${PlatformTypes.Notion}`),
        {
          notion_link: notion_link || formik.values.notion_link,
          embednotion_link: formik.values.embednotion_link,
        },
        { headers: getCSRF() },
      );
      if (result.status === 200) {
        logging(result);
        showSuccessModal(result.data.result_text, result.data.success);
        if (result.data.success) {
          formik.setFieldValue("preview_image", result.data.img_path);
          formik.setFieldValue("embednotion_link", result.data.embednotion_link);
        } else {
          onError(false);
        }
      } else {
        onError();
      }
    } catch (e) {
      logging(e);
      onError();
    }
    toggleLoadingModal(false);
  };

  useEffect(() => {
    fetch(getApiUrl("artifacts/tags/"))
      .then((res) => res.json())
      .then((result) => {
        setTags(result.tags);
        setCommonTags(result.common_tags);
      });
    fetch(getApiUrl("artifacts/industries/"))
      .then((res) => res.json())
      .then((result) => {
        setIndustries(result.industries);
      });
  }, []);

  useEffect(() => {
    const fetchArtifacts = async () => {
      toggleLoadingModal(true);
      try {
        const res = await axios.get(getApiUrl("artifacts/get-own-artifacts/"), {
          headers: getCSRF(),
        });

        if (res.status === 200) {
          toggleLoadingModal(false);
          setOwnArtifacts(res.data.artifacts);
        }
      } catch (e) {
        logging(e);
      }
      toggleLoadingModal(false);
    };
    if (isChanging) {
      fetchArtifacts();
    }
  }, [setOwnArtifacts, toggleLoadingModal, isChanging]);

  const typeOptions = useMemo(() => Object.values(ArtifactTypes).map((value) => ({ value, key: value, label: value })), []);
  const platformOptions = useMemo(() => Object.values(PlatformTypes).map((value) => ({ value, key: value, label: value })), []);

  const onTypeChange = useCallback((e) => {
    formik.setFieldValue("preview_image", null);
    formik.setFieldValue("miro_id", "");
    formik.setFieldValue("notion_link", "");
    formik.setFieldValue("embednotion_link", "");
    formik.setFieldValue("source_link", "");
    formik.setFieldValue("platform", e.value);
  }, [formik]);

  return (
    <StyledDiv>
      <div className="settings__header">{SettingsTitles[locale][action]}</div>
      <FormikProvider value={formik}>
        <StyledForm className="settings__form" onSubmit={formik.handleSubmit}>
          <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__artifact-type"]}</div>
          <Select className="settings__select add-artifact__short-select" valueName="type" formik={formik} options={typeOptions} placeholder="add-artifact__choose-artifact-type" />
          <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__platform"]}</div>
          <Select className="settings__select add-artifact__short-select" onChange={onTypeChange} valueName="platform" formik={formik} options={platformOptions} placeholder="add-artifact__choose-platform" />
          <Show when={formik && formik.values["platform"] === PlatformTypes.Miro}>
            <InterProvider id="settings__add-miro">
              <LoadMiro formik={formik} />
            </InterProvider>
          </Show>
          <Show when={formik && formik.values["platform"] === PlatformTypes.Notion}>
            <InterProvider id="settings__add-notion">
              <LoadNotion formik={formik} toggleModal={toggleNotionModal} getPreviewImage={getPreviewImage} noneText={noneText} />
            </InterProvider>
          </Show>
          <Show when={formik && formik.values["platform"] === PlatformTypes.Other}>
            <LoadOther formik={formik} />
          </Show>
          <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__title"]}</div>
          <Input className="settings__input" valueName="title" formik={formik} placeholder="add-artifact__title" maxLength="40" />
          <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__company"]}</div>
          <Input className="settings__input" valueName="company" formik={formik} placeholder="add-artifact__company" maxLength="30" />
          <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__industry"]}</div>
          <Select className="settings__select" valueName="industry" formik={formik} options={industryOptions} placeholder="add-artifact__industry" />
          <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__tags"]}</div>
          <TagSelect
            className="settings__select"
            valueName="tags"
            formik={formik}
            options={tags}
            commonTags={commonTags}
            placeholder="add-artifact__tags"
          />
          <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__description"]}</div>
          <Input
            className="settings__input"
            textarea
            valueName="description"
            formik={formik}
            placeholder="add-artifact__description"
            maxLength="1000"
            counter
          />
          <SubmitButton className="settings__submit" disabled={!submitActive} />
        </StyledForm>
      </FormikProvider>
      <ModalPR formik={formik} modalVisible={notionModalVisible} toggleModal={toggleNotionModal} getPreviewImage={getPreviewImage} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  .add-artifact__short-select {
    width: 288px;
  }
`;

const StyledForm = styled.form``;

export default AddArtifact;
