/* eslint prefer-arrow-callback: 0 */

import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useFormik, FormikProvider } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { AppContext } from "../../AppContext";
import { device, lastNameRegExp, nameRegExp, regulars } from "../../../constants";
import { apiRequest, getApiUrl, getCSRF, logging } from "../../../utils";
import Avatar from "./Avatar";
import SubmitButton from "../../UI/buttons/SubmitButton";
import DeleteAccountButton from "./DeleteAccountButton";
import Input from "../../UI/inputs/Input";
import Checkbox from "../../UI/inputs/Checkbox";
import { localePlaceholderTexts, localeSettingsLabels } from "../../../Internationalization";
import { SettingsTitles } from "../../../Routes";

const ProfileSettings = ({ action }) => {
  const {
    ownProfile,
    setOwnProfile,
    showSuccessModal,
    locale,
  } = useContext(AppContext);


  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("required-field").matches(nameRegExp, "check-first-name"),
    lastName: Yup.string().trim().required("required-field").matches(lastNameRegExp, "check-last-name"),
    username: Yup.string().trim().lowercase().required("required-field").min(2, "username-min-letters")
      .matches(regulars.startWithLetter, "start-with-letter").matches(regulars.endWithoutSymbol, "end-without-symbols")
      .matches(regulars.usernameRegExp, "check-username").test(
        "if-exist", "username-exists", function (username) {
          return new Promise((resolve, reject) => {
            fetch(getApiUrl(`check-username/${username}`))
              .then((res) => res.json())
              .then((result) => {
                if (result.vacant || username === ownProfile.username) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              }).catch(() => {
                // note exists
                resolve(true);
              });
          });
        })
  });

  const onSuccess = (res) => {
    setOwnProfile(res.data);
    showSuccessModal();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: ownProfile ? ownProfile.first_name : "",
      lastName: ownProfile ? ownProfile.last_name : "",
      jobLooking: ownProfile ? ownProfile.job_looking : false,
      username: ownProfile ? ownProfile.username : "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      values = ValidationSchema.cast(values);
      apiRequest(`settings/${action}`, values, {}, onSuccess);
    },
  });

  return (
    <StyledSettings>
      <div className="settings__header">{SettingsTitles[locale][action]}</div>
      <Avatar className="settings__avatar" />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Input
            className="settings__input"
            valueName="firstName"
            formik={formik}
            placeholder={localePlaceholderTexts[locale]["profile-settings__input__first-name"]}
            maxLength="35"
          />
          <Input
            className="settings__input"
            valueName="lastName"
            formik={formik}
            placeholder={localePlaceholderTexts[locale]["profile-settings__input__last-name"]}
            maxLength="35"
          />
          <div className="settings__label">{localeSettingsLabels[locale]["profile-settings__link"]}</div>
          <Input
            className="settings__input profile-settings__username-input"
            valueName="username"
            formik={formik}
            maxLength="25"
            prefix={`${window.location.protocol}//underdoc.${window.location.hostname.split(".").last()}/`}
          />
          <Checkbox className="settings__checkbox" valueName="jobLooking" label={<FormattedMessage id="job-looking" />} />
          <SubmitButton className="settings__submit" />
        </form>
      </FormikProvider>
      <DeleteAccountButton className="settings__delete-button" action={action} formik={formik} />
    </StyledSettings>
  );
};

const StyledSettings = styled.div`
  display: flex;
  flex-direction: column;

  .settings__avatar {
    margin-bottom: 30px;
  }

  .settings__submit {
    margin-top: 30px;
    margin-bottom: 95px;
  }

  .settings__input {
    margin-bottom: 10px;
  }

  .settings__checkbox {
    margin-top: 10px;
  }

  .profile-settings__username-input > .input__field-wrapper > input {
    padding-left: 186px;
  }

  @media ${device.phone_landscape} {
    .settings__delete-button {
      align-self: center;
    }
  }
`;
export default ProfileSettings;
