import React, { useContext } from "react";
import styled from "styled-components";
import { Field } from "formik";
import { Show } from "../../../utils";
import { AppContext } from "../../AppContext";
import { localeErrorTexts, localePlaceholderTexts } from "../../../Internationalization";

const Input = ({ maxLength, formik, valueName, className, placeholder = "", hideAfterRow, innerRef, textarea, counter, noneBorder, prefix }) => {
  const isError = formik.touched[valueName] && formik.errors[valueName];
  const {locale} = useContext(AppContext);
  return (
    <StyledInput className={className} noneBorder={noneBorder}>
      <div className="input__field-wrapper">
        <Show when={prefix}>
          <div className="input__prefix">{prefix}</div>
        </Show>
        <Field
          innerRef={innerRef}
          name={valueName}
          as={textarea ? "textarea" : "input"}
          className={isError ? "error" : ""}
          placeholder={localePlaceholderTexts[locale][placeholder] || placeholder}
          maxLength={maxLength}
        />
        <Show when={counter}>
          <div className="input__counter">
            {formik.values[valueName].length}/{maxLength}
          </div>
        </Show>
      </div>
      <Show when={!hideAfterRow}>
        <div className="input__after-row">
          <Show when={isError} otherwise={<div />}>
            <div className="input__error-label">{localeErrorTexts[locale][formik.errors[valueName]] || formik.errors[valueName]}</div>
          </Show>
        </div>
      </Show>
    </StyledInput>
  );
};

const StyledInput = styled.div`

  .input__field-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .input__prefix {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 12px;
      font-size: 16px;
      line-height: 48px;
      height: 48px;
      pointer-events: none;
      color: ${(props) => props.theme.colorFont};
    }

    textarea,
    input {
      -webkit-appearance: none;
      resize: none;
      overflow: auto;
      height: 48px;
      width: 100%;
      font-size: 16px;
      line-height: 48px;
      color: ${(props) => props.theme.colorDark};
      padding: 0px 12px 0px 12px;
      border: 2px transparent;
      box-shadow: 0 0 0 1px ${(props) => props.theme.colorGrey2};
      border-radius: 5px;
      outline: none;
      background: inherit;

      ::placeholder {
        color: ${(props) => props.theme.colorFont};
      }

      :focus-visible {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorMain};
      }
    }

    textarea {
      height: 172px;
      line-height: 30px;
      padding-top: 3px;
      border-bottom: 21px solid transparent;
    }

    .error {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};

      :focus-visible {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};
      }
    }

    .input__counter {
      position: absolute;
      bottom: 0px;
      right: 5px;
      font-size: 12px;
      line-height: 24px;
      color: ${(props) => props.theme.colorGrey2};
    }
  }

  .input__error-label {
    margin-top: 4px;
    color: ${(props) => props.theme.colorRed};
    font-size: 12px;
    line-height: 13px;
  }
`;

export default Input;
