import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { AppContext } from "../../AppContext";
import { getApiUrl, getCSRF, getMonth, getYear, logging, Show } from "../../../utils";
import { Months, OnConfirmMessages, DefaultStartYear } from "../../../constants";
import WorkList from "./WorkList";
import DateSelect from "../../UI/inputs/DateSelect";
import Checkbox from "../../UI/inputs/Checkbox";
import Input from "../../UI/inputs/Input";
import SubmitButton from "../../UI/buttons/SubmitButton";
import DeleteButton from "../../UI/buttons/rounded/DeleteButton";
import { localeErrorTexts,  localeSettingsLabels } from "../../../Internationalization";
import { SettingsTitles } from "../../../Routes";

const ValidationSchema = Yup.object().shape({
  company: Yup.string().trim().required("required-field"),
  post: Yup.string().trim().required("required-field"),
  achievements: Yup.string().trim().required("required-field"),
  toggleNow: Yup.bool(),
  workStartMonth: Yup.string().required("required-field"),
  workStartYear: Yup.number().required("required-field"),
  workEndMonth: Yup.mixed().test("toggle", "required-field", function (endMonth) {
    const toggle = this.resolve(Yup.ref("toggleNow"));
    return !!toggle || !!endMonth;
  }),
  workEndYear: Yup.mixed()
    .test("toggle", "required-field", function (endYear) {
      const toggle = this.resolve(Yup.ref("toggleNow"));
      return !!toggle || !!endYear;
    })
    .test("later", "date-error", function (endYear) {
      const toggleRef = Yup.ref("toggleNow");
      if (this.resolve(toggleRef)) {
        return true;
      }
      const startYear = this.resolve(Yup.ref("workStartYear"));
      if (!startYear) {
        return true;
      }
      if (endYear > startYear) {
        return true;
      }
      if (endYear < startYear) {
        return false;
      }
      const startMonth = this.resolve(Yup.ref("workStartMonth"));
      const endMonth = this.resolve(Yup.ref("workEndMonth"));
      if (!(startMonth && endMonth)) {
        return true;
      }
      return endMonth >= startMonth;
    }),
});

const WorkExperience = ({ action }) => {
  const { ownProfile, setOwnProfile, showSuccessModal, locale } = useContext(AppContext);
  const [workIndex, setWorkIndex] = useState(0);
  useEffect(() => {
    setWorkIndex(0);
  }, []);

  const checkWork = (info, otherwise = "") => JSON.stringify(ownProfile.works) !== "[]" && workIndex >= 0 ? ownProfile.works[workIndex][info] : otherwise;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      toggleNow: checkWork("date_now", false),
      company: checkWork("company"),
      post: checkWork("post"),
      achievements: checkWork("achievements"),
      workStartMonth: getMonth(checkWork("date_start")),
      workStartYear: getYear(checkWork("date_start")),
      workEndMonth: getMonth(checkWork("date_end")),
      workEndYear: getYear(checkWork("date_end")),
      workID: checkWork("id", 0),
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      values = ValidationSchema.cast(values);
      const res = await axios.post(getApiUrl(`settings/${action}`), values, {
        headers: getCSRF(),
      });
      if (res.status === 200) {
        setOwnProfile(res.data);
        showSuccessModal();
      }
      logging(res);
    },
  });

  if (!ownProfile) return null;

  return (
    <WorkDiv>
      <div className="settings__header">{SettingsTitles[locale][action]}</div>
      <WorkList
        works={ownProfile.works}
        workIndex={workIndex}
        setWorkIndex={setWorkIndex}
      />
      <Show when={ownProfile && ownProfile.works.length > 0}>
        <WorkForm
          index={workIndex}
          formik={formik}
          action={action}
          setOwnProfile={setOwnProfile}
          ownProfile={ownProfile}
          locale={locale}
        />
      </Show>
    </WorkDiv>
  );
};

const WorkForm = (props) => {
  const { formik, index, action, setOwnProfile, ownProfile, locale } = props;
  const startYear = DefaultStartYear;
  const endYear = new Date().getFullYear();
  const countYear = endYear - startYear + 1;

  const clearExperience = async () => {
    const res = await axios.post(
      getApiUrl(`settings/${action}__clear`),
      { index },
      { headers: getCSRF() }
    );
    logging(res);
    if (res.status === 200) {
      setOwnProfile(res.data);
      return true;
    }
    return false;
  };

  const monthOptions = Object.keys(Months[locale])
    .sort()
    .map((key) => ({ key, value: key, label: Months[locale][key] }));

  const yearOptions = Array.from(Array(countYear), (e, i) => ({
    key: (endYear - i).toString(),
    value: (endYear - i).toString(),
    label: (endYear - i).toString(),
  }));
  const endYearErrorCondition = formik.touched.workEndYear && formik.errors.workEndYear;
  const endMonthErrorCondition = (formik.touched.workEndMonth && formik.errors.workEndMonth) ||
    (formik.errors.workEndYear && (formik.errors.workEndYear === localeErrorTexts[locale]["date-error"]));

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="settings__label">{localeSettingsLabels[locale]["experience__date-start"]}</div>
        <DateSelect valueName="workStart" formik={formik} monthOptions={monthOptions} yearOptions={yearOptions} />
        <div className="settings__label">{localeSettingsLabels[locale]["experience__date-end"]}</div>
        <Checkbox className="settings__checkbox" valueName="toggleNow" label={localeSettingsLabels[locale]["experience__currently-work"]} gap="13px" />
        <Show when={!formik.values.toggleNow}>
          <DateSelect
            valueName="workEnd"
            formik={formik}
            monthOptions={monthOptions}
            yearOptions={yearOptions}
            monthErrorCondition={endMonthErrorCondition}
            yearErrorCondition={endYearErrorCondition}
          />
        </Show>
        <div className="settings__label">{localeSettingsLabels[locale]["experience__company"]}</div>
        <Input className="settings__input" valueName="company" formik={formik} placeholder="LLC Dream Team" maxLength="30" />
        <div className="settings__label">{localeSettingsLabels[locale]["experience__post"]}</div>
        <Input className="settings__input" valueName="post" formik={formik} placeholder="Product Manager" maxLength="50" />
        <div className="settings__label">{localeSettingsLabels[locale]["experience__achievements"]}</div>
        <Input
          textarea
          className="settings__input"
          valueName="achievements"
          formik={formik}
          placeholder="experience__achievements-input"
          maxLength="300"
          counter
        />
        <div className="settings__btns">
          <SubmitButton className="settings__submit" />
          <Show when={ownProfile.works[index].company}>
            <DeleteButton className="settings__btn-delete" onClick={clearExperience} text={OnConfirmMessages[locale].clearWork(`${localeSettingsLabels[locale]["experience__work"]} ${index + 1}`)} />
          </Show>
        </div>
      </form>
    </FormikProvider>
  );
};

const WorkDiv = styled.div`
  display: flex;
  flex-direction: column;

  .settings__header {
    margin-bottom: 24px;
  }

  .settings__checkbox {
    margin-bottom: 8px;
  }
`;

export default WorkExperience;
