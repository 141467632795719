import React from "react";
import styled from "styled-components";
import { activeClass } from "../../../utils";
import Filter from "../SVGs/Filter";

const ButtonFilter = ({ className, isActive, ...props }) => (
  <StyledDiv className={activeClass(className, isActive)}{...props}>
    <div className="button-filter__icon">
      <Filter />
    </div>
  </StyledDiv>
);

const StyledDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  background: ${(props) => props.theme.colorGrey};
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: center;

  .button-filter__icon {
    stroke: ${(props) => props.theme.colorDark};
    stroke-width: 1.2px;
  }

  &.active-state {
    background: ${(props) => props.theme.colorDark};

    .button-filter__icon {
      stroke: ${(props) => props.theme.colorGreyLight};
    }
  }
`;

export default ButtonFilter;
