import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import Cookies from "js-cookie";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.locale("ru");

const msPerSecond = 1000;
const msPerMinute = msPerSecond * 60;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerMonth = msPerDay * 30;
const msPerYear = msPerDay * 365;

export const getCSRF = () => ({ "X-CSRFToken": Cookies.get("csrftoken") });

export const Show = ({ when = false, children, otherwise = null }) => (when ? children : otherwise);

export const parseDate = (date, type = "") => {
  if (date) {
    switch (type) {
      case "year":
        return dayjs(date).format("YYYY").concat(" г.");
      case "date":
        return dayjs(date).format("ll");
      default:
        return dayjs(date).format("lll");
    }
  }
  return null;
};

export const getRelativeDate = (_date, locale = "ru") => {
  const date = dayjs(_date);
  if (dayjs(date).add(10, "month").isBefore(Date.now())) {
    return dayjs(date).locale(locale).format("LL");
  }
  return date.locale(locale).fromNow();
};

export const getMonth = (date) => (date ? date.substr(5, 2) : "");
export const getYear = (date) => (date ? date.substr(0, 4) : "");

export const getMiroUrl = (id, autoOpen = false) =>
  id ? `https://miro.com/app/live-embed/${id}/?embedAutoplay=${autoOpen}` : null;
// id ? `https://miro.com/app/embed/${id}/?autoplay=${autoOpen ? "yep" : "false"}` : null;


export const Timeout = (delay) => new Promise((res) => setTimeout(res, delay));

export const numberWithSpaces = (number) => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

export const logging = (...args) => process.env.REACT_APP_CONSOLE_LOGGING === "on" && console.log(args);

export const addClass = (firstClass, secondClass, condition = secondClass) => condition ? [firstClass, secondClass].join(" ") : firstClass;

export const activeClass = (firstClass, condition) => addClass(firstClass, "active-state", !!condition);

export const getApiUrl = (url = "") => `${window.location.origin}/api/${url}`;
export const getMediaUrl = (url = "") => `${window.location.protocol}//api.${window.location.hostname}/media/${url}`;


export const defaultRequest = async (url = "", values, headers = {}, onSuccess = () => { }, onFailure = () => { }, onError = () => { }) => {
  axios.post(url, values, {
    headers: { ...getCSRF(), ...headers },
  }).then((res) => {
    if (res.status === 200) {
      onSuccess(res);
    } else {
      onError(res);
    }
    logging(res);
  }).catch((e) => {
    console.log(e);
    onFailure(e);
  });
};

export const apiRequest = async (url = "", values, headers, onSuccess, onFailure, onError) =>
  defaultRequest(getApiUrl(url), values, headers, onSuccess, onError, onFailure);
