import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { A, navigate } from "hookrouter";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { AppContext } from "../../AppContext";
import { SettingsItems } from "../../../Routes";
import GearSVG from "../../UI/SVGs/Gear";
import SuitcaseSVG from "../../UI/SVGs/Suitcase";
import MedalSVG from "../../UI/SVGs/Medal";
import EditSVG from "../../UI/SVGs/Edit";
import PlusSVG from "../../UI/SVGs/Plus";
import AddedWorksSVG from "../../UI/SVGs/AddedWorks";
import LogoutSVG from "../../UI/SVGs/Logout";
import { activeClass, getApiUrl } from "../../../utils";
import { OnConfirmMessages } from "../../../constants";
import { localeButtonTexts } from "../../../Internationalization";

export const MenuItem = ({ item, className = "settings__menu-item" }) => {
  const theme = useTheme();
  const { settingsAction, toggleMobileMenu } = useContext(AppContext);
  const isActiveItem = item === settingsAction;

  const cName = activeClass(className, isActiveItem);
  const href = `/settings/${item}`;

  const onClick = () => toggleMobileMenu(false);

  const itemProps = { className: cName, href, onClick, id: `item__${item}` };

  const items = {
    [SettingsItems.profileSettings]: <SettingsItem {...itemProps} image={<GearSVG />} />,
    [SettingsItems.workExperience]: <SettingsItem {...itemProps} image={<SuitcaseSVG />} />,
    [SettingsItems.education]: <SettingsItem {...itemProps} image={<MedalSVG />} />,
    [SettingsItems.description]: <SettingsItem {...itemProps} image={<EditSVG />} />,
    [SettingsItems.chooseArtifactType]: <SettingsItem {...itemProps} image={<PlusSVG />} />,
    [SettingsItems.addArtifact]: <SettingsItem {...itemProps} image={<PlusSVG />} />,
    [SettingsItems.addCJM]: <SettingsItem
      {...itemProps} image={<PlusSVG />}
      onClick={() => {
        !isActiveItem && window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', 'settings-add-cjm');
        onClick();
      }}
    />,
    [SettingsItems.addedArtifacts]: <SettingsItem {...itemProps} image={<AddedWorksSVG />} />,
    [SettingsItems.exit]: <ExitItem {...itemProps} />,
  };

  return items[item];
};

const SettingsItem = ({ children, image, id, ...props }) => (
  <WrapperDiv {...props}>
    {image}
    <div className="item-title"><FormattedMessage id={id} /></div>
    <div className="active-point" />
  </WrapperDiv>
);



const ExitItem = (props) => {
  const { setUser, setConfirmProps, locale } = useContext(AppContext);

  const logoutRequest = async () => {
    const logout = () => {
      setUser(null);
      navigate("/");
    };
    try {
      const request = await axios.get(getApiUrl("logout/"));
      request.status === 200 && logout();
    } catch (e) { }
    setConfirmProps({ visible: false });
    window.location.reload();
  };

  const onClick = async (e) => {
    setConfirmProps({
      confirmText: OnConfirmMessages[locale].exit,
      confirmButtonText: localeButtonTexts[locale].exit,
      confirmAction: () => logoutRequest(),
      visible: true,
    });
  };

  return <SettingsItem {...props} image={<LogoutSVG />} onClick={onClick} href="" />;
};

const WrapperDiv = styled(A)`
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colorDark};
  stroke: ${props => props.theme.colorDark};
  stroke-width: 1.2px;

  & > * + * {
    margin-left: 27px;
  }

  .item-title {
    white-space: nowrap;
    flex-grow: 1;
    font-size: 16px;
    line-height: 30px;
    padding-right: 15px;
    font-weight: bold;
  }
  svg {
    flex-shrink: 0;
    width: 24px;
  }


  &:hover,
  &:link,
  &:active,
  &:visited {
    text-transform: none;
    text-decoration: none;
  }

  :hover {
    color: ${(props) => props.theme.colorMain};
    stroke: ${(props) => props.theme.colorMain};
    text-transform: none;
    text-decoration: none;
  }

  &.active-state {
    color: ${(props) => props.theme.colorMain};
    stroke: ${(props) => props.theme.colorMain};
    font-weight: bold;
    stroke-width: 2px;

    .active-point {
      border-right: solid 4px ${(props) => props.theme.colorMain};
    }
  }
`;
