import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { MenuItem } from "./SettingsMenuItem";
import { SettingsItems } from "../../../Routes";
import { device } from "../../../constants";
import InterProvider from "../../../Internationalization";

const SettingsMenu = (props) => (
  <MenuDiv>
    <MainSettingsMenu />
  </MenuDiv>
);

export const MainSettingsMenu = ({ itemClass = "settings__menu-item", marginBottom }) => {
  const profileItems = ["profileSettings", "workExperience", "education", "description"];
  const portfolioItems = ["addArtifact", "addedArtifacts"];
  return (
    <InterProvider id="settings-menu">
    <div className="settings__main-menu">
      <SettingsBlock title={<FormattedMessage id="block__profile"/>} items={profileItems} itemClass={itemClass} />
      <SettingsBlock title={<FormattedMessage id="block__portfolio"/>} items={portfolioItems} itemClass={itemClass} />
      <ExitBlock marginBottom={marginBottom}/>
    </div>
    </InterProvider>
  );
};
const SettingsBlock = ({ title, items, itemClass, ...props }) => (
  <StyledMenuBlock {...props}>
    <div className="menublock__title">{title}</div>
    {items.map((value) => <MenuItem key={value} className={itemClass} item={SettingsItems[value]} />)}
  </StyledMenuBlock>
);

const ExitBlock = (props) => (
  <StyledExitBlock marginBottom={props.marginBottom}>
    <MenuItem item={SettingsItems.exit} />
  </StyledExitBlock>
);

const MenuDiv = styled.div`
  position: sticky;
  top: 0px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 138px;
  /* border-right: 1px solid ${props => props.theme.colorGrey}; */
  width: 331px;

  .settings__main-menu {
    width: 100%;
    display: flex;
    align-self: flex-start;
    justify-content: stretch;
    flex-direction: column;
    white-space: nowrap;
  }

  .settings__menu-item {
    margin-top: 30px;
  }

  .settings-footer {
    margin-top: 40px;
  }

  @media ${device.desktop} {
    width: 286px;
  }

  @media ${device.tablet_landscape} {
    padding-left: 24px;
  }

  @media ${device.tablet_portrait} {
    display: none;
  }
`;

const StyledMenuBlock = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  margin-top: 60px;

  .menublock__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colorFont};
  }

  &:first-of-type {
    margin-top: 0px;
  }
`;

const StyledExitBlock = styled(StyledMenuBlock)`
  margin-top: 115px;
  margin-bottom: ${(props) => props.marginBottom || "60px"};

  @media ${device.tablet_portrait} {
    margin-top: 90px;
  }
`;

export default SettingsMenu;
