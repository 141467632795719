import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { AppContext } from "../../AppContext";
import { getApiUrl, getCSRF, logging, Show } from "../../../utils";
import Cards from "../../cards/NewCards";
import { SettingsTitles } from "../../../Routes";

const AddedArtifacts = ({ action }) => {

  const { ownArtifacts, setOwnArtifacts, toggleLoadingModal, locale } = useContext(AppContext);
  useEffect(() => {
    const fetchArtifacts = async () => {
      if (!ownArtifacts || ownArtifacts.length===0) {
        toggleLoadingModal(true);
      }
      try {
        const res = await axios.get(getApiUrl("artifacts/get-own-artifacts/"), {
          headers: getCSRF(),
        });

        if (res.status === 200) {
          toggleLoadingModal(false);
          setOwnArtifacts(res.data.artifacts);
        }
      } catch (e) {
        logging(e);
      }
      toggleLoadingModal(false);
    };
    fetchArtifacts();
  }, [setOwnArtifacts, toggleLoadingModal]);

  return (
    <AddedDiv>
      <div className="settings__header">{SettingsTitles[locale][action]}</div>
      <Show when={ownArtifacts}>
        <Cards cards={ownArtifacts} isOwner noneText={"У\xa0вас\xa0пока\xa0нет опубликованных\xa0работ."}/>
      </Show>
    </AddedDiv>
  );
};

const AddedDiv = styled.div`
  display: flex;
  flex-direction: column;

  .settings__header {
    margin-bottom: 30px;
  }
`;

export default AddedArtifacts;
