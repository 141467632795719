import React, { useContext, useState } from "react";
import styled from "styled-components";
import { SettingsItems, SettingsTitles  } from "../../../../Routes";
import BackToArtifactSelect from "../BackToArtifactSelect";
import AddPRForm from "./AddPRForm";
import AddArtifactFormik from "../AddArtifactFormik";
import { ValidationSchema } from "./AddPRValidationSchema";
import LoadNotion from "./LoadNotion";
import { ArtifactTypes } from "../../../../constants";
import { Show } from "../../../../utils";
import { AppContext } from "../../../AppContext";

const PR_LOCAL_STORAGE_KEY = "addPr";
const PR_INITIAL_VALUES = {
  id: null,
  notion_link: null,
  embednotion_link: null,
  preview_image: null,
  title: "",
  company: "",
  industry: "",
  tags: [],
  description: "",
};

const AddPR = ({ action, artifactIndex }) => {

  const isChanging = action === SettingsItems.changePR;
  const [submitActive, setSubmitActive] = useState(true);
  const formik = AddArtifactFormik(artifactIndex, isChanging, setSubmitActive, PR_LOCAL_STORAGE_KEY, PR_INITIAL_VALUES, ArtifactTypes.PR, ValidationSchema);
  const { locale } = useContext(AppContext);
  const enableSubmit = submitActive && formik.values.notion_link;

  return (
    <StyledDiv>
      <Show when={!isChanging}>
        <BackToArtifactSelect className="add-pr__back-to-select" />
      </Show>
      <div className="settings__header add-pr__header">{SettingsTitles[locale][action]}</div>
      <LoadNotion className="add-pr__load-notion" formik={formik} />
      <AddPRForm formik={formik} disableSubmit={!enableSubmit} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .add-pr__load-notion {
    margin-bottom: 30px;
  }

  .add-pr__back-to-select {
    margin-bottom: 40px;
  }

  .add-pr__header {
    margin-bottom: 30px;
  }
`;

export default AddPR;
