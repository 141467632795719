import React, { useContext, useState, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import axios from "axios";
import Cookies from "js-cookie";
import "../../fonts.css";
import { getApiUrl, logging, Show } from "../../utils";
import { AppContext } from "../AppContext";
import { SettingsItems } from "../../Routes";
import { ModalBackground, SmallCrossButton } from "../SmallComponents";
import { device, ScreenSizes } from "../../constants";
import { localeButtonTexts } from "../../Internationalization";

const ChangePictureModal = () => {
  const {
    changePictureModalVisible,
    toggleChangePictureModal,
    setNewPicture,
    newPicture,
    showSuccessModal,
    setOwnProfile,
    currentWidth,
    locale,
  } = useContext(AppContext);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState({ value: 1, max: 5, min: 1, step: 0.1 });
  const [avatarSize, setAvatarSize] = useState(null);


  useEffect(() => {
    const sizes = {
      default: 170,
      [ScreenSizes.phone_landscape]: 120,
      [ScreenSizes.phone_portrait]: 150,
    };
    currentWidth in sizes ? setAvatarSize(sizes[currentWidth]) : setAvatarSize(sizes.default);
  }, [currentWidth]);

  const onClick = async () => {
    toggleChangePictureModal(false);
    const csrftoken = Cookies.get("csrftoken");
    const form_data = new FormData();
    const canvas = editor.getImageScaledToCanvas().toDataURL();
    let imageBlob;
    await fetch(canvas)
      .then((res) => res.blob())
      .then((blob) => (imageBlob = blob));
    form_data.append("picture", imageBlob, "picture.png");
    const res = await axios.post(
      getApiUrl(`settings/${SettingsItems.profileSettings}__change-picture`),
      form_data,
      {
        headers: { "X-CSRFToken": csrftoken, "content-type": "multipart/form-data" },
      }
    );
    if (res.status === 200) {
      setOwnProfile(res.data);
      showSuccessModal();
    }
    logging(res);
  };

  const onClose = () => {
    toggleChangePictureModal(false);
    setNewPicture(null);
  };

  const setRef = (editor) => setEditor(editor);
  return (
    <Show when={changePictureModalVisible}>
      <ModalBackground onClick={onClose}>
        <ChangePictureModalDiv onClick={(e) => e.stopPropagation()}>
          <AvatarEditor
            ref={setRef}
            image={newPicture}
            width={avatarSize}
            height={avatarSize}
            borderRadius={85}
            border={0}
            color={[255, 255, 255, 0.7]} // RGBA
            scale={scale.value}
            rotate={0}
          />
          <div>
            <input
              className="change-picture__input"
              type="range"
              id="scale"
              name="scale"
              min={scale.min}
              max={scale.max}
              step={scale.step}
              value={scale.value}
              onChange={(e) => setScale({ ...scale, value: parseFloat(e.target.value) })}
            />
          </div>
          <div className="change-picture__btn" onClick={onClick}>
            {localeButtonTexts[locale].save}
          </div>
          <div className="change-picture__small-cross-button">
            <SmallCrossButton onClick={onClose} color="black" />
          </div>
        </ChangePictureModalDiv>
      </ModalBackground>
    </Show>
  );
};

const ChangePictureModalDiv = styled.div`
  padding: 80px 107px 51px 107px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: ${(props) => props.theme.colorWhite};
  color: ${(props) => props.theme.colorDark};
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-radius: 10px;
  align-items: center;
  justify-content: center;

  .change-picture__small-cross-button {
    display: none;
  }

  .change-picture__input {
    margin-top: 39px;
    width: 234px;
    height: 5px;
  }

  .change-picture__btn {
    margin-top: 55px;
    cursor: pointer;
    background: ${(props) => props.theme.colorMain};
    border: none;
    border-radius: 5px;
    font-weight: bold;
    width: 217px;
    height: 64px;
    font-size: 16px;
    line-height: 64px;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    color: ${(props) => props.theme.colorWhite};
  }

  @media ${device.phone_landscape} {
    width: auto;
    height: 100%;
    right: 0;
    border-radius: 0;
    padding: 35px 24px 24px 24px;

    .change-picture__small-cross-button {
      display: block;
      position: absolute;
      z-index: 15;
      cursor: pointer;
      top: 16px;
      right: 24px;
      width: 24px;
      height: 24px;
    }

    .change-picture__btn {
      width: 432px;
    }
  }

  @media ${device.phone_portrait} {
    padding: 109px 16px 68px 16px;

    .change-picture__btn {
      height: 48px;
      line-height: 48px;
      width: 100%;
    }

    .change-picture__small-cross-button {
      top: 15px;
      right: 14px;
    }
  }
`;

export default ChangePictureModal;
