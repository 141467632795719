import React from "react";
import styled from "styled-components";
import RoundedRefresh from "../../SVGs/rounded/RoundedRefresh";
import RoundedButton from "./RoundedButton";

const RoundedRefreshButton = ({ className, ...props }) => (
  <StyledButton className={className}>
    <RoundedButton {...props}>
      <RoundedRefresh />
    </RoundedButton>
  </StyledButton>
);

const StyledButton = styled.div`
  .rounded-button {
    background-color: ${(props) => props.theme.colorMain};
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    stroke: ${(props) => props.theme.colorWhite};
    fill: ${(props) => props.theme.colorWhite};
  }
`;

export default RoundedRefreshButton;
