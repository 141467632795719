import React, { useState, useEffect } from "react";

const useWidth = () => {
  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
  const [width, setWidth] = useState(getWidth());
  useEffect(() => {
    const resize = () => {
      setWidth(getWidth());
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return width;
};

export default useWidth;
