import React from "react";
import styled from "styled-components";

const BurgerMenuButton = ({ ...props }) => (
  <StyledButton {...props} >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12H21" stroke="#121C43" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 6H21" stroke="#121C43" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 18H21" stroke="#121C43" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </StyledButton>
);

const StyledButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export default BurgerMenuButton;
