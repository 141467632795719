import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const HowCreate = () => {
  const embedId = "hKpXrAsurT4";
  return (
    <HowCreateDiv>
      <div className="for-product__block-title"><FormattedMessage id="how-create__title" defaultMessage=""/></div>
      <div className="how-create__youtube-embed">
        <YoutubeEmbed embedId={embedId} />
      </div>
    </HowCreateDiv>
  );
};

const YoutubeEmbed = ({ embedId }) => (
  <YoutubeEmbedDiv>
    <iframe
      width="912"
      height="570"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </YoutubeEmbedDiv>
);

const YoutubeEmbedDiv = styled.div`
  flex-grow: 1;
  max-width: 1164px;
  overflow: hidden;
  padding-bottom: min(56.25%, 652px);
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const HowCreateDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;

  .how-create__youtube-embed {
    align-self: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export default HowCreate;
