import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { navigate } from "hookrouter";
import { getApiUrl, getCSRF, logging, Timeout } from "../../../utils";
import useLocalStorageState from "../../hooks/useLocalStorageState";
import { AppContext } from "../../AppContext";
import { SettingsItems } from "../../../Routes";


const AddArtifactFormik = (artifactIndex, isChanging, setSubmitActive, localStorageKey, InitialValues, artifactType, ValidationSchema) => {

  const { showSuccessModal, ownArtifacts, setOwnArtifacts, toggleLoadingModal } = useContext(AppContext);
  const [localArtifact, handleUpdateForm] = useLocalStorageState({ key: localStorageKey, value: InitialValues });
  useEffect(() => {
    const fetchArtifacts = async () => {
      toggleLoadingModal(true);
      try {
        const res = await axios.get(getApiUrl(`artifacts/get-own-artifacts/`), {
          headers: getCSRF(),
        });

        if (res.status === 200) {
          toggleLoadingModal(false);
          setOwnArtifacts(res.data.artifacts);
        }
      } catch (e) {
        logging(e);
      }
      toggleLoadingModal(false);
    };
    fetchArtifacts();
  }, [setOwnArtifacts, toggleLoadingModal]);


  const setArtifactData = () => {
    let artifact = {};
    if (isChanging) {
      if (ownArtifacts.length > artifactIndex) {
        artifact = ownArtifacts[artifactIndex];
      } else {
        Object.assign(artifact, InitialValues);
      }
    } else {
      artifact = localArtifact;
    }
    return artifact;
  };

  const artifact = setArtifactData();

  const setFormikInitialValues = () => {
    const initialValues = {};
    Object.keys(InitialValues).forEach((key) => initialValues[key] = artifact[key]);
    return initialValues;
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: setFormikInitialValues(),
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setSubmitActive(false);
      toggleLoadingModal(true);
      values = ValidationSchema.cast(values);
      const data = { ...values };
      try {
        const res = await axios.post(getApiUrl(`artifacts/add-artifact/${artifactType}`), data, {
          headers: getCSRF(),
        });

        if (res.status === 200) {
          toggleLoadingModal(false);
          showSuccessModal(res.data.result_text, res.data.success);
          if (res.data.success) {
            !isChanging && window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', `${artifactType.toLowerCase()}-added-successfully`);
            await Timeout(2000);
            navigate(`/settings/${SettingsItems.addedArtifacts}`);
            window.location.reload();
          }
          !isChanging && handleUpdateForm(InitialValues);
        }
        logging(res);
      } catch (e) {
        logging(e);
      } finally {
        setSubmitActive(true);
        toggleLoadingModal(false);
      }
    },
  });

  useEffect(() => {
    !isChanging && handleUpdateForm(formik.values);
  }, [formik.values, handleUpdateForm, isChanging]);

  return formik;
};

export default AddArtifactFormik;
