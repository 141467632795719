import React, { useContext, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import "../../fonts.css";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { getApiUrl, logging, Show } from "../../utils";
import { AppContext } from "../AppContext";
import { CrossButton, SmallCrossButton } from "../SmallComponents";
import { device } from "../../constants";
import InterProvider from "../../Internationalization";

const LoginModal = () => {

  const { loginModalVisible, toggleLoginModal, locale } = useContext(AppContext);
  const theme = useTheme();


  return (
    <InterProvider id="modal__login">
      <Show when={loginModalVisible}>
        <Background onClick={() => toggleLoginModal(false)}>
          <LoginModalDiv onClick={(e) => e.stopPropagation()}>
            <div className="modal-header"><FormattedMessage id="header" /></div>
            <NewGoogleButton locale={locale}/>
            {/* <AppleButton /> */}
            <FacebookButton/>
            <PoliticsMessage />
          </LoginModalDiv>
          <div className="login-modal__btn-cross">
            <CrossButton strokeColorOnTabletPortrait={theme.colorDark} />
          </div>
          <div className="login-modal__small-btn-cross">
            <SmallCrossButton />
          </div>
        </Background>
      </Show>
    </InterProvider>
  );
};

const PoliticsMessage = () => {
  const agreementUrl = `${process.env.PUBLIC_URL}/agreement.pdf`;
  const confidenceUrl = `${process.env.PUBLIC_URL}/confidence.pdf`;

  return (
    <div className="politics-wrapper">
      <div className="politics">
        <FormattedMessage
          id="politics-text"
          values={{
            confidence: (...chunks) => <a href={confidenceUrl} className="link" target="_blank" rel="noreferrer">{chunks}</a>,
            agreement: (...chunks) => <a href={agreementUrl} className="link" target="_blank" rel="noreferrer">{chunks}</a>,
          }
          }
        />
      </div>
    </div>
  );
};

const NewGoogleButton = ({locale}) => (
  <a className="login-button lb-google" href={`${window.location.protocol}//${window.location.hostname}/accounts/google/login/`}>
    <div className="logo-wrapper">
      <div className="logo-svg">
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.5 6.53125C18.9338 6.53125 21.1131 7.37 22.8319 9.00625L27.5413 4.29688C24.6813 1.63625 20.9481 0 16.5 0C10.0513 0 4.47563 3.69875 1.76001 9.08875L7.24626 13.3444C8.54563 9.4325 12.1963 6.53125 16.5 6.53125Z"
            fill="#EA4335"
          />
          <path
            d="M32.2987 16.8781C32.2987 15.7987 32.1956 14.7538 32.0375 13.75H16.5V19.9513H25.3962C24.9975 21.9863 23.8425 23.7188 22.11 24.8875L27.4244 29.0125C30.525 26.1388 32.2987 21.89 32.2987 16.8781Z"
            fill="#4285F4"
          />
          <path
            d="M7.23937 19.6557C6.90937 18.6589 6.71688 17.6001 6.71688 16.5001C6.71688 15.4001 6.9025 14.3414 7.23937 13.3445L1.75312 9.08887C0.6325 11.3164 0 13.8326 0 16.5001C0 19.1676 0.6325 21.6839 1.76 23.9114L7.23937 19.6557Z"
            fill="#FBBC05"
          />
          <path
            d="M16.4999 32.9999C20.9549 32.9999 24.7018 31.5356 27.4243 29.0056L22.1099 24.8806C20.6318 25.8774 18.7274 26.4618 16.4999 26.4618C12.1962 26.4618 8.54555 23.5606 7.2393 19.6487L1.75305 23.9043C4.47555 29.3012 10.0512 32.9999 16.4999 32.9999Z"
            fill="#34A853"
          />
        </svg>
      </div>
    </div>
    <div className={`logo-text ${locale}`}><FormattedMessage id="button__google-text" /></div>
  </a>
);

const AppleButton = ({locale}) => (
  <div className="login-button lb-apple">
    <div className="logo-wrapper">
      <div className="logo-svg">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.3734 12.0367C17.9134 12.0367 15.6534 10.3767 13.2734 10.4367C10.1334 10.4767 7.25344 12.2567 5.63344 15.0767C2.37344 20.7367 4.79344 29.0967 7.97344 33.6967C9.53344 35.9367 11.3734 38.4567 13.8134 38.3767C16.1534 38.2767 17.0334 36.8567 19.8734 36.8567C22.6934 36.8567 23.4934 38.3767 25.9734 38.3167C28.4934 38.2767 30.0934 36.0367 31.6334 33.7767C33.4134 31.1767 34.1534 28.6567 34.1934 28.5167C34.1334 28.4967 29.2934 26.6367 29.2334 21.0367C29.1934 16.3567 33.0534 14.1167 33.2334 14.0167C31.0334 10.7967 27.6534 10.4367 26.4734 10.3567C23.3934 10.1167 20.8134 12.0367 19.3734 12.0367ZM24.5734 7.31675C25.8734 5.75675 26.7334 3.57675 26.4934 1.41675C24.6334 1.49675 22.3934 2.65675 21.0534 4.21675C19.8534 5.59675 18.8134 7.81675 19.0934 9.93675C21.1534 10.0967 23.2734 8.87675 24.5734 7.31675Z"
            fill="#121C43"
          />
        </svg>
      </div>
    </div>
    <div className={`logo-text ${locale}`}>Apple account</div>
  </div>
);

const FacebookButton = () => {
  const { toggleLoginModal, locale } = useContext(AppContext);

  const fbLogin = async (accesstoken) => {
    logging("fbLogin");
    const res = await axios.post(getApiUrl("rest-auth/facebook/"), {
      access_token: accesstoken,
    });
    logging(res);
    toggleLoginModal(false);
    return res.status;
  };

  const responseFb = async (response) => {
    logging("responseFb");
    logging(response);
    const fbResponse = await fbLogin(response.accessToken);
    localStorage.setItem("facebook_access_token", response.accessToken);
    logging(fbResponse);
    window.location.reload();
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_CLIENTID_FACEBOOK}
      fields="name,email,picture"
      callback={responseFb}
      render={(renderProps) => (
        <div className="login-button lb-facebook" onClick={renderProps.onClick}>
          <div className="logo-wrapper">
            <div className="logo-svg">
              <svg width="19" height="34" viewBox="0 0 19 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 0.333252H14C11.7898 0.333252 9.67021 1.21123 8.1074 2.77403C6.5446 4.33683 5.66663 6.45645 5.66663 8.66659V13.6666H0.666626V20.3333H5.66663V33.6666H12.3333V20.3333H17.3333L19 13.6666H12.3333V8.66659C12.3333 8.22456 12.5089 7.80064 12.8215 7.48808C13.134 7.17552 13.5579 6.99992 14 6.99992H19V0.333252Z"
                  fill="#3B5997"
                />
              </svg>
            </div>
          </div>
          <div className={`logo-text ${locale}`}><FormattedMessage id="button__facebook-text" /></div>
        </div>
      )}
    />
  );
};

const LoginModalDiv = styled.div`
  padding: 10px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  width: 670px;
  height: 532px;
  background: ${props => props.theme.colorWhite};
  color: ${props => props.theme.colorDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 15;
  border-radius: 5px;

  & > * + * {
    margin-top: 30px;
  }

  .modal-header {
    text-transform: uppercase;
    margin-bottom: 26px;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    text-align: center;
  }

  .login-button {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 2px;
    max-width: 454px;
    color: #fff;
    cursor: pointer;

    .logo-wrapper {
      width: 95px;
      height: 63px;
      border-radius: 5px;
      justify-self: center;
      background: #fff;
      display: grid;

      .logo-svg {
        flex-shrink: 0;
        text-align: center;
        justify-self: center;
        align-self: center;
      }
    }
  }

  .lb-google {
    background: #3367d6;

    .logo-wrapper {
      .logo-svg {
        width: 33px;
        height: 33px;
      }
    }
  }

  .lb-apple {
    border: 1px solid ${(props) => props.theme.colorDark};
    background: #fff;
    color: ${(props) => props.theme.colorDark};

    .logo-wrapper {
      .logo-svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .lb-facebook {
    background: #3b5997;
    color: #fff;

    .logo-wrapper {
      .logo-svg {
        width: 19px;
        height: 33px;
      }
    }
  }

  .logo-text {
    flex-grow: 1;
    margin-left: 95px;
    font-size: 18px;
    line-height: 30px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;

    &.en {
      margin-left: 65px;
    }
  }

  .politics {
    margin-top: 10px;
    max-width: 535px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
  }

  @media ${device.tablet_landscape} {
    height: 439px;
    width: 600px;
    & > * + * {
      margin-top: 20px;
    }

    .modal-header {
      margin-bottom: 28px;
    }
    .politics {
      margin-top: 10px;
    }
  }

  @media ${device.tablet_portrait} {
    padding: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
    .modal-header {
      margin-bottom: 20px;
      width: 100%;
    }
    .logo-text {
      margin-left: 71px;
      &.en {
        margin-left: 41px;
      }
    }
  }

  @media ${device.phone_landscape} {
    & > * + * {
      margin-top: 10px;
    }
    .login-button {
      max-width: 406px;
    }
    .logo-wrapper {
      width: 85px;
      height: 56px;
    }
    .politics {
      margin-top: 0;
      max-width: 471px;
      font-size: 12px;
    }
    .modal-header {
      margin-bottom: 3px;
    }
  }

  @media ${device.phone_portrait} {
    .login-button {
      max-width: 300px;
    }
    .logo-wrapper {
      width: 62px;
      height: 46px;
    }
    .logo-text {
      font-size: 14px;
      margin-left: 38px;
      &.en {
        margin-left: 8px;
      }
    }
    .politics {
      max-width: 300px;
      font-size: 10px;
    }
    .modal-header {
      margin-bottom: 19px;
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(18, 28, 61, 0.7);
  position: fixed;
  top: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 7;

  .login-modal__btn-cross {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  .login-modal__small-btn-cross {
    display: none;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  @media ${device.tablet_portrait} {
    grid-template-columns: 1fr;

    .login-modal__btn-cross {
      z-index: 15;
    }
  }
  @media ${device.phone_landscape} {
    .login-modal__btn-cross {
      display: none;
    }
    .login-modal__small-btn-cross {
      display: block;
      z-index: 15;
    }
  }
`;

export default LoginModal;
