import React from "react";
import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  miro_id: Yup.string().trim().required("required-field"),
  title: Yup.string().trim().required("required-field"),
  company: Yup.string().trim().required("required-field"),
  industry: Yup.string().required("required-field"),
  description: Yup.string().trim().required("required-field"),
  tags: Yup.mixed().test("maxCount", "too-many-tags", (tags) => tags.length <= 5),
});
