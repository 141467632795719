import React from "react";
import styled from "styled-components";
import { Show } from "../../../utils";
import CertificateCards from "../../cards/CertificateCards";

const Certificates = ({ profileData, isOwner }) => {
  const certificates = profileData.certificates;
  return (
    <Show when={certificates}>
    <StyledDiv>
      <CertificateCards cards={certificates} noneText="" isOwner={false} className="profile__portfolio__cards"/>
    </StyledDiv>
  </Show>
  );
};

const StyledDiv = styled.div``;

export default Certificates;
