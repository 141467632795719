import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import { device } from "../../constants";
import { SearchButton } from "../SmallComponents";
import ProfileImage from "../UI/other/ProfileImage";
import SettingsButton from "../UI/buttons/SettingsButton";

const ProfileHeader = () => {
  const { ownProfile } = useContext(AppContext);
  return (
    ownProfile && (
      <ProfileHeaderDiv>
        <div className="profile-header__buttons">
          <SettingsButton className="profile-header__btn-settings" href="/settings"/>
          <div className="profile-header__btn-search">
            <SearchButton />
          </div>
          <LoginPlaceDiv>
            <ProfileImage picture={ownProfile.picture} href={`/${ownProfile.username}`} />
          </LoginPlaceDiv>
        </div>
      </ProfileHeaderDiv>
    )
  );
};

const Bell = () => (
  <div className="profile-header__btn-bell">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const ProfileHeaderDiv = styled.div`
  background: ${(props) => props.theme.colorWhite};
  display: flex;
  justify-self: end;
  .profile-header__buttons {
    align-self: center;
    display: flex;
    justify-items: end;
    align-items: center;

    & > * + * {
      margin-left: 35px;
    }

    .profile-header__btn-bell,
    .profile-header__btn-settings,
    .profile-header__btn-search {
      width: 24px;
      height: 24px;
      cursor: pointer;
      stroke: ${(props) => props.theme.colorFont};
      stroke-width: 1.2px;

      :hover {
        stroke: ${(props) => props.theme.colorMain};
      }

      &.active-state {
        stroke: ${(props) => props.theme.colorMain};
        stroke-width: 2px;
      }
    }

    .profile-header__btn-search {
      display: none;
    }
    @media ${device.tablet_portrait} {
      .profile-header__btn-bell,
      .profile-header__btn-settings {
        display: none;
      }
      .profile-header__btn-search {
        display: block;
      }
    }
  }

  @media ${device.phone_landscape} {
    width: 119px;
  }

  @media ${device.phone_portrait} {
    width: 91px;
  }
`;
const LoginPlaceDiv = styled.div`
  position: relative;
  width: 40px;
  height: 40px;

  @media ${device.tablet_portrait} {
    display: none;
  }
`;

export default ProfileHeader;
