import React, { useContext, useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Show } from "../../../../utils";
import { AppContext } from "../../../AppContext";
import UploadButton from "../../../UI/buttons/UploadButton";
import ArrowHint from "../../../UI/other/ArrowHint";
import Hint from "../../../UI/other/Hint";
import PreviewImage from "../PreviewImage";
import { localeButtonTexts, localeSettingsLabels } from "../../../../Internationalization";
import Input from "../../../UI/inputs/Input";
import ModalOther from "./ModalOther";
import { OnConfirmMessages } from "../../../../constants";

const LoadOther = ({ formik, noneText, ...props }) => {
  const { locale } = useContext(AppContext);
  const [uploadPicture, setUploadPicture] = useState(null);
  const [modalVisible, toggleModal] = useState(false);
  const deleteInfo = () => {
    formik.setFieldValue("preview_image", null);
  };

  const uploadPreviewImage = async (e) => {
    const picture = e.target.files[0];
    setUploadPicture(picture);
    e.target.value = "";
    toggleModal(true);
  };

  const setPreviewImage = (previewImage) => {
    formik.setFieldValue("preview_image", previewImage);
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);
      setUploadPicture(imageDataUrl);
      toggleModal(true);
      e.target.value = "";
    }
  };
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };
  return (
    <StyledDiv {...props}>
      <div className="settings__label">{localeSettingsLabels[locale]["add-artifact__link"]}</div>
      <Input className="settings__input" valueName="source_link" formik={formik} placeholder="" maxLength="150" />
      <Show when={!formik.values.preview_image}>
        <label htmlFor="load-other-upload">
          <input
            accept="image/*"
            id="load-other-upload"
            type="file"
            style={{ display: "none" }}
            onChange={onFileChange}
          />
          <UploadButton className="load__upload" text={localeButtonTexts[locale]["upload-image"]} />
        </label>
      </Show>
      <Show when={formik.values.preview_image}>
        <PreviewImage
          previewImage={formik.values.preview_image}
          handleDelete={deleteInfo}
          handleUpload={onFileChange}
          imageText="other screenshot"
          noneText={noneText}
          deleteConfirmText={OnConfirmMessages[locale].deleteImage}
          hideRefresh
        />
      </Show>
      <ModalOther
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        imageSrc={uploadPicture}
        setImageSrc={setUploadPicture}
        setPreviewImage={setPreviewImage}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .load__hint,
  .load__upload {
    margin-bottom: 30px;
  }

  .load__upload > .button__label {
    letter-spacing: 0.07em;
  }

  .load__arrow-hint {
    margin-bottom: 40px;
  }
`;

export default LoadOther;
