import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { AppContext } from "../AppContext";
import { Show } from "../../utils";
import "../../fonts.css";
import ProfileHeader from "./ProfileHeader";
import { device, ScreenSizes, urls } from "../../constants";
import BurgerMenuButton from "../UI/buttons/BurgerMenuButton";
import LinkButton from "../UI/buttons/LinkButton";
import LogoButton from "../UI/buttons/LogoButton";
import EnterButton from "../UI/buttons/EnterButton";
import LanguageSelect from "../UI/inputs/LanguageSelect";
import InterProvider from "../../Internationalization";


const Header = () => {
  const { user, mobileMenuVisible, toggleMobileMenu, information } = useContext(AppContext);
  const competition = information && information.competition;

  const toggleMenu = () => {
    window.innerWidth < ScreenSizes.tablet_landscape && toggleMobileMenu(!mobileMenuVisible);
  };

  return (
    <InterProvider id="header">
      <HeaderWrapper>
        <HeaderDiv>
          <BurgerMenuButton className="header__menu-button" onClick={() => toggleMenu()} />
          <div className="header__left-side">
            <LogoButton />
            <Show when={competition && competition.enabled}>
              <LinkButton simple className="header__left-side__button" url={competition && competition.url}>
                <FormattedMessage id="link__competition"/>
              </LinkButton>
            </Show>
            <LinkButton className="header__left-side__button" url={urls.product}><FormattedMessage id="link__product"/></LinkButton>
            <LinkButton className="header__left-side__button" url={urls.company}><FormattedMessage id="link__company"/></LinkButton>
          </div>
          <div className="header__right-side">
            <LanguageSelect className="header__right-side__lang-select" />
            <Show when={user}>
              <ProfileHeader />
            </Show>
            <Show when={!user}>
              <EnterButton />
            </Show>
          </div>
        </HeaderDiv>
      </HeaderWrapper>
    </InterProvider>
  );
};

const HeaderWrapper = styled.div`
height: 116px;
  @media ${device.tablet_portrait} {
    height: 100px;
    background-color: white;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 6;
  }
  @media ${device.phone_landscape} {
    height: 78px;
  }
`;

const HeaderDiv = styled.div`
  height: 100%;
  display: grid;
  margin: 0 138px;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;

  .header__left-side {
    display: flex;
    justify-self: start;
    align-items: center;

    & > * + * {
      margin-left: 60px;
    }
  }

  .header__right-side {
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .header__right-side__lang-select {
    margin-right: 37px;
  }

  .header__left-side__button {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    position: relative;
    color: ${(props) => props.theme.colorDark};
    &:hover {
      color: ${(props) => props.theme.colorMain};
    }
    &.active-state {
      color: ${(props) => props.theme.colorMain};
      &:after {
        position: absolute;
        display: block;
        width: 43px;
        content: "";
        height: 4px;
        background: ${(props) => props.theme.colorMain};
        bottom: 0;
        left: calc(50% - 21.5px);
      }
    }
  }

  .header__menu-button {
    display: none;
  }

  @media ${device.tablet_landscape} {
    margin-left: 24px;
    margin-right: 24px;

    .header__left-side > * + * {
      margin-left: 40px;
    }
  }
  @media ${device.tablet_portrait} {
    grid-template-columns: auto 1fr auto;

    .header__right-side__lang-select {
      display: none;
    }
    .header__menu-button {
      display: block;
    }

    .header__left-side {
      padding-left: 28px;
    }

    .header__left-side__button {
      display: none;
    }
  }
  @media ${device.phone_landscape} {
    .header__left-side {
      padding-left: 0;
      justify-self: center;
    }
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export default Header;
