import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { getApiUrl } from "../../utils";
import Profile from "../profile/Profile";

const OtherPage = ({ data }) => {
  const [contentData, setContentData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const request = await axios.get(getApiUrl(`other/${data}`));
        setContentData(request.data);
      } catch (e) {
        setContentData({});
       }
    };
    fetchData();
  }, [data]);

  if (contentData.page_type === 'profile') {
    return <Profile userId={contentData.user_id} />;
  }

  return (
    <StyledDiv>Page does not exist</StyledDiv>
  );
};

const StyledDiv = styled.div``;

export default OtherPage;
