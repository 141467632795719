import React, { useContext, useState } from "react";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { activeClass, addClass, Show } from "../../../utils";
import { device } from "../../../constants";
import { AppContext } from "../../AppContext";
import { localeErrorTexts, localePlaceholderTexts } from "../../../Internationalization";

const TagSelect = ({ valueName, formik, options, commonTags, errorCondition, className, placeholder }) => {
  const isError = !!(errorCondition || formik.touched[valueName] && formik.errors[valueName]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { locale } = useContext(AppContext);
  const getValue = () => formik.values[valueName].map((value) => ({ value, label: value }));
  const cName = addClass(className, "error", isError);

  const onChange = (option) => {
    formik.setFieldValue(
      valueName,
      option.map((item) => item.value)
    );
    setTimeout(() => {
      formik.setFieldTouched(valueName);
    }, 20);
  };

  const onBlur = (e) => {
    setTimeout(() => {
      formik.setFieldTouched(valueName);
      formik.handleBlur(e);
    }, 20);
  };

  const Input = (props) => <components.Input {...props} maxLength={25} />;

  const commonTagOnClick = (tag) => {
    const array = formik.values[valueName].map((value) => value);
    if (formik.values[valueName].includes(tag.value)) {
      const index = array.indexOf(tag.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(tag.value);
    }
    formik.setFieldValue(valueName, array);
    setTimeout(() => {
      formik.setFieldTouched(valueName);
    }, 20);
  };

  return (
    <StyledTagSelect
      className={cName}
      value={formik.values[valueName]}
      isMenuOpen={isMenuOpen}
      hideAfterFive
    >
      <CreatableSelect
        components={{ Input }}
        classNamePrefix="tag-select"
        className="tag-select"
        isClearable
        isMulti
        name={valueName}
        value={getValue()}
        onChange={onChange}
        placeholder={localePlaceholderTexts[locale][placeholder] || placeholder}
        options={options}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        onBlur={onBlur}
        defaultMenuIsOpen={false}
      />
      <Show when={isError} >
        <div className="tag-select__error-label">{localeErrorTexts[locale][formik.errors[valueName]] || formik.errors[valueName]}</div>
      </Show>
      <div className="tag-select__common-tags">
        {commonTags.map((value, index) => (
          <div
            className={activeClass("tag tag-select__common-tag", formik.values[valueName].includes(value.value))}
            key={index}
            onClick={() => commonTagOnClick(value)}
          >
            {value.value}
          </div>
        ))}
      </div>
    </StyledTagSelect>
  );
};

const StyledTagSelect = styled.div`
  width: 100%;
  input {
    -webkit-appearance: none;
  }

  .tag-select {
    .tag-select__placeholder {
      color: ${(props) => props.theme.colorFont};
    }

    .tag-select__single-value {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
    }

    .tag-select__control {
      min-height: 48px;
      height: fit-content;
      cursor: pointer;
      border: 2px transparent;
      box-shadow: 0 0 0 1px ${(props) => props.theme.colorGrey2};
      border-radius: 5px;
      margin-right: 0;
      .tag-select__indicators {
        .tag-select__indicator-separator {
          display: none;
        }
        .tag-select__dropdown-indicator {
          color: ${(props) => props.theme.colorDark};
          transform: scale(1, ${(props) => (props.isMenuOpen ? -1 : 1)});
        }
      }

      .tag-select__multi-value {
        background-color: ${(props) => props.theme.colorWhite};
        border: 1px solid ${(props) => props.theme.colorGrey2};

        border-radius: 50px;
        height: 31px;
        line-height: 31px;
        align-items: center;
        padding: 0px 5px 0px 5px;

        .tag-select__multi-value__label {
          padding-right: 0px;
          font-size: 12px;
          color: ${(props) => props.theme.colorDark};
        }
      }
    }

    .tag-select__control--is-focused {
      outline: none;
      box-shadow: 0 0 0 2px ${(props) => props.theme.colorMain};
      border-radius: 5px;
    }
  }

  .tag-select__common-tags {
    display: flex;
    height: 76px;
    flex-wrap: wrap;
    overflow: hidden;

    --gap: 9px;

    margin: calc(9px + (-1 * var(--gap))) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
    & > * {
      margin: var(--gap) 0 0 var(--gap);
    }

    .tag-select__common-tag {
      cursor: pointer;

      &.active-state {
        color: ${props => props.theme.colorWhite};
        background-color: ${props => props.theme.colorDark};
        border-color: transparent;
      }
    }
  }

  &.error {
    .tag-select {
      .tag-select__control {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};
      }

      .tag-select__control--is-focused {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colorRed};
      }
    }
  }

  .tag-select__menu {
    z-index: 5;
    width: fit-content;
    min-width: 100%;
    max-width: 500px;
    .tag-select__menu-list {
      min-width: 100%;
      max-width: 100%;
      width: max-content;
      display: flex;
      flex-direction: column;

      .tag-select__option {
        flex-shrink: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 12px;
        line-height: 40px;
        &:first-child {
          display: ${(props) => (props.hideFirst ? "none" : "flex")};
        }
        &:nth-child(n + 6) {
          display: ${(props) => (props.hideAfterFive ? "none" : "flex")};
        }
        &:last-child {
          display: flex;
        }
      }
    }
  }
  .tag-select__error-label {
    margin-top: 4px;
    color: ${(props) => props.theme.colorRed};
    font-size: 12px;
    line-height: 13px;
  }


  @media ${device.phone_landscape} {
    .tag-select__menu {
      max-width: 448px;
    }
  }

  @media ${device.phone_landscape} {
    .tag-select__menu {
      max-width: 288px;
    }
  }
`;

export default TagSelect;
