import React, { Fragment } from "react";
import styled from "styled-components";
import { device } from "../../../constants";

const ArrowHint = ({ elements, ...props }) => (
  <StyledDiv {...props}>
    {elements.map((value, index, array) => (
      index === array.length - 1
        ? <div className="hint__element" key={value}>{value}</div>
        : (
          <Fragment key={value}>
            <div className="hint__element">{value}</div>
            <div className="hint__arrow">→</div>
          </Fragment>
        )
    ))}
  </StyledDiv>
);
const StyledDiv = styled.div`
  display: flex;

  & > * + * {
    margin: 0 0 0 10px;
  }

  .hint__arrow {
    font-size: 16px;
    line-height: 30px;
    width: fit-content;
    align-self: center;
  }

  .hint__element {
    background: #DFE9FF;
    border-radius: 50px;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    color: ${(props) => props.theme.colorMain};
    padding: 2.5px 10px;
    width: fit-content;
  }

  @media ${device.tablet_landscape} {
    margin-bottom: 30px;
  }

  @media ${device.phone_landscape} {
    & > * + * {
      margin: 0 0 0 0;
    }
    justify-content: space-between;
  }

  @media ${device.phone_portrait} {
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin: 5px 0 0 0;
    }

    margin-bottom: 20px;
    .hint__arrow {
      transform: rotate(90deg);
    }

  }
`;

export default ArrowHint;
