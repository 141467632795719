import React from "react";
import styled from "styled-components";
import BigCross from "../SVGs/BigCross";
import SmallCross from "../SVGs/SmallCross";

const CrossButton = ({ small, className, ...props }) => (
  <StyledDiv className={`${small && "small"} ${className}`} {...props}>
    {small ? <SmallCross/> : <BigCross />}
  </StyledDiv>
);

const StyledDiv = styled.div`
  cursor: pointer;
  stroke: ${props => props.color || props.theme.colorWhite};
  width: ${props => props.width || "54px"};
  height: ${props => props.height || "54px"};

  &.small {
    stroke: ${props => props.color || props.theme.colorDark};
    width: ${props => props.width || "24px"};
    height: ${props => props.height || "24px"};
  }
`;

export default CrossButton;
