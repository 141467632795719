import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { AppContext } from "../AppContext";
import { CustomTextarea } from "../settings/CustomFields";
import { device, fullNameRegExp, mailRegExp } from "../../constants";
import { getApiUrl, getCSRF, logging } from "../../utils";
import Input from "../UI/inputs/Input";
import SubmitButton from "../UI/buttons/SubmitButton";
import { localeButtonTexts } from "../../Internationalization";

const LeaveRequest = ({ inputRef }) => (
  <LeaveRequestDiv>
    <div className="for-company__block-title"><FormattedMessage id="leave-request__title" /></div>
    <div className="leave-request__row">
      <RequestFormComponent inputRef={inputRef} />
    </div>
  </LeaveRequestDiv>
);

const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required("required-name").matches(fullNameRegExp, "for-company__name-validation"),
  email: Yup.string().trim().required("required-email").matches(mailRegExp, "for-company__email-validation"),
  telegramLink: Yup.string(),
});

const RequestFormComponent = ({ inputRef }) => {
  const { showSuccessModal, locale } = useContext(AppContext);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      telegramLink: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      values = ValidationSchema.cast(values);
      const res = await axios.post(getApiUrl("add_company_request"), values, {
        headers: getCSRF(),
      });
      if (res.status === 200) {
        window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', 'company-send-request');
        showSuccessModal(res.data.result_text, res.data.success);
      }
      logging(res);
    },
  });
  return (
    <FormikProvider value={formik}>
      <RequestForm onSubmit={formik.handleSubmit}>
        <Input className="request-form__input" valueName="name" formik={formik} placeholder="for-company__input__name" maxLength="50" innerRef={inputRef} />
        <Input className="request-form__input" valueName="email" formik={formik} placeholder="for-company__input__email" maxLength="50" />
        <Input className="request-form__input" valueName="telegramLink" formik={formik} placeholder="for-company__input__telegram" maxLength="50" />
        <SubmitButton className="request-form__btn" text={localeButtonTexts[locale]["send-request"]} />
        <div className="request-form__politics">
          <FormattedMessage
            id="leave-request__politics-text"
            values={{
              a: (...chunks) => <a href={`${process.env.PUBLIC_URL}/confidence.pdf`} className="link" target="_blank" rel="noreferrer">{chunks}</a>,
            }}
          />
        </div>
      </RequestForm>
    </FormikProvider>
  );
};

const LeaveRequestDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;

  .leave-request__row {
    display: flex;
    justify-content: space-between;
  }

  @media ${device.tablet_landscape} {
    margin-bottom: 100px;
    .leave-request__row {
      flex-direction: column;
    }
  }
  @media ${device.tablet_portrait} {
    margin-bottom: 80px;
  }
  @media ${device.phone_landscape} {
    margin-bottom: 0;
  }
`;

const RequestForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.theme.colorGrey};
  --indent-left: 83px;
  --indent-right: 155px;
  padding: 72px var(--indent-right) 53px var(--indent-left);
  width: calc(809px - var(--indent-right) - var(--indent-left));
  flex-shrink: 0;
  & > * + * {
    margin-top: 10px;
  }
  border-radius: 5px;

  .link {
    letter-spacing: 0.08em;
  }

  .request-form__politics {
    font-size: 14px;
    color: ${(props) => props.theme.colorFont};
    letter-spacing: 0.08em;
    line-height: 30px;
  }

  .request-form__btn {
    margin-top: 20px;
  }

  .request-form__input {
    .input__field-wrapper {
      background-color: ${props => props.theme.colorWhite};
    }
    input {
      box-shadow: 0 0 0 1px ${props => props.theme.colorWhite};
    }
  }


  @media ${device.tablet_portrait} {
    --indent: 20px;
    width: calc(100% - 2*var(--indent));
    max-width: calc(592px - 2*var(--indent));
    padding: 55px var(--indent) 58px var(--indent);
  }

  @media ${device.phone_landscape} {
    --indent: 24px;
    padding-top: 30px;
    padding-bottom: 60px;
    margin-left: calc(-1 * var(--indent));
    margin-right: calc(-1 * var(--indent));
    width: 100%;
    border-radius: 0;
    align-self: center;

    .request-form__btn {
      align-self: center;
    }
    .request-form__politics {
      text-align: center;
    }
  }

  @media ${device.phone_portrait} {
    --indent: 16px;
    padding-top: 22px;
    padding-bottom: 27px;
  }
`;

export default LeaveRequest;
