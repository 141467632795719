import { A } from "hookrouter";
import React, { useContext } from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import { localeProfileLabels } from "../../../Internationalization";
import { SettingsItems } from "../../../Routes";
import { getYear, Show } from "../../../utils";
import { AppContext } from "../../AppContext";
import EditButton from "../../UI/buttons/rounded/RoundedEditButton";

const WorkExperience = ({ profileData, isOwner }) => {
  const { locale } = useContext(AppContext);
  if (!profileData.works || !profileData.works.constructor === Array) {
    return null;
  }
  const works = profileData.works;
  const emptyExperience = !(works[0].date_start || works[1].date_start || works[2].date_start);
  return (
    <StyledDiv>
      <Show when={isOwner}>
        <A href={`/settings/${SettingsItems.workExperience}`}>
          <EditButton className="profile__right-side__edit-button" />
        </A>
      </Show>
      {works.map((work) => work.date_start ? <Work work={work} key={work.id} locale={locale} /> : null)}
      <Show when={emptyExperience}>Не заполнено</Show>
    </StyledDiv>
  );
};

const Work = ({ work, locale }) => (
  <div className="works__work">
    <div className="work__dates">{getYear(work.date_start)} - {work.date_now ? localeProfileLabels[locale]["work-now"] : getYear(work.date_end)}</div>
    <div className="work__company">{work.company}</div>
    <div className="work__post">{work.post}</div>
    <div className="work__achievements">{work.achievements}</div>
  </div>
);

const StyledDiv = styled.div`
  position: relative;
  margin-right: 124px;
  .works__work {
    margin-bottom: 60px;
    :last-child {
      margin-bottom: 0px;
    }

    div {
      line-height: 30px;
      margin-bottom: 20px;

      :last-child {
        margin-bottom: 0px;
      }
    }

    .work__company {
      font-weight: bold;
      font-size: 20px;
    }
    .work__dates,
    .work__achievements,
    .work__post {
      font-size: 18px;
    }

    .work__achievements {
      white-space: pre-wrap;
    }
  }
  @media ${device.desktop} {
    margin-right: 28px;
  }

  @media ${device.tablet_landscape} {
    margin-right: 0px;
  }

  @media ${device.phone_landscape} {
    .works__work {
      margin-bottom: 40px;
      div {
        margin-bottom: 10px;
      }
      .work__company {
        font-size: 18px;
      }
      .work__dates,
      .work__achievements,
      .work__post {
        font-size: 16px;
      }
    }
  }
`;


export default WorkExperience;
