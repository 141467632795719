import { A } from "hookrouter";
import React from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import logo from "../../../images/logo.svg";

const LogoButton = () => (
  <StyledButton href="/">
    <img src={logo} alt="Underdoc" />
  </StyledButton>
);

const StyledButton = styled(A)`
  img {
    height: 16px;
    width: 133px;
  }

  @media ${device.phone_portrait} {
    img {
      height: 12px;
      width: 99px;
    }
  }
`;

export default LogoButton;
