import { createGlobalStyle } from "styled-components";
import { device } from "./constants";
import "./fonts.css";
import theme from "./Theme";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: ${theme.fontFamily};
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1em;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    padding: 0 35px 0 20px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;

  }
  select::-ms-expand {
    display: none;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  p:not(:first-child) {
    margin-top: 8px !important;
  }

  strong,
  b {
    font-weight: bold;
  }

  i,
  cite,
  em,
  var,
  address,
  dfn {
    font-style: italic;
  }

  u,
  ins {
    text-decoration: underline;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body,
  button {
    font-family: ${theme.fontFamily}, normal;
  }

  html,
  body,
  #root {
    display: block;
    height: 100%;
    margin: 0;
    scroll-behavior: smooth;
  }

  body {
    line-height: 1;
    background: #fff;
    color: ${theme.colorDark};
    font-family: 'Lato', normal;
  }
  @-webkit-keyframes alertPulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

  ul, ol {
    list-style: none;
  }

  .pulsating {
    animation: alertPulse 1s ease-out;
    animation-iteration-count: infinite;
  }

  div[data-slate-editor="true"] {
    min-width: 150px;
  }

  #root {
    margin: auto;
    border: 0;
    padding: 0;
    min-height: 100%;
  }

  body {
    @media ${device.tablet_portrait} {
      padding-top: 100px;
    }
    @media ${device.phone_landscape} {
      padding-top: 78px;
    }
  }

  body.mobile-menu-open {
    @media ${device.tablet_portrait} {
      position: fixed;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
  }

  .flex {
    display: flex;

    &.column {
    flex-direction: column;
    }
    &.center {
      align-items: center;
    }
  }
  .d-none {
    display: none;
  }
  a {
    color: ${theme.colorDark};
    text-transform: none;
    text-decoration: none;
    &:hover,
    &:link,
    &:active,
    &:visited {
      color: ${theme.colorDark};
      text-transform: none;
      text-decoration: none;
    }
  }

  .link {
    white-space: nowrap;
    &:hover,
    &:link,
    &:active,
    &:visited {
      color: ${theme.colorMain};
    }
  }

  .button {
    cursor: pointer;
    background: ${theme.colorMain};
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: ${theme.colorWhite};

    :disabled {
      background-color: ${theme.colorGrey2};
    }
  }
`;
