import React, { useContext } from "react";
import styled from "styled-components";
import { FormikProvider, useFormik } from "formik";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { AppContext } from "../../AppContext";
import { getApiUrl, getCSRF, getYear, logging, Show } from "../../../utils";
import { EducationLevels, OnConfirmMessages, DefaultStartYear, device } from "../../../constants";
import SubmitButton from "../../UI/buttons/SubmitButton";
import Select from "../../UI/inputs/Select";
import Input from "../../UI/inputs/Input";
import DeleteButton from "../../UI/buttons/rounded/DeleteButton";
import Hint from "../../UI/other/Hint";
import { SettingsTitles } from "../../../Routes";
import { localeSettingsLabels } from "../../../Internationalization";

const ValidationSchema = Yup.object().shape({
  educationLevel: Yup.mixed().required("required-field"),
  institution: Yup.string().trim().required("required-field"),
  faculty: Yup.string().trim().required("required-field"),
  specialty: Yup.string().trim(),
  yearEnd: Yup.mixed().required("required-field"),
});

const Education = ({ action }) => {
  const { ownProfile, setOwnProfile, showSuccessModal, locale } = useContext(AppContext);
  const startYear = DefaultStartYear;
  const endYear = new Date().getFullYear() + 5;
  const countYear = endYear - startYear + 1;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      educationLevel: ownProfile && ownProfile.education.level ? ownProfile.education.level : null,
      institution: ownProfile ? ownProfile.education.institution : "",
      faculty: ownProfile ? ownProfile.education.faculty : "",
      specialty: ownProfile ? ownProfile.education.specialty : "",
      yearEnd: ownProfile && ownProfile.education.date_end ? getYear(ownProfile.education.date_end) : null,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      values = ValidationSchema.cast(values);
      const res = await axios.post(getApiUrl(`settings/${action}`), values, {
        headers: getCSRF(),
      });
      if (res.status === 200) {
        setOwnProfile(res.data);
        showSuccessModal();
      }
      logging(res);
    },
  });
  const yearOptions = Array.from(Array(countYear), (e, i) => ({
    key: (endYear - i).toString(),
    value: (endYear - i).toString(),
    label: (endYear - i).toString(),
  }));

  const educationOptions = EducationLevels.map((value) => ({ value, key: value, label: value }));

  const clearEducation = async () => {
    const res = await axios.post(
      getApiUrl(`settings/${action}__clear`),
      {},
      { headers: getCSRF() }
    );
    logging(res);
    if (res.status === 200) {
      setOwnProfile(res.data);
      return true;
    }
    return false;
  };
  return (
    <FormikProvider value={formik}>
      <EducationDiv>
        <div className="settings__header">{SettingsTitles[locale][action]}</div>
        <Hint className="settings__hint"><FormattedMessage id="hint" /></Hint>
        <EducationForm className="settings__form" onSubmit={formik.handleSubmit}>
          <div className="settings__label">{localeSettingsLabels[locale]["education__level"]}</div>
          <Select className="settings__select" valueName="educationLevel"
            formik={formik} options={educationOptions} placeholder="education__level" disableSearch />
          <div className="settings__label">{localeSettingsLabels[locale]["education__institution"]}</div>
          <Input className="settings__input" valueName="institution" formik={formik} placeholder="education__institution" maxLength="100" />
          <div className="settings__label">{localeSettingsLabels[locale]["education__faculty"]}</div>
          <Input className="settings__input" valueName="faculty" formik={formik} maxLength="50" />
          <div className="settings__label">{localeSettingsLabels[locale]["education__specialty"]}</div>
          <Input className="settings__input" valueName="specialty" formik={formik} maxLength="50" />
          <div className="settings__label">{localeSettingsLabels[locale]["education__date-end"]}</div>
          <div className="settings__date">
            <Select className="settings__select settings__select-year"
              valueName="yearEnd" formik={formik} options={yearOptions}
              placeholder="date-year" disableSearch />
            <div className="date-label"><FormattedMessage id="date-label" /></div>
          </div>
          <div className="settings__btns">
            <SubmitButton className="settings__submit" />
            <Show when={ownProfile.education.institution}>
              <DeleteButton className="settings__btn-delete" onClick={clearEducation} text={OnConfirmMessages[locale].clearEducation} />
            </Show>
          </div>
        </EducationForm>
      </EducationDiv>
    </FormikProvider>
  );
};

const EducationDiv = styled.div`
  display: flex;
  flex-direction: column;

  .settings__header {
    margin-bottom: 24px;
  }

  .settings__hint {
    margin-bottom: 30px;
  }
`;

const EducationForm = styled.form`

  .settings__select {
    width: 132px;
    flex-shrink: 0;
  }

  .settings__select-year {
    margin-bottom: 0px;
  }

  .settings__date {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .date-label {
    font-size: 14px;
    line-height: 30px;
    margin-left: 17px;
    color: ${(props) => props.theme.colorDark};
  }

  .select-wrapper {
    width: 371px;
  }

  @media ${device.phone_landscape} {
    .date-label {
      font-size: 12px;
    }

    .settings__btns {
      grid-gap: 0px;
    }

    .settings__submit {
      margin-top: 30px;
    }

    .settings__btn-delete {
      margin-top: 10px;
    }
  }

  @media ${device.phone_portrait} {
    .settings__date {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0px;
    }
    .date-label {
      margin-left: 0;
      margin-bottom: 8px;
      order: -1;
      font-size: 12px;
    }
  }
`;

export default Education;
