import React from "react";

const useLocalStorageState = ({ key, value }) => {
  const parsedLocalStorage = React.useMemo(() => JSON.parse(localStorage.getItem(key) || '{}'), [key]);
  const initialValue = React.useMemo(() => {
    if (Object.keys(parsedLocalStorage).length === 0) {
      return value;
    }
    const result = { ...parsedLocalStorage };
    Object.keys(value).filter(val => !(val in result)).forEach(valKey => result[valKey] = value[valKey]);
    Object.keys(result).filter(val => !(val in value)).forEach(resKey => delete result[resKey]);

    return result;
    // return Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : value;
  }, [value, parsedLocalStorage]);
  const [localStorageState, setLocalStorageState] = React.useState(initialValue);
  const handleUpdateLocalStorageState = React.useCallback((x) => {
    setLocalStorageState(x);
    localStorage.setItem(key, JSON.stringify(x));
  },
    [key]
  );
  return [localStorageState, handleUpdateLocalStorageState];
};

export default useLocalStorageState;
