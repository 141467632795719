export const DefaultStartYear = 1960;

export const Months = {
  "en": {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  },
  "ru": {
    "01": "Январь",
    "02": "Февраль",
    "03": "Март",
    "04": "Апрель",
    "05": "Май",
    "06": "Июнь",
    "07": "Июль",
    "08": "Август",
    "09": "Сентябрь",
    "10": "Октябрь",
    "11": "Ноябрь",
    "12": "Декабрь",
  }
};

export const EducationLevels = [
    "Bachelor",
    "Master",
    "Associate",
    "MBA",
    "Ph.D.",
    "High School Diploma",
    "Some College (no degree)",
    "Undergraduate",
  ];

  // "Среднее профессиональное образование",
  // "Неоконченное высшее",
  // "Высшее",
  // "Бакалавр",
  // "Магистр",
  // "Специалист",
  // "Аспирант",
  // "Кандидат наук",
  // "Доктор наук",


export const OnConfirmMessages = {
  "en": {
    clearEducation: "Are you sure you want to delete Education info?",
    clearWork: (index) => `Are you sure you want to delete «${index}»?`,
    deleteAccount: "Are you sure you want\xa0to delete your\xa0account?",
    deleteArtifact: (title) => `Are you sure you want to delete ${title}?`,
    exit: "Are you sure you want to sign out?",
    deleteLink: "Are you sure you want to delete embedded link?",
    deleteImage: "Are you sure to delete this preview image?",
  },
  "ru": {
    clearEducation: "Удалить данные по Образованию?",
    clearWork: (index) => `Удалить данные «${index}»?`,
    deleteAccount: "Вы уверены, что хотите удалить свой\xa0профиль?",
    deleteArtifact: (title) => `Удалить ${title}?`,
    exit: "Вы уверены, что хотите выйти?",
    deleteLink: "Удалить ссылку на работу?",
    deleteImage: "Вы уверены, что хотите удалить превью работы?",
  },
};

export const successfulMessages = {
  "en": {
    unknownError: "Unknown error",
  },
  "ru": {
    unknownError: "Неизвестная ошибка",
  },
};

export const ScreenSizes = {
  phone_portrait: 320,
  phone_landscape: 480,
  tablet_portrait: 640,
  tablet_landscape: 960,
  desktop: 1280,
  xxl: 1440,
};

export const device = {
  micromachines: `(max-width: ${ScreenSizes.phone_portrait - 1}px)`,
  phone_portrait: `(max-width: ${ScreenSizes.phone_landscape - 1}px)`,
  phone_landscape: `(max-width: ${ScreenSizes.tablet_portrait - 1}px)`,
  tablet_portrait: `(max-width: ${ScreenSizes.tablet_landscape - 1}px)`,
  tablet_landscape: `(max-width: ${ScreenSizes.desktop - 1}px)`,
  desktop: `(max-width: ${ScreenSizes.xxl - 1}px)`,
  upper_phone_landscape: `(min-width: ${ScreenSizes.tablet_portrait}px)`,
};


export const links = {
  competition: "https://underdoc.notion.site/underdoc/MakeSense-Underdoc-01cd0c2a5d224fc4b8cce417fb323a92",
};
//  export const mailRegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const mailRegExp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const nameRegExp = /^([A-ZА-Я][a-zа-я]*)(?:( |-)[A-ZА-Яa-zа-я]+){0,3}$/;
export const fullNameRegExp = /^([A-ZА-Я][a-zа-я]*)(?:( |-)[A-ZА-Яa-zа-я]+){0,6}$/;
export const lastNameRegExp = /^([A-ZА-Я][a-zа-я]*)(|(-| )[A-ZА-Яa-zа-я]*)$/;
export const fbRegExp = /^(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i;
export const liRegExp = /^(?:https?:\/\/)?(?:www\.)?(?:linkedin)\.(?:com)\/(?:in)\/(?:\w)*/i;
export const notionRegExp = /^(https:\/\/)([-A-Za-z0-9]*)(.notion.site\/)([-A-Za-z0-9\?=_]*)$/;

export const regulars = {
  usernameRegExp: /^([a-z][a-z0-9]*)(?:(\.|-|_)[a-z0-9]+){0,2}$/,
  startWithLetter: /^[a-z](.*$)/,
  endWithoutSymbol: /^(.*)[a-z0-9]$/,
};

export const urls = {
  company: "/forcompany",
  settings: "/settings",
  product: "/forproduct",
};
export const ArtifactTypes = {
  CJM: "CJM",
  PR: "PR",
  OTHER: "Other",
};

export const PlatformTypes = {
  Miro: 'Miro',
  Notion: 'Notion',
  Other: 'Other',
};

export const SalaryTypes = {
  Dollar: "$/hour",
  Ruble: "руб/мес"
};

export const localeDefaultTexts = {
  "en": {
    "success-modal__text": "Success!",
    "success-modal__failed-text": "Error",
    "confirm-modal__confirm-button-text": "Yes",
    "confirm-modal__cancel-button-text": "Cancel",
  },
  "ru": {
    "success-modal__text": "Данные сохранены",
    "success-modal__failed-text": "Ошибка",
    "confirm-modal__confirm-button-text": "Да",
    "confirm-modal__cancel-button-text": "Отмена",
  },
};
