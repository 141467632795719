import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import { device, urls } from "../../constants";
import ForCompanyHeader from "./HeaderBlock";
import OurOffer from "./OurOfferBlock";
import HowSelected from "./HowSelectedBlock";
import LeaveRequest from "./LeaveRequestBlock";
import InterProvider from "../../Internationalization";

const ForCompany = () => {
  const { setUrlState } = useContext(AppContext);
  const inputRef = useRef(null);

  useEffect(() => {
    setUrlState(urls.company);
    return () => {
      setUrlState(null);
    };
  }, [setUrlState]);

  return (
    <InterProvider id="forcompany">
      <ForCompanyDiv>
        <ForCompanyHeader inputRef={inputRef} />
        <OurOffer />
        <HowSelected />
        <LeaveRequest inputRef={inputRef} />
      </ForCompanyDiv>
    </InterProvider>
  );
};

const ForCompanyDiv = styled.div`
  background: ${props => props.theme.colorLinearBG};
  padding: 37px 137px 0px 137px;
  display: flex;
  flex-direction: column;
  min-width: calc(320px - 32px);
  .for-company__block-title {
    color: ${(props) => props.theme.colorDark};
    font-size: 34px;
    font-weight: 900;
    line-height: 70px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 50px;
  }

  @media ${device.tablet_landscape} {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media ${device.tablet_portrait} {
    .for-company__block-title {
      font-size: 24px;
      margin-bottom: 40px;
      line-height: 50px;
    }
  }
  @media ${device.phone_portrait} {
    padding-left: 16px;
    padding-right: 16px;
    .for-company__block-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
`;

export default ForCompany;
