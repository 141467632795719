import React, { useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { A, navigate } from "hookrouter";
import { SettingsItems } from "../../Routes";
import backgroundImage from "../../images/placeholder_cjm.png";
import { getApiUrl, getCSRF, getMediaUrl, getRelativeDate, logging, Show } from "../../utils";
import { device, OnConfirmMessages, SalaryTypes } from "../../constants";
import EditButton from "../UI/buttons/rounded/RoundedEditButton";
import Likes from "../Likes";
import DeleteButton from "../UI/buttons/rounded/DeleteButton";
import RoundedAddButton from "../UI/buttons/rounded/RoundedAddButton";
import { AppContext } from "../AppContext";
import InterProvider, { localeLinksTexts } from "../../Internationalization";
import AddButton from "../UI/buttons/AddButton";
import LinkSVG from "../UI/SVGs/Link";

const CertificateCards = (props) => {
  const { cards, addCard, isOwner, noneText, className } = props;
  return (
    <StyledCards className={className}>
      <Show when={addCard && isOwner}>
        <AddCard />
      </Show>
      <InterProvider id="certificate">
        {cards && cards.map((card, index) => (
          <Card card={card} key={index} cardIndex={index} isOwner={isOwner} />
        ))}
      </InterProvider>
      <Show when={cards && cards.length === 0}>
        <div className="cards__none">{noneText}</div>
      </Show>
    </StyledCards>
  );
};

const AddCard = () => {
  const AddCJM = () => (navigate(`/settings/${SettingsItems.addCertificate}`));
  return (
    <>
      <AddButton className="cards__add-button" onClick={AddCJM} text="Добавить сертификат" />
      <EmptyCard className="cards__empty-card" AddArtifact={AddCJM} />
    </>
  );
};

const EmptyCard = ({ AddArtifact, ...props }) => (
  <StyledEmptyCard {...props}>
    <div className="empty-card__giglet" />
    <div className="empty-card__button">
      <RoundedAddButton onClick={AddArtifact} />
    </div>
  </StyledEmptyCard>
);

const Card = (props) => {
  const { card, cardIndex, isOwner } = props;
  const { locale } = useContext(AppContext);
  const deleteArtifact = async () => {
    const res = await axios.post(
      getApiUrl("settings/artifact__delete"),
      { id: card.id },
      {
        headers: getCSRF(),
      }
    );
    if (res.status === 200) {
      window.location.reload();
    }
    logging(res);
  };

  // const editArtifact = () => (navigate(`/settings/${SettingsItems[`change${card.type}`]}/${cardIndex}`));

  return (
    <StyledCard>
      <StyledCardMain rate={card.rate}>
        <div className="card-main__top">
          <div className="card-main__year">{card.year}</div>
          <div className="card-main__title">{card.title}</div>
          <StyledCardImage imgpath={card.certificate_image && getMediaUrl(`${card.certificate_image}`)} />
        </div>
        <div className="card-main__bottom">
        <a className="card-main__link" href={card.source_link} target="_blank" rel="noreferrer">
          <div className="card-main__link-text">{localeLinksTexts[locale]["open-by-link"]}</div>
          <div className="card-main__link-svg">
            <LinkSVG/>
          </div>
        </a>
        <div className="card-main__organization">{card.organization}</div>
          {/* <Show when={isOwner}>
            <div className="card-main__delete-button">
              <DeleteButton onClick={deleteArtifact} text={OnConfirmMessages[locale].deleteArtifact(card.title)} />
            </div>
            <div className="card-main__edit-button">
              <EditButton onClick={(e) => editArtifact(e)} />
            </div>
          </Show> */}
        </div>
      </StyledCardMain>
    </StyledCard>
  );
};

const StyledCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
  justify-content: space-between;
  grid-gap: 80px;

  .cards__none {
    grid-column: 1 / -1;
    justify-self: center;
    font-size: 16px;
    line-height: 30px;
    color: ${(props) => props.theme.colorFont};
    text-align: center;
    width: calc(100% - 56px);
  }

  .cards__add-button {
    grid-column: 1 / -1;
    display: none;
  }

  @media ${device.desktop} {
    grid-gap: 40px;
  }

  @media ${device.tablet_landscape} {
    grid-gap: 27px;
  }
  @media ${device.tablet_portrait} {
    grid-gap: 20px;
    .cards__empty-card {
      display: none;
    }
    .cards__add-button {
      display: block;
    }
  }
  @media ${device.phone_landscape} {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    .cards__add-button {
      justify-self: center;
    }
  }
  @media ${device.phone_portrait} {
    .cards__none {
      width: 240px;
    }
  }
`;

const StyledEmptyCard = styled.div`
  border: 1px dashed #BFC0C1;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  padding: 88px 10px 84px;
  .empty-card__button {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .empty-card__giglet {
    height: 0;
    padding-bottom: 60%;
  }
`;

const StyledCard = styled.div`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
  & > * + * {
    margin-bottom: 1px;
  }
`;

const StyledCardMain = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.colorGrey};
  border-radius: 5px;
  background-color: ${props => props.theme.colorWhite};

  .card-main__top {
    padding: 38px 10px 0px;
  }

  .card-main__bottom {
    padding: 8px 10px 16px;
  }

  .card-main__year {
    --height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: var(--height);
    border-radius: 0px 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.colorDark};
    color: ${props => props.theme.colorWhite};
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    line-height: var(--height);
  }

  .card-main__title {
    --height: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: var(--height);
    height: calc(2*var(--height));
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .card-main__link {
    display: flex;
    & > * + * {
      margin-left: 8px;
    }
  }

  .card-main__link-text {
    font-size: 12px;
    line-height: 30px;
    color: ${(props) => props.theme.colorMain};
    white-space: nowrap;
  }

  .card-main__link-svg {
    align-self: center;
    stroke: ${(props) => props.theme.colorMain};
    stroke-width: 1.5px;
  }

  .card-main__organization {
    font-weight: bold;
    line-height: 15px;
    font-size: 12px;
    color: ${(props) => props.theme.colorFont};
    margin-top: 23px;
  }

  .card-main__delete-button,
  .card-main__edit-button {
    position: absolute;
    bottom: 10px;
    right: 16px;
  }

  .card-main__edit-button {
    right: 69px;
  }
`;

const StyledCardImage = styled.div`
  border-radius: 5px;
  height: 0;
  padding-bottom: 60%;
  background-size: cover;
  /* background-image: url(${(props) => props.imgpath}),  url(${backgroundImage}); */
  background-image: url(${(props) => props.imgpath});
  border: 1.2px solid ${props => props.theme.colorDark};
`;

export default CertificateCards;
