import React from "react";
import { A } from "hookrouter";
import styled from "styled-components";
import { Show } from "../../../utils";
import LinkButton from "../../UI/buttons/LinkButton";
import RoundedButton from "../../UI/buttons/rounded/RoundedButton";
import EditButton from "../../UI/buttons/rounded/RoundedEditButton";
import Facebook from "../../UI/SVGs/rounded/Facebook";
import LinkedIn from "../../UI/SVGs/rounded/LinkedIn";
import { SettingsItems } from "../../../Routes";
import { device } from "../../../constants";

const DescriptionData = ({ profileData, className, isOwner }) => (
  <StyledDiv className={className}>
    <div className="description-data__portrait">
      {profileData.portrait}
    </div>
    <ul className="description-data__buttons">
      <SocialButton link={profileData.fb_link} svg={<Facebook />} />
      <SocialButton link={profileData.li_link} svg={<LinkedIn />} />
    </ul>
    <Show when={isOwner}>
      <A href={`/settings/${SettingsItems.description}`}>
        <EditButton className="profile__left-side__edit-button" />
      </A>
    </Show>
  </StyledDiv>
);

const SocialButton = ({ link, svg }) => (
  <Show when={link}>
    <li className="description-data__button">
      <LinkButton simple url={link}>
        <RoundedButton>
          {svg}
        </RoundedButton>
      </LinkButton>
    </li>
  </Show>
);

const StyledDiv = styled.div`
  padding: 20px 50px 53px 38px;
  background-color: ${props => props.theme.colorWhite};
  border: 1px solid ${props => props.theme.colorGrey};
  border-radius: 5px;
  position: relative;

  .description-data__portrait {
    overflow-wrap: break-word;
    line-height: 30px;
  }
  .description-data__buttons {
    margin-top: 30px;
    display: flex;
  }
  .description-data__button {
    margin: 0 17.5px;
    .rounded-button {
      background-color: ${(props) => props.theme.colorDark};
    }
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }

  @media ${device.tablet_portrait} {
    padding: 18px 58px 30px 20px;
  }
  @media ${device.phone_landscape} {
    padding-right: 50px;
  }
  @media ${device.phone_portrait} {
    padding-bottom: 20px;
    padding-left: 12px;
    .description-data__portrait {
      font-size: 14px;
    }
    .description-data__button {
      margin: 0 10px;
      .rounded-button {
        width: 24px;
        height: 24px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export default DescriptionData;
