import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import styled, { useTheme } from "styled-components";
import { AppContext } from "../../AppContext";
import ProfileImage from "../../UI/other/ProfileImage";
import EditButton from "../../UI/buttons/rounded/RoundedEditButton";

const Avatar = (props) => {

  const { setNewPicture, toggleChangePictureModal, ownProfile } = useContext(AppContext);

  const changePicture = async (e) => {
    const picture = e.target.files[0];
    setNewPicture(picture);
    e.target.value = "";
    toggleChangePictureModal(true);
  };

  const theme = useTheme();

  return (
    <StyledAvatar {...props}>
      <div className="avatar__image">
        <ProfileImage picture={ownProfile.picture} />
        <label htmlFor="icon-button-file">
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => changePicture(e)}
          />
          <div className="btn-edit">
            <EditButton />
          </div>
        </label>
      </div>
      <div className="avatar__text"><FormattedMessage id="avatar-text" /></div>
    </StyledAvatar>
  );
};

const StyledAvatar = styled.div`
  display: flex;

  .avatar__image {
    margin-right: 19px;
    width: 98px;
    height: 98px;
    position: relative;
    flex-shrink: 0;
    .btn-edit {
      position: absolute;
      width: 35px;
      height: 35px;
      top: -1px;
      right: -7px;
    }
  }

  .avatar__text {
    font-size: 14px;
    line-height: 30px;
    display: flex;
    color: ${(props) => props.theme.colorDark};
    align-self: flex-end;
  }
`;

export default Avatar;
