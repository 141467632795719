import { Field } from "formik";
import React from "react";
import styled from "styled-components";

const Checkbox = ({ className, valueName, label, gap="19px" }) => (
  <StyledCheckbox gap={gap} className={className}>
    <Field className="checkbox__input" type="checkbox" name={valueName} id={valueName} />
    <label className="checkbox__label" htmlFor={valueName}>{label}</label>
  </StyledCheckbox>
);

const StyledCheckbox = styled.div`
  display: flex;

  .checkbox__input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &+label {
      display: flex;
      align-items: center;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
    }

    &+label::before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: ${props => props.gap};
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      border: 1px solid ${props => props.theme.colorGrey2};
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04));
      border-radius: 4px;
    }

    &:checked+label::before {
      background-color: ${props => props.theme.colorMain};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }

  .checkbox__label {
    font-size: 14px;
    line-height: 30px;
    color: ${props => props.theme.colorDark};
  }
`;

export default Checkbox;
