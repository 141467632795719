import React from "react";
import styled, { useTheme } from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import Beatris from "../../images/Beatris.JPG";
import { device } from "../../constants";

const Quotation = () => {
  const intl = useIntl();
  return (
    <QuotationDiv>
      <div className="quotation__image">
        <img src={Beatris} width="100%" height="100%" alt="Beautiful Beatris" />
      </div>
      <Quote authorsName={intl.formatMessage({id: "quotation__name"})} authorsPost={intl.formatMessage({id: "quotation__post"})}>
        <FormattedMessage
          id="quotation__quote"
          defaultMessage=""
        />
      </Quote>
    </QuotationDiv>
  );
};
const Quote = (props) => {
  const { authorsName, authorsPost, children } = props;
  const theme = useTheme();
  return (
    <QuoteDiv>
      <div className="quote__tail">
        <svg width="63" height="48" viewBox="0 0 63 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.8147e-06 46.9125C31.2338 52.1799 55.0141 37.0362 63 28.806L62.1127 4.11514C53.5352 5.76119 34.9606 7.24264 29.2817 0C30.169 29.629 6.21127 44.4435 3.8147e-06 46.9125Z"
            fill="#E9ECFA"
          />
        </svg>
      </div>
      <div className="quote__author">
        <div className="quote__name">{authorsName.concat(",\u00A0")}</div>
        <div className="quote__post">{authorsPost}</div>
      </div>
      <div className="quote__text">{children}</div>
    </QuoteDiv>
  );
};
const QuoteDiv = styled.div`
  position: relative;
  width: 100%;
  max-width: 715px;
    border-radius: 20px 20px 20px 0px;
    background-color: ${(props) => props.theme.colorMain.concat("1A")};
    padding: 30px 50px;


  .quote__author {
    display: flex;
    align-items: flex-end;
    margin-bottom: 17px;
  }

  .quote__name {
    font-weight: 900;
    font-size: 28px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
  }

  .quote__post {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colorDark};
  }

  .quote__text {
    font-size: 16px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
  }

  .quote__tail {
    position: absolute;
    bottom: -19px;
    left: -29px;
  }
  @media ${device.tablet_portrait} {
    padding-right: 15px;
  }
  @media ${device.phone_landscape} {
    padding-top: 60px;
    padding-left: 20px;
    border-radius: 0px 20px 20px 0px;
    .quote__tail {
      display: none;
    }
    .quote__name {
      font-size: 20px;
    }

    .quote__post {
      font-size: 14px;
    }
  }
  @media ${device.phone_portrait} {
    .quote__name {
      font-size: 18px;
    }

    .quote__post {
      font-size: 12px;
    }
    .quote__text {
      font-size: 14px;
    }
  }
`;

const QuotationDiv = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 70px;
  align-items: end;

  .quotation__image {
    z-index: 1;
    flex-shrink: 0;
    margin-right: 35px;
    margin-bottom: 35px;
    width: 316px;
    height: 316px;
    border-radius: 50%;

    img {
      object-fit: cover;
      border-radius: 50%;
    }
  }

  @media ${device.tablet_landscape} {
    .quotation__image {
      width: 228px;
      height: 228px;
      margin-right: 30px;
      margin-bottom: 30px;
    }
  }

  @media ${device.tablet_portrait} {
    .quotation__image {
      width: 142px;
      height: 142px;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }

  @media ${device.phone_landscape} {
    .quotation__image {
      width: 100px;
      height: 100px;
      margin-right: 0px;
      margin-bottom: 0px;
      position: absolute;
      top: -50px;
      left: 0;
    }
  }
`;

export default Quotation;
