import React from "react";

const AddedWorks = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      strokeLinecap="round" strokeLinejoin="round"
    />
    <path d="M10.4688 6.50391H7.46875V17.5039H10.4688V6.50391Z" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.4688 6.50391H13.4688V11.5039H17.4688V6.50391Z" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.4688 14.5039H13.4688V17.5039H17.4688V14.5039Z" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default AddedWorks;
