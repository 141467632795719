import React from "react";

const RoundedUpload = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 14V2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 6.5L10 1.5L5 6.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19 12.5V16.5C19 17.0304 18.7893 17.5391 18.4142 17.9142C18.0391 18.2893 17.5304 18.5 17 18.5H3C2.46957 18.5 1.96086 18.2893 1.58579 17.9142C1.21071 17.5391 1 17.0304 1 16.5V12.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RoundedUpload;
