import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useFormik, FormikProvider } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { AppContext } from "../../AppContext";
import { fbRegExp, liRegExp } from "../../../constants";
import { SettingsTitles } from "../../../Routes";
import { getApiUrl, getCSRF, logging } from "../../../utils";
import Input from "../../UI/inputs/Input";
import SubmitButton from "../../UI/buttons/SubmitButton";


const ValidationSchema = Yup.object().shape({
  portrait: Yup.string().trim(),
  // .transform((value) => value.replaceAll(/\s\s*/g, " "))
  fbLink: Yup.string().matches(fbRegExp, "check-facebook"),
  liLink: Yup.string().matches(liRegExp, "check-linkedin"),
});

const Description = ({ action }) => {
  const { ownProfile, showSuccessModal, setOwnProfile, locale } = useContext(AppContext);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      portrait: ownProfile ? ownProfile.portrait : "",
      fbLink: ownProfile ? ownProfile.fb_link : "",
      liLink: ownProfile ? ownProfile.li_link : "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      values = ValidationSchema.cast(values);
      const res = await axios.post(getApiUrl(`settings/${action}`), values, {
        headers: getCSRF(),
      });
      if (res.status === 200) {
        setOwnProfile(res.data);
        showSuccessModal();
      }
      logging(res);
    },
  });

  return (
    <DescriptionDiv>
      <div className="settings__header">{SettingsTitles[locale][action]}</div>
      <FormikProvider value={formik}>
        <DescriptionForm className="settings__form" onSubmit={formik.handleSubmit}>
          <Input
            textarea
            className="settings__input"
            valueName="portrait"
            formik={formik}
            placeholder="description__portrait"
            maxLength="100"
            counter
          />
          <div className="settings__label">Facebook</div>
          <Input className="settings__input" valueName="fbLink" formik={formik} placeholder="https://www.facebook.com/product-name" maxLength="100" />
          <div className="settings__label">LinkedIn</div>
          <Input className="settings__input" valueName="liLink" formik={formik} placeholder="https://www.linkedin.com/in/product-name" maxLength="100" />
          <SubmitButton className="settings__submit" />
        </DescriptionForm>
      </FormikProvider>
    </DescriptionDiv>
  );
};

const DescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionForm = styled.form``;

export default Description;
