import React, { useContext, useState, useEffect, useCallback } from "react";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import axios from "axios";
import Cookies from "js-cookie";
import Cropper from "react-easy-crop";
import "../../../../fonts.css";
import { getApiUrl, logging, Show } from "../../../../utils";

import { ModalBackground, SmallCrossButton } from "../../../SmallComponents";
import { device, ScreenSizes } from "../../../../constants";
import { localeButtonTexts } from "../../../../Internationalization";
import { AppContext } from "../../../AppContext";
import Modal from "../../../modals/Modal";
import { getCroppedImg } from "../../../canvasUtils";

const ModalOther = ({ modalVisible, toggleModal, imageSrc, setImageSrc, setPreviewImage }) => {
  const { showSuccessModal, locale, toggleLoadingModal } = useContext(AppContext);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClick = async () => {
    toggleModal(false);
    toggleLoadingModal(true);
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      const csrftoken = Cookies.get("csrftoken");
      const form_data = new FormData();
      const canvas = croppedImage;
      let imageBlob;
      await fetch(canvas)
        .then((res) => res.blob())
        .then((blob) => (imageBlob = blob));
      form_data.append("picture", imageBlob, "picture.png");
      const res = await axios.post(
        getApiUrl("artifacts/set_screenshot/"),
        form_data,
        {
          headers: { "X-CSRFToken": csrftoken, "content-type": "multipart/form-data" },
        }
      );
      if (res.status === 200) {
        if (res.data.success) {
          setPreviewImage(res.data.img_path);
        }
        showSuccessModal(res.data.result_text, res.data.success);
      }
      logging(res);
    } catch (e) {
      console.error(e);
    }
    toggleLoadingModal(false);
  };

  const onClose = () => {
    toggleModal(false);
    setImageSrc(null);
  };

  return (
    <Modal showCondition={modalVisible} closeModal={onClose}>
      <ModalOtherDiv onClick={(e) => e.stopPropagation()}>
        <div className="modal-other__cropper">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={847 / 506}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div>
          <input
            className="modal-other__input"
            type="range"
            id="scale"
            name="scale"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(parseFloat(e.target.value))}
          />
        </div>
        <div className="modal-other__btn" onClick={onClick}>
          {localeButtonTexts[locale].save}
        </div>
      </ModalOtherDiv>
    </Modal>
  );
};

const ModalOtherDiv = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  .modal-other__cropper {
    position: relative;
    width: calc(100vw - 300px);
    max-width: 847px;
    min-height: 300px;
    height: 50vw;
    max-height: 506px;
    background: #333;
  }

  .modal-other__input {
    margin-top: 39px;
    width: 234px;
    height: 5px;
  }

  .modal-other__btn {
    margin-top: 55px;
    cursor: pointer;
    background: ${(props) => props.theme.colorMain};
    border: none;
    border-radius: 5px;
    font-weight: bold;
    width: 217px;
    height: 64px;
    font-size: 16px;
    line-height: 64px;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    color: ${(props) => props.theme.colorWhite};
  }

  @media ${device.tablet_portrait} {
    .modal-other__cropper {
      width: calc(100vw - 120px);
    }
  }

  @media ${device.phone_landscape} {
    padding: 35px 24px 24px 24px;

    .modal-other__cropper {
      width: 100%;
      min-height: 200px;
    }

    .modal-other__btn {
      width: 432px;
    }
  }

  @media ${device.phone_portrait} {
    padding: 109px 16px 68px 16px;

    .modal-other__btn {
      height: 48px;
      line-height: 48px;
      width: 100%;
    }
  }
`;

export default ModalOther;
