import React from "react";

const SmallCross = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SmallCross;





