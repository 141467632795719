import axios from "axios";
import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { device, PlatformTypes, successfulMessages } from "../../../../constants";
import { getApiUrl, getCSRF, logging, Show } from "../../../../utils";
import { AppContext } from "../../../AppContext";
import UploadButton from "../../../UI/buttons/UploadButton";
import Hint from "../../../UI/other/Hint";
import PreviewImage from "../PreviewImage";
import ArrowHint from "../../../UI/other/ArrowHint";
import { localeButtonTexts } from "../../../../Internationalization";

const LoadMiro = ({ formik }) => {

  const { toggleLoadingModal, showSuccessModal, locale } = useContext(AppContext);

  const getPreviewImage = async (miro_id) => {
    toggleLoadingModal(true);
    try {
      formik.setFieldValue("preview_image", null);
      const result = await axios.post(
        getApiUrl(`artifacts/create_screenshot/${PlatformTypes.Miro}`),
        { miro_id: miro_id || formik.values.miro_id },
        { headers: getCSRF() },
      );
      if (result.status === 200) {
        logging(result);
        showSuccessModal(result.data.result_text, result.data.success);
        if (result.data.success) {
          formik.setFieldValue("preview_image", result.data.img_path);
        }
      } else {
        showSuccessModal(successfulMessages[locale].unknownError, false);
      }
    } catch (e) {
      logging(e);
      showSuccessModal(successfulMessages[locale].unknownError, false);
    }
    toggleLoadingModal(false);
  };

  const onClickDownload = async () => {
    window.miroBoardsPicker.open({
      clientId: "3074457362793623209",
      action: "select",
      success: (result) => {
        logging(result);
        formik.setFieldValue("miro_id", result.id);
        formik.values.title === "" && formik.setFieldValue("title", result.name || "");
        getPreviewImage(result.id);
      },
    });
  };

  const onClickDelete = () => {
    formik.setFieldValue("miro_id", null);
    formik.setFieldValue("preview_image", null);
  };

  const arrowHintElements = [
    <FormattedMessage id="load-miro__arrow-hint.1" />,
    <FormattedMessage id="load-miro__arrow-hint.2" />,
    <FormattedMessage id="load-miro__arrow-hint.3" />,
  ];

  return (
    <StyledDiv>
      <Show when={!formik.values.miro_id}>
        <Hint className="load__hint"><FormattedMessage id="load-miro__hint" /></Hint>
        <ArrowHint className="load__arrow-hint" elements={arrowHintElements} />
        <UploadButton className="load__upload" text={localeButtonTexts[locale]["upload"]} onClick={onClickDownload} />
      </Show>
      <Show when={formik.values.miro_id}>
        <PreviewImage
          previewImage={formik.values.preview_image}
          handleRefresh={() => getPreviewImage()}
          handleDelete={onClickDelete}
          imageText="miro screenshot"
          hideUpload
        />
      </Show>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .load__hint,
  .load__upload {
    margin-bottom: 30px;
  }

  .load__arrow-hint {
    margin-bottom: 40px;
  }

  .load__row {
    display: flex;
    align-items: center;

    svg {
      flex-shrink: 0;
    }
  }

  .btn-add {
    cursor: pointer;
    width: 200px;
    height: 63px;
    margin: 30px 0px 30px 0px;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colorMain};
    border-radius: 5px;
    justify-content: center;

    .btn-add-inside {
      display: flex;
      align-items: center;

      .btn-text {
        padding-left: 9px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.colorWhite};
        text-transform: uppercase;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
      }

      .btn-img {
        position: relative;
        margin-left: 12px;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }

  @media ${device.phone_portrait} {
    .load__hint {
      margin-bottom: 20px;
    }
  }
`;

export default LoadMiro;
