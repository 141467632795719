import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FormikProvider } from "formik";
import SubmitButton from "../../../UI/buttons/SubmitButton";
import Input from "../../../UI/inputs/Input";
import Select from "../../../UI/inputs/Select";
import TagSelect from "../../../UI/inputs/TagSelect";
import { localeSettingsLabels } from "../../../../Internationalization";
import { AppContext } from "../../../AppContext";
import { getApiUrl } from "../../../../utils";

const AddPRForm = (props) => {
  const { formik, disableSubmit } = props;
  const [tags, setTags] = useState([]);
  const [commonTags, setCommonTags] = useState([]);
  const [industries, setIndustries] = useState([]);
  const { locale } = useContext(AppContext);
  useEffect(() => {
    fetch(getApiUrl("artifacts/tags/"))
      .then((res) => res.json())
      .then((result) => {
        setTags(result.tags);
        setCommonTags(result.common_tags);
      });
    fetch(getApiUrl("artifacts/industries/"))
      .then((res) => res.json())
      .then((result) => {
        setIndustries(result.industries);
      });
  }, []);

  const industryOptions = industries.map((value) => ({ value, key: value, label: value }));
  return (
    <FormikProvider value={formik}>
      <StyledForm className="settings__form" onSubmit={formik.handleSubmit}>
        <div className="settings__label">{localeSettingsLabels[locale]["add-pr__title"]}</div>
        <Input className="settings__input" valueName="title" formik={formik} placeholder="add-pr__title" maxLength="40" />
        <div className="settings__label">{localeSettingsLabels[locale]["add-pr__company"]}</div>
        <Input className="settings__input" valueName="company" formik={formik} placeholder="add-pr__company" maxLength="30" />
        <div className="settings__label">{localeSettingsLabels[locale]["add-pr__industry"]}</div>
        <Select className="settings__select" valueName="industry" formik={formik} options={industryOptions} placeholder="add-pr__industry" />
        <div className="settings__label">{localeSettingsLabels[locale]["add-pr__tags"]}</div>
        <TagSelect
          className="settings__select"
          valueName="tags"
          formik={formik}
          options={tags}
          commonTags={commonTags}
          placeholder="add-pr__tags"
        />
        <div className="settings__label">{localeSettingsLabels[locale]["add-pr__description"]}</div>
        <Input
          className="settings__input"
          textarea
          valueName="description"
          formik={formik}
          placeholder="add-pr__description"
          maxLength="1000"
          counter
        />
        <SubmitButton className="settings__submit" disabled={disableSubmit} />
      </StyledForm>
    </FormikProvider>
  );
};

const StyledForm = styled.form``;

export default AddPRForm;
