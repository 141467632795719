import React, { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import SettingsMenu from "./menu/SettingsMenu";
import { Show } from "../../utils";
import { AppContext } from "../AppContext";
import { SettingsContent, SettingsTitles, SettingsItems } from "../../Routes";
import { device, urls } from "../../constants";
import InterProvider from "../../Internationalization";

const Settings = ({ action, index }) => {
  const { ownProfile, setSettingsAction, setUrlState, locale } = useContext(AppContext);
  const provider = useMemo(() => {
    if ([SettingsItems.changeCJM, SettingsItems.changePR].includes(action)) {
      return action.replace("change", "add");
    }
    return action;
  }, [action]);
  useEffect(() => {
    setUrlState(urls.settings);
    return () => {
      setUrlState(null);
    };
  }, [setUrlState]);

  useEffect(() => {
    setSettingsAction(action);
    return () => {
      setSettingsAction(null);
    };
  }, [action, setSettingsAction]);
  return (
    <StyledSettings>
      <Helmet>
        <title>{SettingsTitles[locale][action] || "Некорректная страница"}</title>
        <meta name="description" content={action} />
        <meta property="og:title" content={action} />
        <meta property="og:description" content={action} />
      </Helmet>
      <Show when={ownProfile && action}>
        <SettingsMenu />
        <InterProvider id={`settings__${provider}`}>
          <StyledMainContent>
            <SettingsContent action={action} artifactIndex={index} />
          </StyledMainContent>
        </InterProvider>
      </Show>
    </StyledSettings>
  );
};

const StyledSettings = styled.div`
  display: flex;

  @media ${device.phone_landscape} {
    justify-content: center;
  }
`;

const StyledMainContent = styled.div`
  flex-grow: 1;
  max-width: 690px;
  padding: 40px 138px 60px;

  .settings__header {
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 30px;
    color: ${(props) => props.theme.colorDark};
  }

  .settings__label {
    font-size: 14px;
    line-height: 30px;
    color: ${(props) => props.theme.colorDark};
    margin-bottom: 8px;
  }

  .settings__form {
    max-width: 571px;
    display: flex;
    flex-direction: column;
  }

  .settings__btns {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .settings__input,
  .settings__select {
    margin-bottom: 30px;
  }

  @media ${device.desktop} {
    padding-left: 124px;
  }

  @media ${device.tablet_landscape} {
    padding-left: 100px;
    padding-right: 24px;
    .settings__form {
      max-width: 460px;
    }
  }

  @media ${device.tablet_portrait} {
    max-width: 100%;
    padding-left: 24px;
    .settings__form {
      max-width: 592px;
    }
  }

  @media ${device.phone_landscape} {
    max-width: 448px;
    padding: 30px 16px;

    .settings__submit {
      width: 100%;
    }

    .settings__btns {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-gap: 30px;
    }

    .settings__btn-delete {
      order: -1;
      justify-self: end;
    }

  }

  @media ${device.phone_portrait} {
    .settings__header {
      font-size: 28px;
      line-height: 30px;
    }

    .settings__submit {
      font-size: 14px;
      height: 48px;
      line-height: 48px;
    }
  }

`;

export default Settings;
