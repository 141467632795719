import React from "react";

const InformationPoint = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#DFE9FF" />
    <path d="M13.5697 15.852L13.3968 16.5589C12.878 16.7636 12.4637 16.9194 12.1551 17.0266C11.8462 17.134 11.4873 17.1875 11.0784 17.1875C10.4504 17.1875 9.96199 17.0337 9.61362 16.7281C9.26526 16.4213 9.09102 16.0326 9.09102 15.561C9.09102 15.3784 9.10366 15.1907 9.12995 14.9994C9.15645 14.8078 9.1986 14.5921 9.25627 14.351L9.90454 12.0565C9.96221 11.8368 10.0112 11.6286 10.0505 11.4317C10.0903 11.2361 10.1095 11.0561 10.1095 10.894C10.1095 10.601 10.0488 10.396 9.92805 10.2808C9.80727 10.1659 9.5768 10.1076 9.23465 10.1076C9.06706 10.1076 8.89482 10.1344 8.71925 10.1863C8.5429 10.2384 8.39217 10.2888 8.26562 10.3356L8.43898 9.62821C8.86365 9.4553 9.26958 9.30724 9.65788 9.18435C10.0462 9.06113 10.4131 8.99946 10.7601 8.99946C11.3837 8.99946 11.865 9.15019 12.2029 9.45164C12.5409 9.75332 12.7098 10.1444 12.7098 10.6263C12.7098 10.726 12.6987 10.9017 12.6748 11.1528C12.6516 11.4044 12.6082 11.635 12.545 11.8447L11.8995 14.13C11.8466 14.3136 11.799 14.5234 11.7576 14.7596C11.7149 14.9941 11.6945 15.1734 11.6945 15.2936C11.6945 15.5971 11.7622 15.8042 11.8978 15.9144C12.0345 16.0245 12.2698 16.0793 12.6041 16.0793C12.761 16.0793 12.9396 16.0514 13.138 15.9968C13.3359 15.9421 13.4802 15.8941 13.5697 15.852ZM13.7334 6.25766C13.7334 6.65582 13.5833 6.99587 13.2817 7.27548C12.981 7.55608 12.6185 7.69649 12.1945 7.69649C11.7692 7.69649 11.4058 7.55608 11.1016 7.27548C10.7979 6.99576 10.6458 6.65582 10.6458 6.25766C10.6458 5.86027 10.7979 5.51966 11.1016 5.23651C11.4053 4.9538 11.7693 4.8125 12.1945 4.8125C12.6184 4.8125 12.981 4.95413 13.2817 5.23651C13.5835 5.51966 13.7334 5.86038 13.7334 6.25766Z" fill="#5773FF" />
  </svg>
);

export default InformationPoint;
