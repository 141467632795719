import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { device, SalaryTypes } from "../../../constants";
import { getMediaUrl, Show } from "../../../utils";

const EstimationBlock = ({ artifact }) => {

  const artifactRate = {
    count: artifact.salary_type === SalaryTypes.Dollar ? `$${artifact.rate}` : `${artifact.rate}k RUB`,
    type: artifact.salary_type === SalaryTypes.Dollar ? "per hour" : "",
  };

  return (
    <Show when={artifact.rate !== 0}>
      <StyledDiv>
        <div className="estimation-block__rate-block">
          <div><FormattedMessage id="salary-estimation" />: </div>
          <div className="estimation-block__rate-block__rate-row">
            <Show when={artifact.rate === 0}>
              <div style={{ marginLeft: "17px" }}><FormattedMessage id="not-estimated" /></div>
            </Show>
            <Show when={artifact.rate !== 0}>
              <div className="estimation-block__rate-block__rate">{artifactRate.count}</div>
              <div>{artifactRate.type}</div>
            </Show>
          </div>
        </div>
        <Show when={artifact.logos.length > 0}>
          <div className="estimation-block__logos-block">
            <div className="estimation-block__logos-block__label"><FormattedMessage id="estimated-by" />: </div>
            <div className="estimation-block__logos-block__logos">
              {artifact.logos.map((logo) => <img key={logo.name} src={getMediaUrl(`${logo.image}`)} alt={logo.name} />)}
            </div>
          </div>
        </Show>
      </StyledDiv>
    </Show>
  );
};

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colorGrey};
  border-radius: 5px;
  margin-bottom: 1px;
  padding: 40px;
  background-color: ${(props) => props.theme.colorWhite};
  box-sizing: border-box;

  .estimation-block__rate-block {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    white-space: nowrap;
  }

  .estimation-block__rate-block__rate-row {
    display: flex;
    align-items: center;
  }

  .estimation-block__rate-block__rate {
    margin-left: 12px;
    margin-right: 12px;
    font-weight: bold;
    font-size: 20px;
  }

  .estimation-block__logos-block {
    margin-top: 30px;
    display: flex;
    width: 100%;
  }

  .estimation-block__logos-block__label {
    font-size: 16px;
    line-height: 35px;
    margin-right: 10px;
    color: ${(props) => props.theme.colorDark};
  }

  .estimation-block__logos-block__logos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    --gap: 20px;

    margin: calc((-1 * var(--gap))) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
    & > * {
      margin: var(--gap) 0 0 var(--gap);
    }
  }

  img {
    height: 35px;
  }

  @media ${device.tablet_portrait} {
    padding-top: 52px;
  }
  @media ${device.phone_portrait} {
    padding: 30px 10px 25px;
    .estimation-block__rate-block {
      flex-direction: column;
      align-items: start;
    }
    .estimation-block__rate-block__rate {
      margin-left: 0;
    }
    .estimation-block__logos-block {
      flex-direction: column;
    }
  }
`;

export default EstimationBlock;
