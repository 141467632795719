import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { device, urls } from "../constants";
import "../fonts.css";
import InterProvider from "../Internationalization";
import { Show } from "../utils";
import { AppContext } from "./AppContext";
import { MainSettingsMenu } from "./settings/menu/SettingsMenu";
import { SmallCrossButton } from "./SmallComponents";
import LinkButton from "./UI/buttons/LinkButton";
import LanguageSelect from "./UI/inputs/LanguageSelect";
import ProfileImage from "./UI/other/ProfileImage";

const MobileMenu = (props) => {
  const { mobileMenuVisible, toggleMobileMenu, user, ownProfile, information } = useContext(AppContext);
  const competition = information && information.competition;
  const closeMenu = () => toggleMobileMenu(false);

  return (
    <Show when={mobileMenuVisible}>
      <Background onClick={closeMenu}>
        <div className="mobile-menu-wrapper" onClick={(e) => e.stopPropagation()}>
          <MobileMenuDiv onClick={(e) => e.stopPropagation()}>
            <Show when={user && ownProfile}>
              <ProfileImage
                className="mobile-menu__profile-img"
                onClick={closeMenu}
                picture={ownProfile ? ownProfile.picture : ""}
                href={ownProfile ? `/${ownProfile.username}` : null}
              />
              <MainSettingsMenu itemClass="mobile-menu__menu-item" marginBottom="20px" />
              <hr className="mobile-menu__line" />
            </Show>
            <InterProvider id="mobile-menu">
              <div className="mobile-menu__buttons">
                <Show when={competition && competition.enabled}>
                  <LinkButton simple className="mobile-menu__button" url={competition && competition.url}>
                    <FormattedMessage id="link__competition" />
                  </LinkButton>
                </Show>
                <LinkButton className="mobile-menu__button" url={urls.product} onClick={closeMenu}><FormattedMessage id="link__product" /></LinkButton>
                <LinkButton className="mobile-menu__button" url={urls.company} onClick={closeMenu}><FormattedMessage id="link__company" /></LinkButton>
                <LanguageSelect />
              </div>
            </InterProvider>
            <div className="mobile-menu__btn-cross">
              <SmallCrossButton onClick={closeMenu} />
            </div>
          </MobileMenuDiv>
        </div>
      </Background>
    </Show>
  );
};

const MobileMenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 53px;
  margin-bottom: 120px;
  margin-left: 24px;

  .mobile-menu__btn-cross {
    position: absolute;
    top: 40px;
    right: 18px;
    cursor: pointer;
  }

  .mobile-menu__profile-img {
    margin-left: 2px;
    margin-bottom: 30px;
    width: 40px;
    height: 40px;
  }

  .mobile-menu__buttons {
    margin-top: 25px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 30px;
    }
  }

  .mobile-menu__button {
    width: fit-content;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    position: relative;
    :hover {
      color: ${(props) => props.theme.colorMain};
    }

    &.active-state {
      color: ${(props) => props.theme.colorMain};
      &:after {
        position: absolute;
        display: block;
        width: 43px;
        content: "";
        height: 4px;
        background: ${(props) => props.theme.colorMain};
        bottom: 0;
        left: calc(50% - 21.5px);
      }
    }
  }

  .mobile-menu__line {
    width: calc(100% + 24px);
    margin: 0px 0px 0px -24px;
  }

  .mobile-menu__menu-item {
    margin-top: 30px;
  }

  @media ${device.phone_portrait} {
    padding-left: 16px;
    .mobile-menu__line {
      width: auto;
      margin: 0px 40px 0px 0px;
    }
  }
`;

const Background = styled.div`
  width: max(100vw, 100%);
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  display: none;
  justify-content: start;
  align-items: center;
  z-index: 7;

  @media ${device.tablet_portrait} {
    display: block;
  }

  .mobile-menu-wrapper {
    position: absolute;
    overflow: auto;
    background: ${(props) => props.theme.colorGreyLight};
    box-shadow: 0px 9px 18px rgba(115, 115, 126, 0.18), 0px 5.5px 5px rgba(115, 115, 126, 0.24);
    width: 390px;
    height: 100%;
    z-index: 8;

    @media ${device.phone_landscape} {
      width: 329px;
    }
    @media ${device.phone_portrait} {
      height: max(100vh, 100%);
      width: max(100vw, 100%);
    }
  }
`;

export default MobileMenu;
