import React, { useEffect, useState } from "react";
import { history } from "react-dom";
import styled from "styled-components";
import axios from "axios";
import { Helmet } from "react-helmet";
import ArtifactBlock from "./artifact-block/ArtifactBlock";
import CommentBlock from "./CommentBlock";
import Sidebar from "./Sidebar";
import { getApiUrl, logging, Show } from "../../utils";
import { device } from "../../constants";
import InterProvider from "../../Internationalization";

const ArtifactPreview = (props) => {
  const [artifact, setArtifact] = useState(null);
  const [artifactID, setArtifactID] = useState(null);
  useEffect(() => {
    setArtifactID(props.artifactID);
    const fetchArtifact = async () => {
      const request = await axios.get(getApiUrl(`artifacts/artifact${props.artifactID}`));
      const data = request.data;
      if (data && data.artifact) {
        if (JSON.stringify(data.artifact) !== "{}") {
          logging(data);
          setArtifact(data.artifact);
        }
      }
    };
    fetchArtifact();
  }, [props.artifactID]);

  return (
    <InterProvider id="cjm-preview">
      <Show when={artifact}>
        <Helmet>
          <title>{artifact ? artifact.title : "Некорректная страница"}</title>
          <meta name="description" content={artifact ? artifact.title : "Некорректная страница"} />
          <meta property="og:title" content={artifact ? artifact.title : "Некорректная страница"} />
          <meta property="og:description" content={artifact ? artifact.title : "Некорректная страница"} />
        </Helmet>
        <PreviewDiv>
          <div className="cjm-preview__left-side">
            <ArtifactBlock artifact={artifact} setArtifactID={setArtifactID} artifactID={artifactID}/>
            <CommentBlock artifact={artifact} artifactID={artifactID} />
          </div>
          <Sidebar artifact={artifact} />
        </PreviewDiv>
      </Show>
    </InterProvider>
  );
};

const PreviewDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 138px 20px;
  background-color: ${(props) => props.theme.colorGreyLight};

  .cjm-preview__left-side {
    flex: 1;
    display: flex;
    width: 100%;
    max-width: calc(100% - 237px);
    flex-direction: column;
  }

  @media ${device.desktop} {
    .cjm-preview__left-side {
      max-width: calc(100% - 205px);

    }
  }

  @media ${device.tablet_landscape} {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media ${device.tablet_portrait} {
    .cjm-preview__left-side {
      max-width: 100%;
    }
    padding-top: 40px;
    flex-direction: column;
  }
  @media ${device.phone_landscape} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default ArtifactPreview;
