import React from 'react';
import styled from 'styled-components';
import { device } from '../../constants';
import { Show } from '../../utils';
import CrossButton from '../UI/buttons/CrossButton';
import ModalBackground from './ModalBackground';

const Modal = ({ showCondition, closeModal, children }) => (
  <Show when={showCondition}>
    <ModalBackground onClick={closeModal}>
      <ModalDiv onClick={(e) => e.stopPropagation()}>
        {children}
        <CrossButton small className="modal__small-cross-button" onClick={closeModal} />
      </ModalDiv>
    </ModalBackground>
  </Show>
);

const ModalDiv = styled.div`
  box-shadow: 0px 1.19px 3.19px rgba(117, 133, 171, 0.2);
  border-radius: 5px;
  background: ${(props) => props.theme.colorWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;

  .modal__small-cross-button {
    display: none;
  }

  @media ${device.phone_landscape} {
    width: auto;
    height: 100%;
    right: 0;
    border-radius: 0;
    padding: 0 16px;

    .modal__small-cross-button {
      display: block;
      position: absolute;
      z-index: 15;
      top: 30px;
      right: 24px;
    }
  }

  @media ${device.phone_portrait} {
    .modal__small-cross-button {
      top: 30px;
      right: 16px;
    }
  }
`;

export default Modal;
