import React from "react";

const RoundedEdit = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
    d="M16 1.82739C16.2626 1.56508 16.5745 1.357 16.9176 1.21503C17.2608 1.07307 17.6286 1 18 1C18.3714 1 18.7392 1.07307 19.0824 1.21503C19.4256 1.357 19.7374 1.56508 20 1.82739C20.2626 2.08971 20.471 2.40113 20.6131 2.74386C20.7553 3.08659 20.8284 3.45393 20.8284 3.8249C20.8284 4.19587 20.7553 4.56321 20.6131 4.90594C20.471 5.24868 20.2626 5.56009 20 5.82241L6.5 19.3056L1 20.8037L2.5 15.3106L16 1.82739Z"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    />
  </svg>
);

export default RoundedEdit;
