import React, { useContext, useEffect } from "react";
import Snowfall from 'react-snowfall';
import styled, { ThemeProvider } from "styled-components";
import { useRoutes } from "hookrouter";
import { Helmet } from "react-helmet";
import { GlobalStyle } from "../globalStyles";
import { AppContextProvider, AppContext } from "./AppContext";
import theme from "../Theme";
import Header from "./header/Header";
import LoginModal from "./modals/LoginModal";
import Routes from "../Routes";
import SuccessModal from "./modals/SuccessModal";
import ConfirmModal from "./modals/ConfirmModal";
import LoadingModal from "./modals/LoadingModal";
import { Show } from "../utils";
import ChangePictureModal from "./modals/ChangePictureModal";
import MobileMenu from "./MobileMenu";
import Footer from "./footer/Footer";

const App = () => (
  <AppContextProvider>
    <Helmet>
      <title>Underdoc</title>
      <meta name="description" content="Main page" />
      <meta property="og:title" content="Underdoc" />
      <meta property="og:description" content="Main page" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Main />
    </ThemeProvider>
  </AppContextProvider>
);

const Main = () => {
  const routes = useRoutes(Routes);
  const { userIsLoaded, setMiroLoaded, mobileMenuVisible, information } = useContext(AppContext);
  const snow = information && information.snow && information.snow.enabled;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://miro.com/app/static/boardsPicker.1.0.js";
    script.async = true;
    script.onload = () => setMiroLoaded(true);

    document.body.appendChild(script);
  }, [setMiroLoaded]);
  return (
    <StyledMain isMobileMenuOpen={mobileMenuVisible}>
      <Header />
      <hr />
      <div className="main-content">
        <Show when={userIsLoaded}>{routes}</Show>
      </div>
      <hr />
      <Footer />
      <MobileMenu />
      <LoginModal />
      <ConfirmModal />
      <SuccessModal />
      <ChangePictureModal />
      <LoadingModal />
      <Show when={snow}>
        <div className="main__snow">
          <Snowfall />
        </div>
      </Show>
    </StyledMain>
  );
};

export const StyledMain = styled.div`
  background: ${(props) => props.theme.colorWhite};
  display: flex;
  flex-direction: column;
  min-height: 100%;
  .main-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  .main__snow {
    pointer-events: none;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  hr {
    border: none;
    border-top: 1px solid ${(props) => props.theme.colorGrey};
  }

  > hr {
    margin: 0px;
  }

  .tag {
    padding: 0px 10px 0px 10px;
    border: 1px solid ${(props) => props.theme.colorGrey2};
    border-radius: 50px;
    font-size: 12px;
    height: 27px;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
    line-height: 27px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: default;
    color: ${(props) => props.theme.colorDark};
  }

  .site-name {
    font-family: Magnolia;
    font-size: 22px;
    color: ${(props) => props.theme.colorDark};
    letter-spacing: 0px;
  }
`;

export default App;
