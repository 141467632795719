import React, { useContext } from "react";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { device, notionRegExp } from "../../../../constants";
import SubmitButton from "../../../UI/buttons/SubmitButton";
import Input from "../../../UI/inputs/Input";
import Modal from "../../../modals/Modal";
import { localeSettingsLabels } from "../../../../Internationalization";
import { AppContext } from "../../../AppContext";


const ModalPR = ({ formik, modalVisible, toggleModal, getPreviewImage }) => {

  const {locale} = useContext(AppContext);

  const ValidationSchema = Yup.object().shape({
    notion_link: Yup.string().trim().required("required-field")
      .matches(notionRegExp, "check-notion"),
  });

  const internalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notion_link: formik && formik.values.notion_link || "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      values = ValidationSchema.cast(values);
      formik.setFieldValue("notion_link", values.notion_link);
      toggleModal(false);
      getPreviewImage(values.notion_link);
    },
  });
  const notion_link = internalFormik.values.notion_link;
  return (
    <Modal showCondition={modalVisible} closeModal={() => toggleModal(false)}>
      <FormikProvider value={internalFormik}>
        <StyledForm onSubmit={internalFormik.handleSubmit}>
          <div className="modal-pr__label">{localeSettingsLabels[locale]["add-pr__modal-pr"]}</div>
          <Input
            className="modal-pr__input"
            valueName="notion_link"
            formik={internalFormik}
            placeholder="https://underdoc.notion.site/underdoc/Product-Research..."
            maxLength="150"
          />
          <SubmitButton disabled={!notion_link || notion_link===""} />
        </StyledForm>
      </FormikProvider>
    </Modal>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 44px 76px 43px 51px;

  .modal-pr__label {
    font-size: 14px;
    color: ${props => props.theme.colorDark};
    line-height: 25px;
    margin-bottom: 8px;
  }

  .modal-pr__input {
    margin-bottom: 30px;
    width: 571px;
  }

  @media ${device.tablet_portrait} {
    padding: 51px 16px 30px;

    .modal-pr__input {
      width: 560px;
    }
  }

  @media ${device.phone_landscape} {
    .modal-pr__input {
      width: 100%;
    }
  }
`;

export default ModalPR;
