import React from "react";

const RoundedArrowLeft = () => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4961 5.94336L1.49609 5.94336" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 1L1 6L6 11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default RoundedArrowLeft;
