import React, { useState, useContext, useEffect } from "react";
import { Field } from "formik";
import ReactSelect from "react-select";
import styled from "styled-components";
import Magnifier from "../SVGs/Magnifier";
import { device } from "../../../constants";
import { AppContext } from "../../AppContext";
import { localePlaceholderTexts } from "../../../Internationalization";

const SearchInput = ({ formik, options, ...props }) => {
  const selectValueName = "selectType";
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { locale } = useContext(AppContext);
  const getValue = () => (options ? options.find((option) => option.value === formik.values[selectValueName]) || null : "");
  const onChangeEvent = (e) => {
    formik.setFieldTouched(selectValueName);
    formik.setFieldValue(selectValueName, e.value);
    formik.submitForm();
  };

  const onBlur = (e) => {
    setTimeout(() => {
      formik.setFieldTouched(selectValueName);
      formik.handleBlur(e);
    }, 20);
  };

  return (
    <StyledDiv {...props} isMenuOpen={isMenuOpen}>
      <Field
        className="search-input__input"
        name="searchField"
        as="input"
        placeholder={localePlaceholderTexts[locale]["search-input"]}
        onChange={formik.handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            formik.handleSubmit();
          }
        }}
      />
      <div className="search-input__icon" onClick={formik.submitForm}>
        <Magnifier />
      </div>
      <div className="search-input__separator">
        <svg width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.5" width="1" height="31" stroke="#C4C4C4" />
        </svg>
      </div>
      <ReactSelect
        classNamePrefix="select"
        className="search-input__select"
        isSearchable={false}
        name={selectValueName}
        options={options}
        value={getValue()}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        onChange={onChangeEvent}
        onBlur={onBlur}
        defaultMenuIsOpen={false}
      />
    </StyledDiv>
  );
};
const StyledDiv = styled.div`

  justify-self: flex-start;
  position: relative;
  display: flex;
  flex-shrink: 0;
  min-width: 288px;
  width: 100%;
  align-items: center;
  background: ${(props) => props.theme.colorGrey};
  border-radius: 5px;
  justify-content: space-between;
  stroke: ${props => props.theme.colorDark};
  stroke-width: 1.2px;

  .search-input__icon {
    cursor: pointer;
    position: absolute;
    right: 240px;
  }

  .search-input__separator {
    position: absolute;
    right: 227px;
  }

  .search-input__input {
    -webkit-appearance: none;
    height: 48px;
    width: 100%;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colorDark};
    padding: 0px 270px 0px 12px;
    border: 2px transparent;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colorGrey2};
    border-radius: 5px;
    outline: none;
    background: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ::placeholder {
      color: ${(props) => props.theme.colorFont};
    }

    :focus-visible {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colorMain};
    }
  }

  .search-input__select {
    width: 222px;
    position: absolute;
    right: 0;

    .select__single-value {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      color: ${props => props.theme.colorDark};
    }

    .select__control {
      height: 48px;
      background-color: inherit;
      cursor: pointer;
      border: 2px transparent;
      border-radius: 5px;
      margin-right: 0;

      .select__value-container {
        padding: 0;
      }

      .select__indicators {
        .select__indicator-separator {
          display: none;
        }
        .select__dropdown-indicator {
          stroke-width: 0.5px;
          color: ${(props) => props.theme.colorDark};
          transform: scale(1, ${(props) => (props.isMenuOpen ? -1 : 1)});
        }
      }
    }

    .select__control--is-focused {
      outline: none;
      box-shadow: none;
      border-radius: 5px;
    }
  }

  @media ${device.phone_landscape} {

    .search-input__input {
      padding-right: 89px;
    }

    .search-input__select {
      width: 100%;
      pointer-events: none;
      .select__control {
        justify-content: end;
        .select__value-container > .select__single-value {
          display: none;
        }
        .select__indicators > .select__indicator {
          pointer-events: auto;
          padding: 8px 14px;
        }
      }
      .select__menu {
        pointer-events: auto;
      }
    }

    .search-input__icon {
      cursor: pointer;
      position: absolute;
      right: 59px;
    }

    .search-input__separator {
      position: absolute;
      right: 47px;
    }
  }
`;

export default SearchInput;
