import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { ArtifactTypes, device, PlatformTypes, SalaryTypes } from "../../../constants";
import { localeLinksTexts } from "../../../Internationalization";
import { getMediaUrl, getMiroUrl, getRelativeDate, Show } from "../../../utils";
import { AppContext } from "../../AppContext";
import Likes from "../../Likes";
import LinkSVG from "../../UI/SVGs/Link";

const MainBlock = ({ artifact }) => {
  const theme = useTheme();
  const { locale } = useContext(AppContext);
  const getArtifactData = () => {
    let iframeData = null;
    let linkData = null;
    if (artifact.platform === PlatformTypes.Miro) {
      iframeData = {
        visible: true,
        id: "previewMiro",
        key: artifact.miro_id,
        src: artifact && getMiroUrl(artifact.miro_id, true),
        title: "miro",
        scrolling: "no",
      };
      linkData = {
        text: `${localeLinksTexts[locale]["go-to"]} Miro`,
        href: `https://miro.com/app/board/${artifact.miro_id}/`,
      };
    }
    if (artifact.platform === PlatformTypes.Notion) {
      iframeData = {
        visible: true,
        id: "previewNotion",
        key: artifact.embednotion_link,
        src: artifact && artifact.embednotion_link,
        title: "notion",
      };
      linkData = {
        text: `${localeLinksTexts[locale]["go-to"]} Notion`,
        href: `${artifact.notion_link}`,
      };
    }

    if (artifact.platform === PlatformTypes.Other) {
      iframeData = {
        visible: false,
        id: "Other",
        key: "Other",
        src: "",
        title: "Other",
      };
      linkData = {
        text: `${localeLinksTexts[locale]["go-to-source"]}`,
        href: `${artifact.source_link}`,
      };
    }

    const artifactData = {
      iframeData,
      linkData,
    };
    return artifactData;
  };
  const artifactData = getArtifactData();
  return (
    <StyledDiv rate={artifact.rate} imgpath={getMediaUrl(artifact.preview_image)}>
      <div className="main-block__rate">{artifact.salary_type === SalaryTypes.Dollar ? `$${artifact.rate}/hour` : `${artifact.rate}k RUB`}</div>
      <div className="main-block__title">{artifact.title}</div>
      <div className="main-block__company">{artifact.company}</div>
      <div className="main-block__pub-date">{getRelativeDate(artifact.pub_date, locale)}</div>
      <div className="main-block__iframe-wrapper">
        <Show when={artifactData.iframeData.visible}>
          <iframe
            id={artifactData.iframeData.id}
            key={artifactData.iframeData.key}
            title={artifactData.iframeData.title}
            width="100%"
            height="100%"
            src={artifactData.iframeData.src}
            frameBorder="0"
            scrolling={artifactData.iframeData.scrolling}
            allowFullScreen
          />
        </Show>
      </div>
      <div className="main-block__row">
        <a className="main-block__link" href={artifactData.linkData.href} target="_blank" rel="noreferrer">
          <div className="main-block__link__text">{artifactData.linkData.text}</div>
          <div className="main-block__link__svg">
            <LinkSVG/>
          </div>
        </a>
        <Likes card={artifact} />
      </div>
      <div className="main-block__industry">{artifact.industry}</div>
      <div className="main-block__description">{artifact.description}</div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colorGrey};
  border-radius: 5px;
  margin-bottom: 1px;
  padding: 40px;
  background-color: ${(props) => props.theme.colorWhite};
  box-sizing: border-box;

  .main-block__rate {
    --height: 32px;
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;
    height: var(--height);
    border-radius: 0px 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.colorGreen};
    color: ${props => props.theme.colorWhite};
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    line-height: var(--height);
  }

  .main-block__title {
    color: ${(props) => props.theme.colorDark};
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 24px;
  }

  .main-block__company {
    color: ${(props) => props.theme.colorFont};
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .main-block__pub-date {
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.colorFont};
    margin-bottom: 10px;
  }

  .main-block__iframe-wrapper {
    background-image: url(${(props) => props.imgpath});
    background-size: cover;
    position: relative;
    width: 100%;
    border-radius: 5px;
    min-width: 268px;
    padding-bottom: 59.74%;
    height: 0;
    margin-bottom: 10px;
    iframe {
      border-radius: 5px;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .main-block__industry {
    font-weight: bold;
    line-height: 22px;
    color: ${props => props.theme.colorFont};
    margin-bottom: 30px;

  }

  .main-block__description {
    font-size: 18px;
    line-height: 30px;
    overflow-wrap: break-word;
  }


  .main-block__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .main-block__link {
    display: flex;
    & > * + * {
      margin-left: 8px;
    }
  }

  .main-block__link__text {
    font-size: 18px;
    line-height: 30px;
    color: ${(props) => props.theme.colorMain};
    white-space: nowrap;
  }

  .main-block__link__svg {
    align-self: center;
    stroke: ${(props) => props.theme.colorMain};
    stroke-width: 1.5px;
  }

  @media ${device.tablet_landscape} {
    .main-block__title {
      margin-bottom: 20px;
    }
  }
  @media ${device.tablet_portrait} {
    padding-top: 52px;
    .main-block__rate {
      display: ${props => props.rate > 0 ? "block" : "none"};
    }
  }
  @media ${device.phone_landscape} {
    .main-block__title {
      font-size: 28px;
      line-height: 30px;
    }
    .main-block__description {
      font-size: 16px;
    }
  }
  @media ${device.phone_portrait} {
    padding-left: 10px;
    padding-right: 10px;
    .main-block__title {
      font-size: 16px;
      line-height: 20px;
    }
    .main-block__company {
      font-size: 14px;
    }
    .main-block__pub-date {
      font-size: 12px;
    }
    .main-block__link__text {
      font-size: 14px;
    }
    .main-block__industry {
      font-size: 12px;
    }
  }
`;

export default MainBlock;
