import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { navigate } from "hookrouter";
import image from "../../images/for-product/illustration-1440.png";
import { AppContext } from "../AppContext";
import { AddButton } from "../SmallComponents";
import { SettingsItems } from "../../Routes";
import { device } from "../../constants";

const ForProductHeader = () => {
  const { toggleLoginModal, user } = useContext(AppContext);
  const intl = useIntl();
  const addButtonOnClick = () => (user ? navigate(`/settings/${SettingsItems.addArtifact}`) : toggleLoginModal(true));
  return (
    <HeaderDiv>
      <div className="for-product-header__title">
        <FormattedMessage
          id="header__title"
          defaultMessage="show your work{br}samples instead{br}of CV to Get job"
        />
      </div>
      <div className="for-product-header__button">
        <AddButton text={intl.formatMessage({ id: "header__add-button" })} onClick={addButtonOnClick} />
      </div>
      <div className="for-product-header__image-wrapper">
        <img src={image} alt="simple" />
      </div>
    </HeaderDiv>
  );
};

const HeaderDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "title image"
    "button image";
  margin-bottom: 150px;
  margin-top: 100px;

  .for-product-header__title {
    grid-area: title;
    font-weight: 900;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colorDark};
    margin-bottom: 62px;
    margin-right: 40px;
    max-width: 478px;
    white-space: nowrap;
  }

  .for-product-header__button {
    grid-area: button;
    width: 252px;
    height: 61px;
  }

  .for-product-header__image-wrapper {
    grid-area: image;
    max-width: 642px;
    justify-self: end;

    img {
      width: 100%;
      height: auto;
    }
  }
  @media ${device.tablet_landscape} {
    margin-top: 80px;
  }

  @media ${device.tablet_portrait} {
    margin-top: 70px;
    margin-bottom: 130px;
    grid-template-columns: auto;
    grid-template-areas:
    "title"
    "button"
    "image";

    .for-product-header__title {
      line-height: 60px;
    }

    .for-product-header__image-wrapper {
      justify-self: center;
    }

    .for-product-header__button {
      margin-bottom: 70px;
    }
  }

  @media ${device.phone_landscape} {
    margin-top: 50px;
    grid-template-areas:
    "title"
    "image"
    "button";

    .for-product-header__button {
      justify-self: center;
      margin-bottom: 0px;
      margin-top: 60px;
      width: 100%;
      max-width: 448px;
    }
    .for-product-header__title {
      margin-bottom: 50px;
      margin-right: 0;
      white-space: normal;
      font-size: 30px;
    }

  }

  @media ${device.phone_portrait} {

    .for-product-header__title {
      font-size: max(18px, 6vw);
      line-height: 50px;
    }
    .for-product-header__button {
      height: 48px;
    }
  }
`;

export default ForProductHeader;
