import React, { createContext, useState, useEffect, useReducer } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { getApiUrl, logging } from "../utils";
import { ScreenSizes } from "../constants";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [currentWidth, setCurrentWidth] = useState(ScreenSizes.xxl);
  const [userIsLoaded, setUserIsLoad] = useState(false);
  const [user, setUser] = useState(null);
  const [ownProfile, setOwnProfile] = useState(null);
  const [loginModalVisible, toggleLoginModal] = useState(false);
  const [settingsAction, setSettingsAction] = useState(null);
  const [miroLoaded, setMiroLoaded] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successInfo, setSuccessInfo] = useState({});
  const [restartSuccessModal, setRestartSuccessModal] = useState(-1);
  const [changePictureModalVisible, toggleChangePictureModal] = useState(false);
  const [loadingModalVisible, toggleLoadingModal] = useState(false);
  const [newPicture, setNewPicture] = useState(null);
  const [mobileMenuVisible, toggleMobileMenu] = useState(false);
  const [urlState, setUrlState] = useState(null);
  const [information, setInformation] = useState(null);
  const [ownArtifacts, setOwnArtifacts] = useState([]);
  const [locale, setLocale] = useState("en");

  const handleChangeLocale = (e) => {
    let currentLocale = e.value;
    if (currentLocale !== "ru" && currentLocale !== "en") {
      currentLocale = "en";
    }
    setLocale(currentLocale);
    localStorage.setItem("locale", currentLocale);
  };


  useEffect(() => {
    const onStorageUpdate = (e) => {
      const { key, newValue } = e;
      if (key === "locale") {
        let currentLocale = newValue;
        if (currentLocale !== "ru" && currentLocale !== "en") {
          currentLocale = "en";
        }
        setLocale(currentLocale);
      }
    };
    let currentLocale = localStorage.getItem("locale") || navigator.language || navigator.userLanguage;
    if (currentLocale !== "ru" && currentLocale !== "en") {
      currentLocale = "en";
    }
    setLocale(currentLocale);
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, [setLocale]);

  useEffect(() => {
    Cookies.set("language", locale);
  }, [locale]);

  const initConfirmProps = {
    visible: false,
    confirmAction: () => { },
    confirmText: "",
    confirmButtonText: null,
    cancelButtonText: null,
  };

  const reducerConfirmProps = (state, data) => ({ ...initConfirmProps, ...data });

  const [confirmProps, setConfirmProps] = useReducer(reducerConfirmProps, initConfirmProps);

  const showSuccessModal = (text, success = true) => {
    setSuccessInfo({ text, success });
    setSuccessModal(false);
    setRestartSuccessModal(true);
  };


  const provide = {
    currentWidth,
    user,
    setUser,
    loginModalVisible,
    toggleLoginModal,
    ownProfile,
    setOwnProfile,
    settingsAction,
    setSettingsAction,
    userIsLoaded,
    miroLoaded,
    setMiroLoaded,
    successModal,
    setSuccessModal,
    showSuccessModal,
    successInfo,
    setSuccessInfo,
    restartSuccessModal,
    setRestartSuccessModal,
    changePictureModalVisible,
    toggleChangePictureModal,
    newPicture,
    setNewPicture,
    loadingModalVisible,
    toggleLoadingModal,
    mobileMenuVisible,
    toggleMobileMenu,
    urlState,
    setUrlState,
    information,
    setInformation,
    confirmProps,
    setConfirmProps,
    ownArtifacts,
    setOwnArtifacts,
    locale,
    handleChangeLocale,
  };

  useEffect(() => {
    if (mobileMenuVisible) {
      const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
      const body = document.body;
      body.style.top = `-${scrollY}`;
    } else {
      const body = document.body;
      const scrollY = body.style.top;
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
    document.body.classList.toggle("mobile-menu-open", mobileMenuVisible);
  }, [mobileMenuVisible]);


  const registrationCheck = async () => {
    const request = await axios.get(getApiUrl("set_registration_checked/"));
    request.data && request.data.registration_checked && window.ym(parseInt(process.env.REACT_APP_YM_ID), 'reachGoal', 'registered');
  };


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const request = await axios.get(getApiUrl("user/"));
        const data = request.data;
        if (data) {
          if (JSON.stringify(data) !== "{}") {
            setUser(data);
            const profileRequest = await axios.get(getApiUrl(`profile/${data.id}`));
            const profileRequestData = profileRequest.data;
            if (profileRequestData) {
              setOwnProfile(profileRequestData);
              profileRequestData.registration_checked || registrationCheck();
            }
          } else {
            setUser(null);
          }
        }
        setUserIsLoad(true);
      } catch (e) {
        setUser(null);
        setOwnProfile(null);
      } finally {
        setUserIsLoad(true);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchStatistic = async () => {
      try {
        const request = await axios.get(getApiUrl("artifacts/get_information"));
        const data = request.data;
        setInformation(data);
        logging(request);
      } catch (e) { }
    };
    fetchStatistic();
  }, []);

  useEffect(() => {
    const setWidth = () => {
      const windowWidth = window.innerWidth;
      const sizes = Object.values(ScreenSizes);
      setCurrentWidth(sizes.filter((value) => value < windowWidth).pop() || sizes.shift());
    };

    const supportsOrientationChange = "onorientationchange" in window;
    const orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
    window.addEventListener(orientationEvent, setWidth);
    setWidth();
    return () => {
      window.removeEventListener(orientationEvent, setWidth);
    };
  }, []);

  return <AppContext.Provider value={provide} {...props} />;
};
