import React from "react";

const BigCross = () => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.5 13.5L13.5 40.5" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 13.5L40.5 40.5" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default BigCross;
