import { A } from "hookrouter";
import React from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import { SettingsItems } from "../../../Routes";
import { getYear, Show } from "../../../utils";
import EditButton from "../../UI/buttons/rounded/RoundedEditButton";

const Education = ({ profileData, isOwner }) => {
  const education = profileData.education;
  return (
    <StyledDiv>
      <Show when={education.institution}>
        <div className="education__institution">{education.institution}</div>
        <div className="education__faculty">{education.faculty}</div>
        <div className="education__specialty">{education.specialty}</div>
        <div className="education__date">{getYear(education.date_end)}</div>
      </Show>
      <Show when={!education.institution}>
        Не заполнено
      </Show>
      <Show when={isOwner}>
        <A href={`/settings/${SettingsItems.education}`}>
          <EditButton className="profile__right-side__edit-button" />
        </A>
      </Show>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  position: relative;
  margin-right: 124px;

  .education__institution {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-right: 40px;
  }

  .education__faculty {
    margin-bottom: 2px;
  }

  .education__specialty,
  .education__date,
  .education__faculty {
    font-size: 18px;
    line-height: 30px;
  }
  .education__specialty {
    margin-bottom: 20px;
  }

  @media ${device.desktop} {
    margin-right: 28px;
  }
  @media ${device.tablet_landscape} {
    margin-right: 0px;
  }
  @media ${device.phone_landscape} {
    .education__institution {
      font-size: 18px;
    }

    .education__institution,
    .education__specialty {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .education__date {
      font-size: 16px;
    }
  }
`;

export default Education;
