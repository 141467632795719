import React, { useContext } from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import { localePlaceholderTexts, localeErrorTexts } from "../../../Internationalization";
import { AppContext } from "../../AppContext";
import Select from "./Select";

const DateSelect = ({ className, formik, valueName, monthName, yearName, monthOptions, yearOptions, monthErrorCondition, yearErrorCondition }) => {
  const monthValueName = monthName || valueName.concat("Month");
  const yearValueName = yearName || valueName.concat("Year");
  const { locale } = useContext(AppContext);
  return (
    <StyledDateSelect className={className}>
      <div className="date_select">
        <Select
          className="month_select"
          valueName={monthValueName}
          formik={formik}
          options={monthOptions}
          placeholder="date-month"
          errorCondition={monthErrorCondition}
          hideAfterRow
          disableSearch
        />
        <Select
          className="year_select"
          valueName={yearValueName}
          formik={formik}
          options={yearOptions}
          placeholder="date-year"
          errorCondition={yearErrorCondition}
          hideAfterRow
          disableSearch
        />
      </div>
      {formik.touched[yearValueName] && formik.errors[yearValueName] ? (
        <div className="date_error-label">{localeErrorTexts[locale][formik.errors[yearValueName]] || formik.errors[yearValueName]}</div>
      ) : formik.touched[monthValueName] && formik.errors[monthValueName] ? (
        <div className="date_error-label">{localeErrorTexts[locale][formik.errors[monthValueName]] || formik.errors[monthValueName]}</div>
      ) : null}
    </StyledDateSelect>
  );
};

const StyledDateSelect = styled.div`
 display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .date_select {
    display: flex;
    & > * + * {
      margin-left: 13px;
    }
  }

  .month_select {
    width: 146px;
  }

  .year_select {
    width: 132px;
  }

  .date_error-label {
    margin-top: 4px;
    color: ${(props) => props.theme.colorRed};
    font-size: 12px;
    line-height: 12px;
  }

  @media ${device.phone_portrait} {
    .date_select {
      & > * + * {
        margin-left: 10px;
      }
    }
  }
`;

export default DateSelect;
